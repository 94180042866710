import * as React from 'react';
import {
  SimpleShowLayout,
  Show,
  DateField,
  ReferenceField,
  TextField,
} from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';

const ShowActions = () => <ResourceTitleActionBar mode="show" />;

export const MissionCauseShow = () => (
  <Show actions={<ShowActions />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="missionId" reference="missions" link="show" />
      <ReferenceField source="causeId" reference="causes" link="show" />
      <DateField
        source="createdAt"
        showTime
        label={`Created at (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
      />
      <DateField
        source="updatedAt"
        showTime
        label={`Updated at (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
      />
    </SimpleShowLayout>
  </Show>
);
