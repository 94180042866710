import { ActionType } from './action';
import { CampaignType } from './campaign';
import { CauseType } from './cause';
import { CommentType } from './comment';
import { MissionStoryType } from './mission_story';
import { TopicType } from './topic';
import { UserType } from './user';
import { WinType } from './win';

export type MissionType = {
  id: string;
  name: string;
  titleEN: string;
  titleFR: string;
  descriptionEN: string;
  descriptionFR: string;
  imageUrl: string;
  outcome: OutcomeStatus;

  thumbnailUrl: string;
  campaignId: CampaignType['id'];
  ownerId: UserType['id'];

  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  outcomeSetAt: string;

  actions?: ActionType[];
  campaign?: CampaignType;
  causes?: CauseType[];
  comments?: CommentType[];
  location?: MissionLocationType;
  owner?: UserType;
  publicUrl?: string;
  stories?: MissionStoryType[];
  topic?: TopicType;
  users?: UserType[];
  usersCount?: number;
  wins?: WinType[];
};

export enum OutcomeStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  ON_HOLD = 'ON_HOLD',
  CLOSED = 'CLOSED',
}

export type MissionLocationType = {
  id: string;
  name: string;
  country: string;
  state: string;
  missionId: MissionType['id'];
  createdAt: string;
  updatedAt: string;
};
