import * as React from 'react';
import { SimpleForm, Create, NumberInput, SelectInput, DateTimeInput } from 'react-admin';

import CustomReferenceInput from '@components/inputs/custom_reference_input';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const redirectAfterCreate = (basePath: any, id: any, data: any) =>
  `users/${data.userId}/show/points`;

const CreateActions = <ResourceTitleActionBar mode="create" />;

export const UserPointTypeSelect = () => (
  <SelectInput
    source="pointType"
    required
    choices={[
      { id: 'ACTION', name: 'Action' },
      { id: 'FIRST_MISSION_JOINED', name: 'First Mission Joined' },
      { id: 'MISSION_WON', name: 'Mission Won' },
      { id: 'MISSION_LOST', name: 'Mission Lost' },
    ]}
  />
);

export const UsersPointsCreate = () => (
  <Create redirect={redirectAfterCreate} actions={CreateActions}>
    <SimpleForm>
      <CustomReferenceInput
        source="userId"
        reference="users"
        queryKey="username"
        sort={{
          field: 'username',
          order: 'ASC',
        }}
        suggestionLimit={100}
      />
      <UserPointTypeSelect />
      <NumberInput source="points" required />
      <DateTimeInput source="createdAt" defaultValue={new Date()} />
    </SimpleForm>
  </Create>
);
