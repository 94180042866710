import React from 'react';
import {
  Button,
  Create,
  SimpleForm,
  TextInput,
  required,
  useRecordContext,
} from 'react-admin';
import { useParams } from 'react-router-dom';

import Callout from '@components/callout';
import CustomReferenceInput from '@components/inputs/custom_reference_input';
import Favicon from '@components/svgs/logos/favicon';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import { SpacingStyle } from '@styles/variables';

import { CountrySelect } from './components/country';
import EmailRecipientsInput from './components/email_recipient/email_recipients_input';

const CreateActions = () => {
  const params = useParams();
  const record = useRecordContext();
  let actionId = params?.actionId ? params.actionId : record?.actionId;
  return (
    <ResourceTitleActionBar
      mode="create"
      actions={
        <Button
          href={`#/actions/${actionId}/show`}
          startIcon={<Favicon width={15} height={12} />}
          label="Back to Action"
          variant="outlined"
        />
      }
    />
  );
};

const redirectAfterCreateEdit = (_basePath: any, _id: any, data: any) =>
  `actions/${data.actionId}/show/cta`;

/**
 * Validate the subject and body of the email
 */
export const validateEmailContent = (value: string) => {
  if (!value) {
    return 'This field is required';
  }
  if (value.includes('{{')) {
    return 'Must not contain {{ }}';
  }
  if (value.includes('&')) {
    return 'Must not contain any &';
  }
  if (value.includes('**')) {
    return 'Must not contain **';
  }
  return null;
};

export const ActionCtaEmailCreate = () => (
  <Create redirect={redirectAfterCreateEdit} actions={<CreateActions />}>
    <SimpleForm>
      <CustomReferenceInput
        source="actionId"
        reference="actions"
        optionText="name"
        validate={[required()]}
      />

      <CountrySelect />
      <br />

      <EmailRecipientsInput source="to" isRequired />
      <EmailRecipientsInput source="cc" />
      <EmailRecipientsInput source="bcc" />

      <br />
      <br />

      <Callout emoji="🤖" backgroundColor="grey">
        Write a default subject.
        <br />
        GPT will overwrite it based on the <strong>content</strong> of the email, before
        sending it.
      </Callout>
      <TextInput source="subject" required fullWidth validate={validateEmailContent} />

      <Callout emoji="👇" backgroundColor="grey">
        <ul style={{ paddingLeft: SpacingStyle.normal }}>
          <li>Write as a concerned person, not on your name,</li>
          <li>
            If you cite a number, or give example, put the link of your sources in the
            email,
          </li>
          <li>Be polite and be precise ;)</li>
        </ul>
      </Callout>
      <TextInput
        source="body"
        label="Content"
        required
        multiline
        fullWidth
        validate={validateEmailContent}
        minRows={10}
      />
    </SimpleForm>
  </Create>
);
