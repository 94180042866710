import * as React from 'react';
import {
  BooleanInput,
  Create,
  ImageField,
  ImageInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';
import { TopicTypes } from 'types/topic.d';

import ResourceTitleActionBar from '@components/resource_title_action_bar';

const CreateActions = <ResourceTitleActionBar mode="create" />;

export const SelectTopicType = ({ ...props }) => (
  <SelectInput
    source="type"
    choices={[
      { id: TopicTypes.Community, name: 'Community' },
      { id: TopicTypes.Cause, name: 'Cause' },
      { id: TopicTypes.Mission, name: 'Mission' },
      { id: TopicTypes.DirectMessage, name: 'Direct Message' },
    ]}
    {...props}
  />
);

const redirectAfterCreate = (_basePath: any, _id: any, data: any) =>
  `topics/${data.id}/show`;

export const TopicCreate = () => (
  <Create actions={CreateActions} redirect={redirectAfterCreate}>
    <SimpleForm>
      <TextInput source="nameEN" label="Name EN" fullWidth required />
      <TextInput source="nameFR" label="Name FR" fullWidth required />
      <TextInput source="descriptionEN" label="Description EN" fullWidth required />
      <TextInput source="descriptionFR" label="Description FR" fullWidth required />
      <BooleanInput source="isPublic" label="Is public?" />
      <SelectTopicType />
      <ImageInput
        source="picture"
        accept="image/*"
        maxSize={5000000}
        validate={[required()]}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Create>
);
