import * as React from 'react';
import {
  Button,
  DateField,
  EditButton,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

import { useAdminRoles } from '@hooks/useAdminRoles';
import { AddFFToBetaUsers, RemoveFFFromBetaUsers } from '@services/feature_flags';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import { FeatureFlagUsersCustomList } from '@components/lists/feature_flag_users_list';

const FeatureFlagShowActions = () => {
  const record = useRecordContext();
  const canEdit = useAdminRoles('super_admin');
  return (
    <ResourceTitleActionBar
      mode="show"
      actions={
        <>
          {window.location.hostname === 'localhost' && record && (
            <Button
              label="Add to production"
              href={`https://command-center.chilli.club/#/feature_flags/create?source={"name":"${record.name}","description":"${record.description}"}`}
              target="_blank"
              startIcon={<NoteAddIcon />}
              variant="outlined"
            />
          )}
          <AddFFToBetaUsers featureFlag={record} />
          <RemoveFFFromBetaUsers featureFlag={record} />
          {canEdit && <EditButton label="Edit FF" variant="outlined" />}
        </>
      }
    />
  );
};

export const FeatureFlagShow = () => (
  <Show actions={<FeatureFlagShowActions />}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="name" />
        <TextField source="description" />
        <DateField
          source="createdAt"
          showTime
          label={`Created at (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
        />
        <DateField
          source="updatedAt"
          showTime
          label={`Updated at (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
        />
        <DateField
          source="enabledAt"
          showTime
          label={`Enabled at (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
        />
      </Tab>
      <Tab label="users" path="users">
        <FeatureFlagUsersCustomList />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
