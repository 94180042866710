import * as React from 'react';
import { ReferenceField, Show, SimpleShowLayout, TextField } from 'react-admin';
import { VideoField } from '../videos/components/video_field';

export const CampaignStoryShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="campaignId" reference="campaigns" link="show" />
      <VideoField source="video" label="Video" reference="campaignStoryId" showButtons />
      <TextField source="createdAt" />
      <TextField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);
