import React from 'react';
import {
  Button,
  useRecordContext,
  useRedirect,
  useRefresh,
  useDataProvider,
} from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ArrowDownward from '@mui/icons-material/ArrowDownward';

import { useAdminRoles } from '@hooks/useAdminRoles';
import { VideoField } from '@models/videos/components/video_field';

export const CampaignStoriesList = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const { id, stories } = record;
  const refresh = useRefresh();
  const isReadOnly = useAdminRoles('read_only');

  const upgradeStoryPosition = async (storyId: string) => {
    try {
      await dataProvider.upgradeCampaignStoryPosition(storyId);
      refresh();
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const downgradeStoryPosition = async (storyId: string) => {
    try {
      await dataProvider.downgradeCampaignStoryPosition(storyId);
      refresh();
    } catch (error: any) {
      throw new Error(error);
    }
  };

  return (
    <div>
      <table width={'100%'} style={{ borderSpacing: 0 }}>
        <tbody>
          <tr style={{ textAlign: 'left', backgroundColor: '#d5d5d5' }}>
            <th>Positioning</th>
            <th>Video</th>
            <th>Created At</th>
          </tr>
          {stories &&
            stories.map((story: any, i: number) => (
              <tr
                key={i}
                style={{
                  height: 100,
                  backgroundColor: i % 2 === 1 ? '#f9f9f9' : undefined,
                  cursor: 'pointer',
                }}
              >
                <td>
                  {story.position && !isReadOnly && (
                    <>
                      <Button
                        onClick={() => downgradeStoryPosition(story.id)}
                        startIcon={<ArrowUpward />}
                        disabled={story.position <= 1}
                      />
                      <Button
                        onClick={() => upgradeStoryPosition(story.id)}
                        startIcon={<ArrowDownward />}
                        disabled={story.position >= stories.length}
                      />
                    </>
                  )}
                </td>
                <td
                  onClick={() => {
                    redirect('show', 'campaign_stories', story.id);
                  }}
                >
                  <VideoField
                    source="video"
                    label="Video"
                    reference="campaignStoryId"
                    width={100}
                    record={story}
                  />
                </td>
                <td
                  onClick={() => {
                    redirect('show', 'campaign_stories', story.id);
                  }}
                >
                  <small>{new Date(story.createdAt).toLocaleString()}</small>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {!useAdminRoles('read_only') && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <Button
            href={`#/campaign_stories/video_studio?campaignId=${id}`}
            label="Create Story"
            startIcon={<AddIcon />}
          />
        </div>
      )}
    </div>
  );
};
