/**
 * [VideoScript] Show view
 */
import React, { useState } from 'react';
import {
  Button,
  EditButton,
  FunctionField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
  useRedirect,
} from 'react-admin';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import DrawIcon from '@mui/icons-material/Draw';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { useAdminRoles } from '@hooks/useAdminRoles';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const VideoScriptShowActions = () => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const canEdit = useAdminRoles(['super_admin', 'campaign_admin', 'campaign_manager']);
  if (!record) {
    return <></>;
  }
  return (
    <ResourceTitleActionBar
      mode="show"
      actions={
        <>
          {record.action && (
            <Button
              label="Open related Action"
              startIcon={<EmojiPeopleIcon />}
              onClick={() => redirect(`/actions/${record.action.id}/show`)}
              variant="outlined"
            />
          )}
          {canEdit && <EditButton variant="outlined" />}
        </>
      }
    />
  );
};

export const VideoScriptField = ({ source, editable, actionId }: any) => {
  let record = useRecordContext();
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  if (source && record[source]) {
    record = record[source];
  }
  return (
    <FunctionField
      label="Script"
      render={() => (
        <div style={Styles.script}>
          <pre style={{ textWrap: 'wrap' } as any}>{record.script}</pre>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 8,
            }}
          >
            {editable && (
              <>
                {actionId && (
                  <Button
                    label={copiedToClipboard ? 'Copied!' : 'Copy link for activist'}
                    variant="outlined"
                    startIcon={<ContentCopyIcon />}
                    onClick={() => {
                      setCopiedToClipboard(true);
                      const host = window.location.host.split('.').slice(1).join('.');
                      navigator.clipboard.writeText(`https://studio.${host}/${actionId}`);
                    }}
                  />
                )}
                <Button
                  href={`#/video_scripts/${record.id}`}
                  label="Edit script"
                  variant="outlined"
                  startIcon={<DrawIcon />}
                />
              </>
            )}
          </div>
        </div>
      )}
    />
  );
};

export const VideoScriptShow = () => (
  <Show actions={<VideoScriptShowActions />}>
    <SimpleShowLayout>
      <TextField source="createdAt" />
      <TextField source="updatedAt" />
      <VideoScriptField />
    </SimpleShowLayout>
  </Show>
);

const Styles = {
  script: {
    padding: '4px 12px',
    backgroundColor: '#f9f9f9',
    borderRadius: 8,
  },
};
