import React, { useEffect, useState } from 'react';
import { Loading, useGetList } from 'react-admin';
import MissionCard from 'src/models/missions/components/card';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from 'src/styles/variables';
import { CampaignType } from 'types/campaign';
import { MissionType } from 'types/mission';

import { MissionCreateDialog } from '../create_dialog';

type MissionsGroupedByStatusProps = {
  campaignId?: CampaignType['id'];
};
const MissionsGroupedByStatus = ({ campaignId }: MissionsGroupedByStatusProps) => {
  const { data: missions, isLoading } = useGetList<MissionType>('missions', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'publishedAt', order: 'DESC' },
    filter: { campaignId },
  });

  const [draftMissions, setDraftMissions] = useState<MissionType[]>([]);
  const [liveMissions, setLiveMissions] = useState<MissionType[]>([]);
  const [endedMissions, setEndedMissions] = useState<MissionType[]>([]);

  useEffect(() => {
    setDraftMissions([]);
    setLiveMissions([]);
    setEndedMissions([]);
  }, []);

  useEffect(() => {
    if (!missions) {
      return;
    }
    setDraftMissions([]);
    setLiveMissions([]);
    setEndedMissions([]);
    for (const mission of missions) {
      if (!mission.publishedAt) {
        setDraftMissions(prev => [...prev, mission]);
      } else if (!mission.outcome) {
        setLiveMissions(prev => [...prev, mission]);
      } else {
        setEndedMissions(prev => [...prev, mission]);
      }
    }
  }, [missions]);

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div style={styles.container}>
      <div style={styles.title}>
        Draft missions
        <MissionCreateDialog campaignId={campaignId} />
      </div>
      <div style={styles.draftMissions}>
        {draftMissions.length === 0 ? (
          <div style={styles.missionPlaceholder}>
            No draft missions.
            <br />
            Create one mission to get started.
          </div>
        ) : (
          <div style={styles.missions}>
            {draftMissions.map((mission, index) => (
              <MissionCard
                mission={mission}
                key={`${index}-${mission.id}`}
                withStartedAt
                withActions="all"
                maxWith={300}
              />
            ))}
          </div>
        )}
      </div>

      <div style={styles.title}>Live missions</div>
      {liveMissions.length === 0 ? (
        <div style={styles.missionPlaceholder}>
          No live missions yet.
          <br />
          Create a draft mission to get started.
        </div>
      ) : (
        <div style={styles.missions}>
          {liveMissions.map(mission => (
            <MissionCard
              mission={mission}
              key={mission.id}
              withStartedAt
              withActions="all"
              withUsersCount
              maxWith={300}
            />
          ))}
        </div>
      )}

      <div style={styles.title}>Ended missions</div>
      {endedMissions.length === 0 ? (
        <div style={styles.missionPlaceholder}>
          No ended missions yet.
          <br />
          Set the outcome of a live mission to end it.
        </div>
      ) : (
        <div style={styles.missions}>
          {endedMissions.map(mission => (
            <MissionCard
              mission={mission}
              key={mission.id}
              maxWith={300}
              withUsersCount
            />
          ))}
        </div>
      )}
    </div>
  );
};

const styles: any = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: `${SpacingStyle.big}px 0`,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: SpacingStyle.normal,
    marginTop: SpacingStyle.big,
    marginBottom: SpacingStyle.small,
    fontSize: FontStyle.sizeNormal,
    fontWeight: 700,
  },
  missions: {
    display: 'flex',
    width: '100%',
    gap: SpacingStyle.normal,
    flexWrap: 'wrap',
    padding: SpacingStyle.normal,
  },
  draftMissions: {
    display: 'flex',
    gap: SpacingStyle.normal,
    backgroundColor: Colors.Grey[25],
    borderRadius: BorderStyle.Radius.normal,
  },
  missionPlaceholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: 120,
    backgroundColor: Colors.Grey[25],
    borderRadius: BorderStyle.Radius.normal,
    color: Colors.Grey[400],
    fontSize: FontStyle.sizeVerySmall,
    textAlign: 'center',
  },
};

export default MissionsGroupedByStatus;
