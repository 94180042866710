import { CampaignType } from './campaign';
import { CauseType } from './cause';
import { CommentType } from './comment';
import { MissionType } from './mission';
import { UserType } from './user';
import { VideoType } from './video';
import { VideoScriptType } from './video_script';

export enum ActionCTAType {
  APP_PAGE = 'app_page',
  EMAIL = 'email',
  EXTERNAL_LINK = 'external_link',
  GOOGLE_MAPS = 'google_maps',
  NOTHING = 'nothing',
  PHONE_CALL = 'phone_call',
  QUESTION = 'question',
  SHARE_LINK = 'share_link',
  SOCIAL_COMMENT = 'social_comment',
  SOCIAL_POST = 'social_post',
  WEBPAGE = 'webpage',
}

export type ActionType = {
  id: string;
  name?: string;
  descriptionEN?: string;
  descriptionFR?: string;
  campaignId: CampaignType['id'];
  missionId: MissionType['id'];
  videoScriptId?: VideoScriptType['id'];
  videoActionId?: VideoType['id'];
  points: number;
  expiresIn: number;
  priority: number;
  publicUrl: string;
  usersObjective?: number;

  ctaType: ActionCTAType;

  createdAt: string;
  updatedAt: string;
  publishedAt: string; // timestamp with no timezone

  campaign?: CampaignType;
  causes?: CauseType[];
  comments?: CommentType[];
  mission?: MissionType;
  owners?: UserType[];
  users?: UserType[];
  usersCount?: number;
  videoAction?: VideoType;
  videoScript?: VideoScriptType;

  actionCtaEmails?: ActionCTAEmailType[];
  actionCtaExternalLink?: ActionCTAExternalLinkType;
  actionCtaGoogleMapsReview?: ActionCTAGoogleMapsReviewType;
  actionCtaPhoneCalls?: ActionCTAPhoneCallType[];
  actionCtaQuestion?: ActionCTAQuestionCTAType;
  actionCtaShareLink?: ActionCTAShareLinkType;
  actionCtaSocialComments?: ActionCTASocialCommentType[];
  actionCtaWebpage?: ActionCTAWebpageType;
};

export type ActionExternalLink = {
  id: string;
  actionId: ActionType['id'];
  faviconUrl?: string;
  title: string;
  subtitle?: string;
  url: string;
};

export type ActionCTAEmailType = {
  id: string;
  to: string;
  cc?: string;
  bcc?: string;
  subject: string;
  body: string;
  summaryEN: string;
  summaryFR: string;
  country?: string;
};

export type ActionCTAExternalLinkType = {
  id: string;
  actionId: ActionType['id'];
  externalUrl: string;
  message: string;
  message1: string;
  message2: string;
  message3: string;
  message4: string;
  message5: string;
  message6: string;
  message7: string;
  message8: string;
  message9: string;
  message10: string;
};

export type ActionCTAGoogleMapsReviewType = {
  id: string;
  googleMapsUrl: string;
  message: string;
  message1: string;
  message2: string;
  message3: string;
  message4: string;
  message5: string;
  message6: string;
  message7: string;
  message8: string;
  message9: string;
  message10: string;
  message11: string;
  message12: string;
  message13: string;
  message14: string;
  message15: string;
  message16: string;
  message17: string;
  message18: string;
  message19: string;
  message20: string;
  actionId: ActionType['id'];
};

export type ActionCTASocialCommentType = {
  id: string;
  postUrl: string;
  socialPlatform: SocialPlatformComment;
  message: string;
  message1: string;
  message2: string;
  message3: string;
  message4: string;
  message5: string;
  message6: string;
  message7: string;
  message8: string;
  message9: string;
  message10: string;
  message11: string;
  message12: string;
  message13: string;
  message14: string;
  message15: string;
  message16: string;
  message17: string;
  message18: string;
  message19: string;
  message20: string;
  gptPrompt: string;
  actionId: ActionType['id'];
};

export type ActionCTAWebpageType = {
  id: string;
  websiteUrl: string;
  propertiesArray?: string;
  extraInjectedJavascript?: string;
  actionId: ActionType['id'];
  type: ActionCTAType;
};

export enum SocialPlatformComment {
  INSTAGRAM = 'instagram',
  TWITTER = 'twitter',
  LINKEDIN = 'linkedin',
  FACEBOOK = 'facebook',
  TIKTOK = 'tiktok',
  SNAPCHAT = 'snapchat',
}

export enum QuestionType {
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
}

export type ResponseCTAType = {
  id?: string;
  responseEN: string;
  responseFR: string;
  question?: ActionCTAQuestionCTAType;
  usersResponsesCount?: string;
  usersResponses: { user: UserType }[];
};

export type ActionCTAQuestionCTAType = {
  id: string;
  questionEN: string;
  questionFR: string;
  actionId: ActionType['id'];
  type: QuestionType;
  responses: ResponseCTAType[];
  usersResponsesCount?: string;
};

export type ActionCTAPhoneCallType = {
  id: string;
  phoneNumber: string;
  script: string;
  country?: string;
};

export type ActionCTAShareLinkType = {
  id: string;
  link: string;
};
