import * as React from 'react';
import {
  BooleanField,
  DateField,
  EditButton,
  ImageField,
  Labeled,
  Show,
  SimpleShowLayout,
  TextField,
  UrlField,
  useRecordContext,
} from 'react-admin';

import { useAdminRoles } from '@hooks/useAdminRoles';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const ShowActions = () => {
  const canEdit = useAdminRoles(['super_admin', 'campaign_admin', 'product']);
  return (
    <ResourceTitleActionBar
      mode="show"
      actions={canEdit && <EditButton label="Edit" variant="outlined" />}
    />
  );
};

export const InAppNotificationShow = () => (
  <Show actions={<ShowActions />}>
    <SimpleShowLayout>
      <DateField source="createdAt" showTime />
      <ImageField
        source="imageUrl"
        sx={{ '& img': { maxWidth: 75, maxHeight: 75, borderRadius: 100 } }}
      />
      <TextField
        source="badgeEmoji"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 30,
          height: 30,
          backgroundColor: '#FBE4E4',
          borderRadius: 100,
        }}
      />
      <BooleanField source="displayAsToast" />
      <hr />
      <TextField source="entityType" label="Type" />
      <InAppNotificationShowSpecifics />
      <hr />
      <TextField source="titleEN" label="Title EN" />
      <TextField source="descriptionEN" label="Description EN" />
      <TextField source="titleFR" label="Title FR" />
      <TextField source="descriptionFR" label="Description FR" />
    </SimpleShowLayout>
  </Show>
);

const InAppNotificationShowSpecifics = () => {
  const record = useRecordContext();
  if (record?.entityType === 'AppPage') {
    return (
      <Labeled label="App Page To Open">
        <TextField source="page" label="App Page" />
      </Labeled>
    );
  }
  if (record?.entityType === 'ExternalLink') {
    return (
      <Labeled label="URL To Open">
        <UrlField source="websiteUrl" target="_blank" />
      </Labeled>
    );
  }

  return <></>;
};
