import React from 'react';
import { Button, useRecordContext } from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useAdminRoles } from '../../../hooks/useAdminRoles';
import { VideoField } from './video_field';
import { SubtitleGenerationStatus, SubtitleType } from 'types/subtitle.d';

export const SubtitleList = () => {
  const record = useRecordContext();
  const isReadOnly = useAdminRoles('read_only');

  if (!record.subtitles) {
    if (isReadOnly) {
      return <div>No subtitles for this video</div>;
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
        <Button
          href={`#/subtitles/create?source={"videoId": "${record.id}"}`}
          label="Add a subtitle"
          startIcon={<AddIcon />}
        />
      </div>
    );
  }

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <table width={'100%'} style={{ borderSpacing: 0, borderCollapse: 'collapse' }}>
          <tbody>
            <tr style={{ backgroundColor: '#ddd' }}>
              {record.subtitles.map((subtitle: SubtitleType, i: number) => (
                <td
                  key={i}
                  style={{
                    padding: 10,
                    fontWeight: 'bold',
                    width: `${100 / record.subtitles.length}%`,
                  }}
                >
                  <strong style={{ textTransform: 'uppercase' }}>
                    {subtitle.locale}
                  </strong>{' '}
                  ({subtitle.generationStatus || SubtitleGenerationStatus.SUCCESS})
                </td>
              ))}
            </tr>
            <tr style={{ verticalAlign: 'top', textAlign: 'left' }}>
              {record.subtitles.map((subtitle: SubtitleType, i: number) => (
                <td
                  key={i}
                  component="pre"
                  style={{
                    border: '1px solid #ddd',
                    padding: 10,
                    verticalAlign: 'top',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {subtitle.error}
                  {subtitle.text}
                </td>
              ))}
            </tr>
            <tr>
              {record.subtitles.map((subtitle: SubtitleType, i: number) => (
                <td key={i} style={{ paddingTop: 20 }}>
                  <Button
                    href={`#/subtitles/${subtitle.id}/show`}
                    label="show"
                    startIcon={<VisibilityIcon />}
                    style={{ marginRight: 20 }}
                  />
                  {!isReadOnly && (
                    <Button
                      href={`#/subtitles/${subtitle.id}`}
                      label="edit"
                      startIcon={<EditIcon />}
                      style={{ marginRight: 20 }}
                    />
                  )}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
        <div
          style={{
            position: 'fixed',
            bottom: 20,
            right: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: '200px',
            width: '100%',
            marginTop: '50px',
          }}
        >
          <VideoField
            record={record}
            source="videoUrl"
            controls
            videoStyle={{
              width: '100%',
              borderRadius: 10,
              boxShadow: '0 0 30px 10px rgba(0,0,0,0.4)',
            }}
          />
        </div>
      </div>
      {!isReadOnly && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <Button
            href={`#/subtitles/create?source={"videoId": "${record.id}"}`}
            label="Add another subtitle"
            startIcon={<AddIcon />}
            style={{
              backgroundColor: '#111',
              color: 'white',
              padding: '10px 20px',
              fontWeight: 'bold',
            }}
          />
        </div>
      )}
    </div>
  );
};
