import React from 'react';
import { Button, useRecordContext } from 'react-admin';
import { Card, CardContent, CardHeader } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { MissionType } from 'types/mission';

import { useAdminRoles } from '@hooks/useAdminRoles';
import { CauseIcon } from '@components/icons';
import CauseChip from '@models/causes/components/chip';
import { Colors, FontStyle, SpacingStyle } from '@styles/variables';

const CausesCard = () => {
  const mission: MissionType = useRecordContext();
  const canAddCauses = useAdminRoles([
    'super_admin',
    'campaign_admin',
    'campaign_manager',
  ]);

  return (
    <Card>
      <CardHeader title="Cause" avatar={<CauseIcon />} />
      <CardContent style={styles.causes}>
        {mission.causes?.map((c: any) => (
          <CauseChip
            cause={c}
            clickable={`/missions_causes/${c.missionCauseId}`}
            key={c.id}
          />
        ))}
        {mission.causes?.length === 0 && canAddCauses && (
          <Button
            label="Link to a cause"
            variant="outlined"
            href={`#/missions_causes/create?source={"missionId":"${mission.id}"}`}
            startIcon={<AddIcon />}
          />
        )}
      </CardContent>
    </Card>
  );
};

const styles: any = {
  causes: {
    display: 'flex',
    alignItems: 'center',
    gap: SpacingStyle.normal,
    flexWrap: 'wrap',
  },
  story: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 60,
    height: 60,
    borderRadius: 60,
    overflow: 'hidden',
    backgroundColor: Colors.Magenta.primary,
    color: Colors.White.primary,
    fontSize: FontStyle.sizeVeryBig,
    cursor: 'pointer',
    border: `3px solid ${Colors.White.primary}`,
    boxShadow: `0px 0px 0 2px ${Colors.Magenta[600]}`,
  },
  addStory: {
    backgroundColor: Colors.Magenta[50],
    color: Colors.Magenta.primary,
    border: `1px dashed ${Colors.Magenta.primary}`,
    boxShadow: 'none',
  },
  storyPoster: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
};

export default CausesCard;
