import React from 'react';
import { useRecordContext } from 'react-admin';
import { Card, CardContent, CardHeader } from '@mui/material';
import { ActionType } from 'types/action';

import { dateFormatter } from '@services/date';
import { MissionIcon } from '@components/icons';
import Callout from '@components/callout';

const MissionCard = () => {
  const action: ActionType = useRecordContext();

  if (!action.mission || !action.mission.outcome) {
    return <></>;
  }

  return (
    <Card style={{ gridColumn: 'span 2' }}>
      <CardHeader title="Mission" avatar={<MissionIcon />} />
      <CardContent>
        <Callout emoji="🛑" backgroundColor={'red'} style={{ maxWidth: '100%' }}>
          <h3>
            <u>This action can't be updated:</u>
            <br />
            The mission hosting this action as been marked as{' '}
            <u>{action.mission.outcome}</u> on{' '}
            {dateFormatter(action.mission.outcomeSetAt)}.
          </h3>
        </Callout>
      </CardContent>
    </Card>
  );
};

export default MissionCard;
