import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { useAdminRoles } from '@hooks/useAdminRoles';

const AmplitudeGraphCard = () => {
  const canView = useAdminRoles(['super_admin', 'campaign_admin', 'product']);
  if (!canView) {
    return null;
  }
  return (
    <Card>
      <CardContent>
        <iframe
          className="frame"
          src="https://app.amplitude.com/analytics/share/embed/fa100a66-9f01-4776-95c4-e6ec953a619b"
          frameBorder="0"
          width="100%"
          height="450"
        ></iframe>
      </CardContent>
    </Card>
  );
};

export default AmplitudeGraphCard;
