import * as React from 'react';
import {
  EditButton,
  FunctionField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import 'property-information';
import ReactMarkdown from 'react-markdown';

import { useAdminRoles } from '@hooks/useAdminRoles';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const ShowActions = () => {
  const canEdit = useAdminRoles(['super_admin', 'campaign_admin', 'campaign_manager']);
  return (
    <ResourceTitleActionBar
      mode="show"
      title="Campaign QAs"
      actions={canEdit && <EditButton label="Edit" variant="outlined" />}
    />
  );
};

export const CampaignQAShow = () => (
  <Show actions={<ShowActions />}>
    <SimpleShowLayout>
      <ReferenceField source="campaignId" reference="campaigns" link="show" />
      <TextField source="createdAt" />
      <hr />
      <TextField label="English question" source="questionEN" />
      <FunctionField
        label="English answer"
        render={(record: any) => <ReactMarkdown>{record.answerEN}</ReactMarkdown>}
      />
      <hr />
      <TextField label="French question" source="questionFR" />
      <FunctionField
        label="French answer"
        render={(record: any) => <ReactMarkdown>{record.answerFR}</ReactMarkdown>}
      />
    </SimpleShowLayout>
  </Show>
);
