import * as React from 'react';
import {
  Datagrid,
  DateField,
  Filter,
  List,
  ReferenceField,
  SearchInput,
  TextField,
} from 'react-admin';

import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const ListActions = () => (
  <ResourceTitleActionBar
    mode="list"
    actions={
      <Filter>
        <SearchInput source="message" alwaysOn />
      </Filter>
    }
  />
);

export const MissionCommentList = () => (
  <List
    actions={<ListActions />}
    sort={{ field: 'createdAt', order: 'ASC' }}
    perPage={PER_PAGE}
    pagination={<PER_PAGE_OPTIONS />}
    exporter={false}
    emptyWhileLoading
  >
    <Datagrid rowClick="show">
      <ReferenceField source="missionId" reference="missions" link="show" />

      <ReferenceField source="authorId" reference="users" link="show">
        <TextField source="username" />
      </ReferenceField>
      <TextField source="message" />
      <DateField source="createdAt" label="Posted At" />
    </Datagrid>
  </List>
);
