import React, { useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import { ActionType } from 'types/action';
import moment from 'moment-timezone';

import Calendar, { CalendarItems } from '@components/calendar';
import ActionCard from '@components/calendar/components/action_card';

const ActionsCalendar = () => {
  const dataProvider = useDataProvider();

  /**
   * Fetch calendar items
   * @param daysDisplayed
   * @returns {Promise<CalendarItems>}
   */
  const fetchCalendarItems = useCallback(
    async (daysDisplayed?: string[]) => {
      if (!daysDisplayed) {
        return {};
      }

      const { data } = await dataProvider.getList('actions', {
        pagination: { page: 1, perPage: 500 },
        sort: { field: 'publishedAt', order: 'ASC' },
        filter: {
          publishedAt: daysDisplayed,
        },
      });

      const result: CalendarItems = {};
      data.forEach((action: ActionType) => {
        const day = moment(action.publishedAt).format('YYYY-MM-DD');
        if (!day) {
          return;
        }
        if (!result[day]) {
          result[day] = [];
        }
        result[day].push(
          <ActionCard key={action.id} action={action} withMission withCause />
        );
      });
      return result;
    },
    [dataProvider]
  );

  return <Calendar fetchItems={fetchCalendarItems} forcedUnitOfTime="week" />;
};

export default ActionsCalendar;
