import * as React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import CustomReferenceInput from '@components/inputs/custom_reference_input';
import SocialPlatformInput from './components/social_platform_input';

const redirectAfterEdit = (basePath: any, id: any, data: any) =>
  `actions/${data.actionId}/show/cta`;

export const ActionCtaSocialPostEdit = () => (
  <Edit redirect={redirectAfterEdit} mutationMode="pessimistic">
    <SimpleForm>
      <CustomReferenceInput source="actionId" reference="actions" disabled />
      <SocialPlatformInput source="socialPlatform" />
      {/* <h3>For Instagram / Facebook</h3>
      <div>One sticker URL can be added (optionnal):</div>
      <TextInput source="stickerUrl" fullWidth />

      <div>One image URL or one video URL (not both):</div>
      <TextInput source="imageUrl" fullWidth />
      <TextInput source="videoUrl" fullWidth /> */}

      <h3>For Twitter</h3>
      <small>⚠️ Twitter only allows 280 chars per message (not counting URLs)</small>
      <TextInput source="message1" fullWidth />
      <TextInput source="message2" fullWidth />
      <TextInput source="message3" fullWidth />
      <TextInput source="message4" fullWidth />
      <TextInput source="message5" fullWidth />
      <TextInput source="message6" fullWidth />
      <TextInput source="message7" fullWidth />
      <TextInput source="message8" fullWidth />
      <TextInput source="message9" fullWidth />
      <TextInput source="message10" fullWidth />
      <TextInput source="message11" fullWidth />
      <TextInput source="message12" fullWidth />
      <TextInput source="message13" fullWidth />
      <TextInput source="message14" fullWidth />
      <TextInput source="message15" fullWidth />
      <TextInput source="message16" fullWidth />
      <TextInput source="message17" fullWidth />
      <TextInput source="message18" fullWidth />
      <TextInput source="message19" fullWidth />
      <TextInput source="message20" fullWidth />
    </SimpleForm>
  </Edit>
);
