import React, { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  useDataProvider,
  useGetList,
  useGetOne,
  useRedirect,
  useRefresh,
} from 'react-admin';
import { useParams, useSearchParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { CommentType } from 'types/comment';
import { UserType } from 'types/user';
import { CommentMediaTypes } from 'types/comment_media.d';
import { UserCommentReactionType } from 'types/user_comment_reaction';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';

import { useOpenAI } from '@hooks/useOpenAI';
import { dateFormatter } from '@services/date';
import ChevronDown from '@components/svgs/chevron_down';
import Edit from '@components/svgs/edit';
import PepperAvatar from '@components/pepper_avatar';
import { UserAvatar } from '@models/users/components/user_avatar';
import UserChip from '@models/users/components/user_chip';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

/**
 * AuthorsModal
 * @param param0
 * @returns {JSX.Element}
 */
type AuthorsModalProps = {
  authors: UserType[];
  open: boolean;
  onClose: () => void;
  onSelect: (author: UserType) => void;
};
const AuthorsModal = ({ authors, open, onClose, onSelect }: AuthorsModalProps) => {
  const [searchQuery, setSearchQuery] = useState('');

  /**
   * Sort authors by username
   * @param authorA
   * @param authorB
   * @returns {number}
   */
  const sortAuthors = (authorA: UserType, authorB: UserType) => {
    if (!authorA.username || !authorB.username) {
      return 0;
    }
    if (authorA.username.toLowerCase() < authorB.username.toLowerCase()) {
      return -1;
    }
    if (authorA.username.toLowerCase() > authorB.username.toLowerCase()) {
      return 1;
    }
    return 0;
  };

  /**
   * Filter authors based on search query
   * @returns {UserType[]}
   */
  const filteredList = useMemo(() => {
    if (!searchQuery) {
      return authors.sort(sortAuthors).slice(0, 10);
    }
    return authors.filter(author =>
      author.username.toLowerCase().includes(searchQuery.toLowerCase())
    ).slice(0, 20);
  }, [authors, searchQuery]);

  /**
   * Handle key down, when enter is pressed, select the first author
   * @param event
   */
  const onKeyDown = useCallback((event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSelect(filteredList[0]);
    }
  }, [filteredList, onSelect]);

  if (!open) {
    return null;
  }

  return (
    <div>
      <div style={authorsModal.background} onClick={onClose} />
      <div style={authorsModal.wrapper}>
        <div style={authorsModal.container}>
          <div style={authorsModal.header}>Select an author </div>
          <div style={authorsModal.list}>
            {filteredList.sort(sortAuthors).map((author: UserType) => (
              <UserChip
                key={author.id}
                user={author}
                onClick={() => onSelect(author)}
                style={authorsModal.listItem}
              />
            ))}
          </div>
          <input
            type="text"
            ref={input => input && input.focus()}
            onChange={event => setSearchQuery(event.target.value)}
            onKeyDown={onKeyDown}
            style={authorsModal.input}
            value={searchQuery}
          />
        </div>
      </div>
    </div>
  );
};

/**
 * CommentMedia
 * @param comment
 * @returns {JSX.Element}
 */
const CommentMedia = ({ comment }: { comment: CommentType }) => {
  if (!comment) {
    return;
  }

  return (
    <div style={styles.commentMedias}>
      {comment.medias?.map(
        media =>
          media && (
            <a href={media.url} target="_blank" key={media.id}>
              {media.type === CommentMediaTypes.Video && (
                <video
                  src={media.url}
                  style={styles.commentMedia}
                  controls={false}
                  autoPlay={false}
                />
              )}
              {media.type === CommentMediaTypes.Audio && (
                <audio
                  src={media.url}
                  style={styles.commentMedia}
                  controls={false}
                  autoPlay={false}
                />
              )}
              {(media.type === CommentMediaTypes.Image ||
                media.type === CommentMediaTypes.Gif) && (
                <img src={media.url} style={styles.commentMedia} />
              )}
            </a>
          )
      )}
    </div>
  );
};

/**
 * CommentBlock
 */
const CommentBlock = ({
  comment,
  isParentComment,
  isInThread,
  spyVersion,
  setSearchParams,
}: {
  comment: CommentType;
  isParentComment?: boolean;
  isInThread: boolean;
  spyVersion: boolean;
  setSearchParams: any;
}) => {
  const redirect = useRedirect();

  const groupedReactions = useMemo(() => {
    const reactions = comment?.reactions || [];
    return reactions.reduce(
      (acc, reaction) => {
        if (!acc[reaction.label]) {
          acc[reaction.label] = [];
        }
        acc[reaction.label].push(reaction);
        return acc;
      },
      {} as Record<string, UserCommentReactionType[]>
    );
  }, [comment]);

  return (
    <div
      style={{
        ...styles.commentContainer,
        ...(isParentComment ? styles.parentCommentContainer : null),
      }}
    >
      {/* Header */}
      <div style={styles.commentHeader}>
        <a style={styles.commentHeaderLeft} href={`#/users/${comment.authorId}/show`}>
          {comment.author && (
            <UserAvatar style={styles.commentAuthorImage} record={comment.author} />
          )}
          <div style={styles.commentHeaderAuthor}>{comment.author?.username}</div>
        </a>
        <div style={styles.commentHeaderDate}>
          {dateFormatter(comment.createdAt, {
            withTime: true,
            short: true,
          })}
        </div>
      </div>

      {/* Comment */}
      <ReactMarkdown>
        {(spyVersion ? comment.messageForSpies : comment.message) ||
          comment.silentMessageEN ||
          ''}
      </ReactMarkdown>

      {/* Media */}
      <CommentMedia comment={comment} />

      {/* Footer */}
      {!!comment.message && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: SpacingStyle.small,
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: SpacingStyle[4],
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            {Object.entries(groupedReactions)?.map(userReaction => (
              <div key={userReaction[0]} style={styles.commentReaction}>
                {userReaction[0]} {userReaction[1].length}
              </div>
            ))}
          </div>
          <div
            style={{
              display: 'flex',
              gap: SpacingStyle[4],
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <div
              style={styles.commentOpenThread}
              onClick={() => redirect('edit', 'topic_comments', comment.id)}
            >
              <Edit width={12} height={12} stroke={Colors.Grey.primary} />
              edit
            </div>
            {!!comment?.highlight?.id && (
              <div
                onClick={() =>
                  redirect('show', 'topic_highlights', comment!.highlight!.id)
                }
                style={styles.commentOpenThread}
              >
                <ElectricBoltIcon style={{ fontSize: 12 }} />
                open highlight
              </div>
            )}
            {!isInThread && (
              <div
                style={styles.commentOpenThread}
                onClick={() => setSearchParams({ commentId: comment.id })}
              >
                open thread
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export const TopicChat = () => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const { createCompletion, isLoading: isSuggestingComment } = useOpenAI();

  const { topicId } = useParams() || {};
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedAuthor, setSelectedAuthor] = useState<UserType>();
  const [authorModalOpen, setAuthorModalOpen] = useState(false);
  const [spyVersion, displaySpyVersion] = useState(false);
  const [isCommentCreating, setIsCommentCreating] = useState(false);
  const commentListRef = createRef<HTMLDivElement>();

  const {
    data: topic,
    isLoading: isTopicLoading,
    refetch: refetchTopic,
  } = useGetOne('topics', {
    id: topicId,
  });
  const { data: topicComments, isLoading: areTopicCommentsLoading } = useGetList(
    'topic_comments',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'createdAt', order: 'DESC' },
      filter: {
        topicId,
        parentCommentId: searchParams?.get('commentId'),
      },
    }
  );

  const { data: parentComment } = useGetOne(
    'topic_comments',
    {
      id: searchParams.get('commentId'),
    },
    {
      enabled: !!searchParams.get('commentId'),
    }
  );

  const { data: authors, isLoading: areAuthorsLoading } = useGetList('users', {
    pagination: { page: 1, perPage: 200 },
    sort: { field: 'username', order: 'ASC' },
    filter: {
      phoneNumber: null,
      appleUserIdentifier: null,
    },
  });

  const { data: chilliTeam, isLoading: isChilliTeamLoading } = useGetList('users', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'username', order: 'ASC' },
    filter: {
      username: [
        'xave',
        'chris_from_chilli',
        'ju_ledrogo',
        'Matisse',
        'solarpunkwithadhd',
      ],
    },
  });

  /**
   *
   * @param commentA
   * @param commentB
   * @returns {number}
   */
  const sortComments = (commentA: CommentType, commentB: CommentType) => {
    if (commentA.createdAt < commentB.createdAt) {
      return -1;
    }
    if (commentA.createdAt > commentB.createdAt) {
      return 1;
    }
    return 0;
  };

  /**
   * Handle creating a comment
   * @returns {Promise<void>}
   */
  const handleCreateComment = async () => {
    const textarea = document.querySelector('textarea') as HTMLTextAreaElement;
    const message = textarea.value.trim();
    if (!message || !selectedAuthor || isCommentCreating) {
      return;
    }
    setIsCommentCreating(true);
    try {
      await dataProvider.createCommentViaChat({
        topicId,
        authorId: selectedAuthor.id,
        parentCommentId: searchParams.get('commentId'),
        message,
      });
      await refetchTopic();
      refresh();
    } finally {
      setSelectedAuthor(undefined);
      setIsCommentCreating(false);
    }
    textarea.value = '';
  };

  /**
   * Write a suggested comment using OpenAI
   */
  const suggestComment = useCallback(async () => {
    const textarea = document.querySelector('textarea') as HTMLTextAreaElement;
    if (!textarea) {
      return;
    }
    try {
      const systemMessage = `
You are an AI assistant helping a user write a reply in a chat conversation.
You must follow the following instructions:
- Given the context of the conversation, write a reply that is relevant and engaging.
- You can ask questions, provide information, or make suggestions to keep the conversation going.
- Always answer using the same language and tone as the conversation.
- Keep the reply short (less than 100 words) and to the point.
`;
      const prompt = `
# Who are you in the conversation?
- username: ${selectedAuthor?.username}
- bio: ${selectedAuthor?.bio?.replace(/\n/gi, ' ') || 'none'}
- location: ${selectedAuthor?.region || 'none'}, ${selectedAuthor?.country || 'none'}
${
  parentComment
    ? `
# Main message was:
- **${parentComment.author.username} wrote:** ${parentComment.messageSanitized}
`
    : ''
}

# Last 20 messages of the conversation:
${topicComments
  ?.filter(comment => comment.messageSanitized)
  .slice(-20)
  .map(
    comment =>
      `- **${comment.author.username} wrote:** ${comment.messageSanitized.replace(/\n/g, ' ')}`
  )
  .join('\n')}
`;
      const response = await createCompletion({ systemMessage, prompt });
      if (response) {
        textarea.value = response;
      }
    } catch (error: any) {
      console.error('[TopicChat.suggestComment]', error);
    }
  }, [topicComments, selectedAuthor, parentComment, createCompletion]);

  const isInThread = useMemo(() => !!searchParams.get('commentId'), [searchParams]);

  useEffect(() => {
    if (topicComments && topicComments.length && commentListRef?.current) {
      commentListRef.current.scrollTop = commentListRef.current.scrollHeight;
    }
  }, [topicComments, commentListRef]);

  if (
    areTopicCommentsLoading ||
    isTopicLoading ||
    areAuthorsLoading ||
    isChilliTeamLoading
  ) {
    return;
  }

  if (!topic) {
    return <div>Topic not found</div>;
  }

  return (
    <div
      style={{
        ...styles.wrapper,
        ...(spyVersion ? { backgroundColor: Colors.Grey.primary } : null),
      }}
    >
      <AuthorsModal
        authors={[...(chilliTeam || []), ...(authors || [])]}
        open={authorModalOpen}
        onClose={() => setAuthorModalOpen(false)}
        onSelect={(author: UserType) => {
          setSelectedAuthor(author);
          setAuthorModalOpen(false);
        }}
      />
      <div style={styles.container}>
        <div style={styles.header}>
          <div>
            {isInThread ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: SpacingStyle[4],
                  cursor: 'pointer',
                }}
                onClick={() => setSearchParams()}
              >
                <ChevronDown
                  style={{ transform: 'rotate(90deg)' }}
                  width={18}
                  height={18}
                  strokeWidth={1.5}
                />
                <div style={styles.headerTopicName}>Back</div>
              </div>
            ) : (
              <>
                <div style={styles.headerTopicName}>{topic.nameEN}</div>
                <div style={styles.headerTopicMembers}>
                  {(topic.users || []).length} members
                </div>
              </>
            )}
          </div>
          <label style={{ display: 'flex', flexDirection: 'row' }}>
            <input
              type="checkbox"
              onChange={event => displaySpyVersion(!!event.target.checked)}
            />
            🕵️
          </label>
        </div>
        <div style={styles.commentList} ref={commentListRef}>
          {!parentComment && topicComments?.length === 0 && (
            <div style={styles.commentContainer}>
              No comments yet. Be the first one to comment!
            </div>
          )}
          {parentComment && (
            <CommentBlock
              comment={parentComment}
              isParentComment
              isInThread={isInThread}
              spyVersion={spyVersion}
              setSearchParams={setSearchParams}
            />
          )}
          {(topicComments || []).sort(sortComments).map((comment: CommentType) => (
            <CommentBlock
              key={comment.id}
              comment={comment}
              isInThread={isInThread}
              spyVersion={spyVersion}
              setSearchParams={setSearchParams}
            />
          ))}
        </div>
        {!spyVersion && (
          <div style={styles.inputContainer}>
            <div
              style={styles.inputAuthorButton}
              onClick={() => setAuthorModalOpen(true)}
            >
              {selectedAuthor ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: SpacingStyle.small,
                  }}
                >
                  <UserAvatar record={selectedAuthor} width={18} height={18} />
                  {selectedAuthor.username}
                </div>
              ) : (
                'Select an author'
              )}
              <ChevronDown width={18} height={18} stroke={Colors.Grey.primary} />
            </div>
            <textarea
              style={styles.inputTextarea}
              rows={8}
              placeholder={isInThread ? 'Reply to thread' : 'Write a comment'}
              disabled={!selectedAuthor || isCommentCreating || isSuggestingComment}
            />
            <div style={styles.inputFooter}>
              <Button
                label="Suggest a comment"
                startIcon={<PepperAvatar />}
                onClick={suggestComment}
                disabled={!selectedAuthor || isCommentCreating || isSuggestingComment}
                variant="outlined"
              />
              <Button
                disabled={!selectedAuthor || isCommentCreating || isSuggestingComment}
                onClick={handleCreateComment}
                label={isInThread ? 'Reply' : 'Comment'}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const styles: any = {
  wrapper: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '94vh',
    width: '100%',
    maxWidth: '600px',
    backgroundColor: Colors.White.primary,
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.05)',
    borderRadius: BorderStyle.Radius.normal,
    border: `1px solid ${Colors.Grey[100]}`,
    overflow: 'hidden',
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: SpacingStyle.normal,
    borderBottom: `1px solid ${Colors.Grey[50]}`,
    backgroundColor: Colors.White.primary,
  },
  headerTopicName: {
    fontSize: FontStyle.sizeNormal,
    fontWeight: 700,
  },
  headerTopicMembers: {
    fontSize: FontStyle.sizeVerySmall,
    color: Colors.Grey[600],
  },

  commentList: {
    flexGrow: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  parentCommentContainer: {
    margin: SpacingStyle.small,
    backgroundColor: Colors.Magenta[50],
    border: `1px solid ${Colors.Magenta.primary}`,
    borderRadius: BorderStyle.Radius.normal,
  },
  commentContainer: {
    padding: `${SpacingStyle.normal}px ${SpacingStyle.normal}px`,
    backgroundColor: Colors.White.primary,
    borderBottom: `1px solid ${Colors.Grey[50]}`,
  },
  commentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  commentHeaderLeft: {
    display: 'flex',
    alignItems: 'center',
    gap: SpacingStyle.small,
    textDecoration: 'none !important',
  },
  commentHeaderAuthor: {
    color: Colors.OffBlack.primary,
    fontSize: FontStyle.sizeSmall,
    fontWeight: 700,
  },
  commentHeaderDate: {
    fontSize: FontStyle.sizeVerySmall,
    color: Colors.Grey[600],
  },
  commentOpenThread: {
    display: 'flex',
    alignItems: 'center',
    gap: SpacingStyle[4],
    padding: '2px 8px',
    textAlign: 'right',
    fontSize: FontStyle.sizeVeryVerySmall,
    fontWeight: 400,
    color: Colors.Grey[800],
    cursor: 'pointer',
    border: `1px solid ${Colors.Grey[300]}`,
    borderRadius: BorderStyle.Radius.normal,
  },
  commentMedias: {
    display: 'flex',
    flexDirection: 'row',
    gap: SpacingStyle.small,
    marginBottom: SpacingStyle.small,
  },
  commentMedia: {
    display: 'block',
    width: '100%',
    height: '100%',
    maxWidth: 200,
    maxHeight: 120,
    objectFit: 'cover',
    borderRadius: BorderStyle.Radius.small,
    border: `0.5px solid ${Colors.Grey.primary}`,
    backgroundColor: Colors.Grey[200],
  },

  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: SpacingStyle.small,
    padding: SpacingStyle.small,
    borderTop: `1px solid ${Colors.Grey[100]}`,
    backgroundColor: Colors.OffWhite.primary,
  },
  inputTextarea: {
    width: '100%',
    padding: SpacingStyle.normal,
    borderRadius: BorderStyle.Radius.normal,
    border: `1px solid ${Colors.Grey[100]}`,
    fontFamily: 'inherit',
    fontSize: FontStyle.sizeMedium,
    resize: 'none',
  },
  inputAuthorButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${SpacingStyle.small}px ${SpacingStyle.normal}px`,
    minWidth: 100,
    fontSize: FontStyle.sizeSmall,
    fontWeight: 600,
    color: Colors.OffBlack.primary,
    borderRadius: BorderStyle.Radius.normal,
    backgroundColor: Colors.White.primary,
    border: `1px solid ${Colors.Grey[200]}`,
    cursor: 'pointer',
  },
  inputFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  inputSendButton: {
    padding: `${SpacingStyle.small}px ${SpacingStyle.normal}px`,
    fontSize: FontStyle.sizeSmall,
    fontWeight: 700,
    color: Colors.White.primary,
    borderRadius: BorderStyle.Radius.normal,
    backgroundColor: Colors.OffBlack.primary,
    cursor: 'pointer',
  },
  inputSendButtonDisabled: {
    backgroundColor: Colors.Grey[100],
    color: Colors.Grey[600],
    cursor: 'not-allowed',
  },
  commentReaction: {
    fontSize: FontStyle.sizeVerySmall,
    color: Colors.Grey[600],
    backgroundColor: Colors.OffWhite.primary,
    border: `1px solid ${Colors.Grey[100]}`,
    borderRadius: BorderStyle.Radius.normal,
    padding: '0 5px',
    userSelect: 'none',
  },
};

const authorsModal: any = {
  background: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: Colors.White.transparent.max,
    backdropFilter: 'blur(4px)',
    zIndex: 10,
  },
  wrapper: {
    position: 'fixed',
    bottom: 300,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    zIndex: 15,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: SpacingStyle.small,
    maxHeight: '55vh',
    overflowY: 'auto',
    backgroundColor: Colors.White.primary,
    borderRadius: BorderStyle.Radius.small,
    boxShadow: '0 2px 20px rgba(0, 0, 0, 0.1)',
  },
  header: {
    fontSize: FontStyle.sizeSmall,
    fontWeight: 600,
    marginBottom: SpacingStyle.small,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: SpacingStyle.small,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 240,
    padding: SpacingStyle.small,
    gap: SpacingStyle.small,
    borderRadius: BorderStyle.Radius.normal,
    backgroundColor: Colors.Grey[50],
    cursor: 'pointer',
  },
  input: {
    marginTop: SpacingStyle.normal,
    padding: SpacingStyle.small,
    borderRadius: BorderStyle.Radius.small,
    border: `1px solid ${Colors.Black.transparent.max}`,
    backgroundColor: Colors.OffWhite.primary,
    fontSize: FontStyle.sizeSmall,
  },
};
