import React, { useEffect, useState } from 'react';
import {
  Create,
  DateTimeInput,
  Labeled,
  ReferenceField,
  SimpleForm,
  TextInput,
} from 'react-admin';
import ReactMarkdown from 'react-markdown';
import moment from 'moment-timezone';
import { useLocation } from 'react-router-dom';

import { useAdminRoles } from '@hooks/useAdminRoles';
import Callout from '@components/callout';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import { Colors, SpacingStyle } from '@styles/variables';

const CreateActions = <ResourceTitleActionBar mode="create" title="Post a Pepper Pill" />;

const PEPPER_USER_ID = '96131f74-c453-40b2-b95a-30d6de38652c';

const redirectAfterCreate = (basePath: any, id: any, data: any) =>
  `topic_comments/${data.id}/show`;

export const TopicCommentCreatePepperPill = () => {
  const maxChar = useAdminRoles('super_admin') ? 5_000 : 500;
  const location = useLocation();

  const [record, setRecord] = useState({});
  const [intro, setIntro] = useState('🌶️ Pepper Pill 🌶️');
  const [happyPill, setHappyPill] = useState('');
  const [truthPill, setTruthPill] = useState('');
  const [detoxPill, setDetoxPill] = useState('');
  const [conclusion, setConclusion] = useState('');
  const [finalMessage, setFinalMessage] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const source = queryParams.get('source');
    if (!source) {
      return;
    }
    try {
      const options = JSON.parse(decodeURIComponent(source));
      setRecord({ ...options, authorId: PEPPER_USER_ID });
    } catch (error) {
      console.error('Error parsing source', error);
    }
  }, [location]);

  useEffect(() => {
    let message = `**${intro ? intro.trim() : 'MISSING INTRO'}**`;
    if (happyPill) {
      message += `\n\n**💊 Happy pill:** ${happyPill.trim()}`;
    }
    if (truthPill) {
      message += `\n\n**💊 Truth pill:** ${truthPill.trim()}`;
    }
    if (detoxPill) {
      message += `\n\n**💊 Detox pill:** ${detoxPill.trim()}`;
    }
    if (conclusion) {
      message += `\n\n${conclusion.trim()}`;
    }
    setFinalMessage(message);
  }, [intro, happyPill, truthPill, detoxPill, conclusion]);

  const pepperAvatar = (
    <img
      src="https://res.cloudinary.com/hsiz9ovy1/image/upload/v1706557985/activists/xave_2REkS9.png"
      alt="Pepper"
      style={{
        width: '42px',
        height: '42px',
        borderRadius: '50%',
      }}
    />
  );

  const preventEnter = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <div style={{ marginBottom: '20vh' }}>
      <Create
        actions={CreateActions}
        redirect={redirectAfterCreate}
        transform={(data: any) => ({ ...data, message: finalMessage })}
      >
        <SimpleForm record={record} warnWhenUnsavedChanges>
          <Labeled label="Topic">
            <ReferenceField source="topicId" reference="topics" />
          </Labeled>

          <Labeled label="Author">
            <ReferenceField source="authorId" reference="users" />
          </Labeled>
          <Callout emoji="📝">
            <ul>
              <li>
                You can use <strong>**strong text**</strong> or <i>_italic_</i> styles
              </li>
              <li>
                Links must start with <strong>https:// </strong>
                and used as this: [nytimes](https://nytimes.com/xxx)
              </li>
              <li>Max {maxChar} characters per input.</li>
            </ul>
          </Callout>
          <TextInput
            source="intro"
            defaultValue={intro}
            fullWidth
            inputProps={{ maxLength: maxChar }}
            helperText={`Character count: ${intro.length}/${maxChar}`}
            onChange={e => setIntro(e.target.value)}
            onKeyDown={preventEnter}
          />
          <TextInput
            source="happyPill"
            label="💊 Happy pill"
            defaultValue={''}
            fullWidth
            inputProps={{ maxLength: maxChar }}
            helperText={`Character count: ${happyPill.length}/${maxChar}`}
            onKeyDown={preventEnter}
            onChange={e => setHappyPill(e.target.value)}
          />
          <TextInput
            source="truthPill"
            label="💊 Truth pill"
            defaultValue={''}
            fullWidth
            inputProps={{ maxLength: maxChar }}
            helperText={`Character count: ${truthPill.length}/${maxChar}`}
            onKeyDown={preventEnter}
            onChange={e => setTruthPill(e.target.value)}
          />
          <TextInput
            source="detoxPill"
            label="💊 Detox pill"
            defaultValue={''}
            fullWidth
            inputProps={{ maxLength: maxChar }}
            helperText={`Character count: ${detoxPill.length}/${maxChar}`}
            onKeyDown={preventEnter}
            onChange={e => setDetoxPill(e.target.value)}
          />
          <TextInput
            source="conclusion"
            defaultValue={''}
            fullWidth
            inputProps={{ maxLength: maxChar }}
            helperText={`Character count: ${conclusion.length}/${maxChar}`}
            onChange={e => setConclusion(e.target.value)}
            onKeyDown={preventEnter}
          />
          <br />
          <DateTimeInput
            source="createdAt"
            label="Published at"
            defaultValue={moment()
              .add(1, 'day')
              .set('hours', 10)
              .set('minutes', 0)
              .set('seconds', 0)}
          />

          <Callout
            emoji={pepperAvatar}
            backgroundColor={Colors.Grey[25]}
            style={{ marginBottom: SpacingStyle.big }}
          >
            <small>
              <strong>Pepper Pill preview:</strong>
            </small>
            <ReactMarkdown>{finalMessage || ''}</ReactMarkdown>
          </Callout>
        </SimpleForm>
      </Create>
    </div>
  );
};
