import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Form, useDataProvider, useRefresh } from 'react-admin';
import EditIcon from '@mui/icons-material/Edit';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import moment from 'moment-timezone';
import { ActionType } from 'types/action';
import { MissionType } from 'types/mission';

import { useAdminRoles } from '@hooks/useAdminRoles';
import { useOpenAI } from '@hooks/useOpenAI';
import CustomReferenceInput from '@components/inputs/custom_reference_input';
import PepperAvatar from '@components/pepper_avatar';
import InputStyle from '@styles/input';
import { Colors, FontStyle, SpacingStyle } from '@styles/variables';

const INPUT_VALIDATION_MESSAGES: any = {
  badInput: {
    text: 'Invalid text. Please re-enter.',
    number: 'Invalid number. Please re-enter.',
    'datetime-local': 'Invalid date/time. Please re-enter.',
  },
  customError: {
    text: 'Error. Check guidelines.',
    number: 'Error. Check guidelines.',
    'datetime-local': 'Error. Check guidelines.',
  },
  patternMismatch: {
    text: 'Incorrect format. Please retry.',
    number: 'Incorrect format. Please retry.',
    'datetime-local': 'Incorrect format. Please retry.',
  },
  rangeOverflow: {
    text: 'Text too long. Shorten and retry.',
    number: 'Number too high. Lower and retry.',
    'datetime-local': 'Date/time too late. Adjust and retry.',
  },
  rangeUnderflow: {
    text: 'Text too short. Lengthen and retry.',
    number: 'Number too low. Increase and retry.',
    'datetime-local': 'Date/time too early. Adjust and retry.',
  },
  stepMismatch: {
    text: 'Invalid step. Please retry.',
    number: 'Invalid step. Please retry.',
    'datetime-local': 'Invalid step. Please retry.',
  },
  tooLong: {
    text: 'Too long. Please shorten.',
    number: 'Too long. Please shorten.',
    'datetime-local': 'Too long. Please shorten.',
  },
  tooShort: {
    text: 'Too short. Please lengthen.',
    number: 'Too short. Please lengthen.',
    'datetime-local': 'Too short. Please lengthen.',
  },
  typeMismatch: {
    text: 'Expecting text. Please retry.',
    number: 'Expecting a number. Please retry.',
    'datetime-local': 'Expecting date/time. Please retry.',
  },
  valueMissing: {
    text: 'Text required. Please enter.',
    number: 'Number required. Please enter.',
    'datetime-local': 'Date/time required. Please enter.',
  },
};

type UpdateFieldDialogProps = {
  resource: 'actions' | 'missions';
  record: ActionType | MissionType;
  field: keyof ActionType | keyof MissionType;
  formatter?: (value: any) => any;
  type?: 'text' | 'textarea' | 'number' | 'date' | 'autocomplete' | 'select';
  color?: string;
  buttonLabel?: string;
  buttonVariant?: 'text' | 'outlined' | 'contained';
  buttonIcon?: React.ReactNode;
  selectOptions?: {
    id: string | number | undefined;
    name: string | number;
    disabled?: boolean;
    selected?: boolean;
  }[];
  maxLength?: number;
  autocompleteReference?: string;
  autocompleteQueryKey?: string | string[];
  disabled?: boolean;
  confirmationMessage?: string;
  defaultValue?: string;
  showSaveButton?: boolean;
  hint?: string;
  children?: React.ReactNode;
};
export const UpdateFieldDialog = ({
  resource,
  record,
  field,
  formatter,
  type = 'text',
  color,
  buttonLabel,
  buttonVariant = 'text',
  buttonIcon,
  autocompleteReference,
  autocompleteQueryKey,
  selectOptions,
  maxLength,
  disabled = false,
  confirmationMessage,
  showSaveButton,
  defaultValue,
  hint,
  children,
}: UpdateFieldDialogProps) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const isReadOnly = useAdminRoles('read_only');
  const { isLoading: isOpenAILoading, fixGrammar, translateText } = useOpenAI();

  const textInputRef = useRef<any>(null);

  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fieldValue, setFieldValue] = useState<any>(defaultValue);
  // default timezone is the user's timezone
  const [userTimezone] = useState<string>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [error, setError] = useState('');

  showSaveButton = showSaveButton ?? !['autocomplete', 'select'].includes(type);

  /**
   * @returns true if the input grammar can be fixed
   */
  const showFixGrammarButton = useMemo(
    () => type === 'text' || type === 'textarea',
    [type]
  );

  /**
   * Returns if the translation button should be shown
   * and the value to translate
   */
  const Translation = useMemo(() => {
    // 1. show the translation button if the grammar button is shown and the field is in English or French
    const showButton =
      showFixGrammarButton && (field.endsWith('EN') || field.endsWith('FR'));

    // 2. get the field to translate and its value
    const fieldToTranslate = showButton
      ? field.endsWith('EN')
        ? field.replace('EN', 'FR')
        : field.replace('FR', 'EN')
      : null;
    let valueToTranslate = null;
    if (showButton && fieldToTranslate && record && (record as any)[fieldToTranslate]) {
      valueToTranslate = (record as any)[fieldToTranslate];
    }

    // 3. get the from language
    const fromLanguage = field.endsWith('EN') ? 'FR' : 'EN';

    // 4. get the to language
    const toLanguage = field.endsWith('EN') ? 'EN' : 'FR';

    return { showButton, valueToTranslate, fromLanguage, toLanguage };
  }, [showFixGrammarButton, record, field]);

  useEffect(() => {
    if (record && (record as any)[field]) {
      setFieldValue((record as any)[field]);
    } else if (defaultValue && !fieldValue) {
      setFieldValue(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record, field, defaultValue]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        if (textInputRef.current) {
          textInputRef.current.focus();
        }
      }, 100);
    }
  }, [open]);

  /**
   * @returns true if the input is valid
   */
  const checkForErrors = useCallback(() => {
    if (!textInputRef?.current) {
      return;
    }

    const inputType = textInputRef.current.type;
    const validityState: any = textInputRef.current.validity;
    const errors = [];
    for (const key in validityState) {
      if (!!validityState[key] && key !== 'valid') {
        errors.push(INPUT_VALIDATION_MESSAGES[key][inputType]);
      }
    }
    setError(errors.join('\n'));
    return errors.length > 0;
  }, []);

  /**
   * Open the modal
   */
  const handleClickOpen = useCallback(() => {
    setOpen(true);
    setIsSubmitting(false);
  }, []);

  /**
   * Close the modal
   */
  const handleClose = useCallback(
    (withConfirmation = false) => {
      if (isSubmitting) {
        window.alert("You can't close this modal while the record is being updated.");
        return;
      }
      const confirmSentence =
        'Are you sure you want to close this modal?\nYour changes will be lost.';
      if (withConfirmation && !window.confirm(confirmSentence)) {
        return;
      }
      setOpen(false);
    },
    [isSubmitting]
  );

  /**
   * Update the record
   */
  const updateRecord = useCallback(
    async (forcedFieldValue?: any) => {
      if (checkForErrors()) {
        return;
      }
      if (
        !!confirmationMessage &&
        !window.confirm(confirmationMessage || 'Are you sure?')
      ) {
        return;
      }
      setIsSubmitting(true);
      try {
        let updatedValue = forcedFieldValue || textInputRef?.current?.value;
        updatedValue = formatter ? formatter(updatedValue) : updatedValue;
        await dataProvider.update(resource, {
          id: record.id,
          data: {
            [field]: updatedValue,
          },
        } as any);
        await refresh();
        setOpen(false);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setIsSubmitting(false);
      }
    },
    [
      record,
      field,
      formatter,
      checkForErrors,
      confirmationMessage,
      dataProvider,
      refresh,
      resource,
    ]
  );

  /**
   * Fix the grammar of the input
   * and update the input value
   */
  const fixGrammarHandler = useCallback(async () => {
    if (!textInputRef?.current?.value) {
      return;
    }
    try {
      const response = await fixGrammar({ text: textInputRef?.current?.value });
      setFieldValue(response);
    } catch (error: any) {
      setError(error.message);
    }
  }, [fixGrammar]);

  /**
   * Translate the input text
   */
  const translateTextHandler = useCallback(async () => {
    if (!Translation.valueToTranslate) {
      return;
    }
    try {
      const response = await translateText({
        text: Translation.valueToTranslate,
        from: Translation.fromLanguage,
        to: Translation.toLanguage,
      });
      setFieldValue(response);
    } catch (error: any) {
      setError(error.message);
    }
  }, [
    translateText,
    Translation.valueToTranslate,
    Translation.fromLanguage,
    Translation.toLanguage,
  ]);

  /**
   * @returns the text input type
   */
  const InputTypeText = () => {
    if (type !== 'text') {
      return <></>;
    }

    return (
      <>
        <input
          type="text"
          ref={textInputRef}
          autoFocus
          style={InputStyle.input}
          placeholder={field}
          defaultValue={fieldValue}
          disabled={isSubmitting || isOpenAILoading}
          maxLength={maxLength}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              updateRecord();
            }
          }}
        />
        {maxLength && (
          <div
            style={{
              marginTop: SpacingStyle.small,
              fontSize: FontStyle.sizeVerySmall,
              color: Colors.Grey[700],
            }}
          >
            <strong>Max length:</strong> {maxLength}
          </div>
        )}
      </>
    );
  };

  /**
   * @returns the text input type
   */
  const Textarea = () => {
    if (type !== 'textarea') {
      return <></>;
    }

    return (
      <textarea
        ref={textInputRef}
        autoFocus
        style={{ ...InputStyle.input, resize: 'vertical' }}
        placeholder={field}
        defaultValue={fieldValue}
        disabled={isSubmitting || isOpenAILoading}
        rows={10}
        maxLength={maxLength}
      />
    );
  };

  /**
   * @returns the number input type
   */
  const InputTypeNumber = () => {
    if (type !== 'number') {
      return <></>;
    }

    return (
      <input
        type="number"
        ref={textInputRef}
        autoFocus
        style={InputStyle.input}
        placeholder={field}
        defaultValue={fieldValue}
        disabled={isSubmitting || isOpenAILoading}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            updateRecord();
          }
        }}
      />
    );
  };

  /**
   * @returns the select input type
   */
  const InputTypeSelect = () => {
    if (type !== 'select') {
      return <></>;
    } else if (!selectOptions) {
      console.error('selectOptions is required');
      return <>Error - see console</>;
    }

    return (
      <select
        autoFocus
        style={InputStyle.input}
        value={fieldValue}
        disabled={isSubmitting || isOpenAILoading}
        onChange={e => {
          updateRecord(e.target.value);
        }}
      >
        {selectOptions.map(option => (
          <option
            key={option.id}
            value={option.id}
            disabled={option.disabled}
            selected={option.selected}
          >
            {option.name}
          </option>
        ))}
      </select>
    );
  };

  /**
   * @returns the autocomplete input type
   */
  const InputTypeAutocomplete = () => {
    if (type !== 'autocomplete') {
      return <></>;
    } else if (!autocompleteReference) {
      console.error('autocompleteReference is required');
      return <>Error - see console</>;
    }

    return (
      <Form>
        <CustomReferenceInput
          source={field}
          reference={autocompleteReference}
          queryKey={autocompleteQueryKey}
          disabled={isSubmitting || isOpenAILoading}
          onChange={(newFieldValue: any) => {
            if (newFieldValue) {
              updateRecord(newFieldValue);
            }
          }}
        />
      </Form>
    );
  };

  /**
   * @returns the date input type
   */
  const InputTypeDate = () => {
    if (type !== 'date') {
      return <></>;
    }
    // Tomorrow, 6am
    const defaultValue = moment()
      .add(1, 'day')
      .hour(6)
      .minute(0)
      .second(0)
      .format('YYYY-MM-DD HH:mm');

    return (
      <div>
        <input
          type="datetime-local"
          ref={textInputRef}
          autoFocus
          style={InputStyle.input}
          placeholder={field}
          defaultValue={fieldValue || defaultValue}
          disabled={isSubmitting || isOpenAILoading}
          min={moment().subtract(1, 'day').format('YYYY-MM-DDTHH:mm')}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              updateRecord();
            }
          }}
        />
        <div
          style={{
            marginTop: SpacingStyle[4],
            fontSize: FontStyle.sizeSmall,
            color: Colors.Grey[700],
          }}
        >
          <strong>Timezone:</strong> {userTimezone}
        </div>
      </div>
    );
  };

  if (isReadOnly) {
    return <></>;
  }

  return (
    <div>
      <Button
        variant={buttonVariant}
        onClick={handleClickOpen}
        label={
          buttonLabel || (record && (record as any)[field])?.toString() || `No ${field}`
        }
        startIcon={buttonIcon ? buttonIcon : <EditIcon style={{ width: '.8em' }} />}
        size="small"
        style={{
          color,
          textTransform: 'none',
          fontSize: 'inherit',
          fontWeight: 'inherit',
        }}
        disabled={disabled}
      />
      <Dialog
        onClose={() => handleClose(true)}
        open={open}
        maxWidth={showFixGrammarButton ? 'sm' : 'xs'}
        fullWidth
      >
        <DialogTitle>Edit record's {field?.replace('Id', '')}</DialogTitle>
        <DialogContent>
          {children}
          {hint && <DialogContentText>{hint}</DialogContentText>}
          <InputTypeText />
          <InputTypeNumber />
          <InputTypeSelect />
          <InputTypeAutocomplete />
          <InputTypeDate />
          <Textarea />
          {error && <div style={styles.error}>{error}</div>}
        </DialogContent>
        <DialogActions>
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'space-between',
            }}
          >
            <Button
              onClick={() => handleClose()}
              label="Cancel"
              disabled={isSubmitting || isOpenAILoading}
            />
            <div>
              {Translation.showButton && (
                <Button
                  onClick={translateTextHandler}
                  label={`Translate from ${Translation.fromLanguage}`}
                  disabled={isOpenAILoading || isSubmitting}
                  startIcon={<PepperAvatar />}
                  variant="outlined"
                  title="Let Pepper translate your text"
                />
              )}
              {showFixGrammarButton && (
                <Button
                  onClick={fixGrammarHandler}
                  label="Fix grammar"
                  disabled={isOpenAILoading || isSubmitting}
                  startIcon={<PepperAvatar />}
                  variant="outlined"
                  title="Let Pepper fix your grammar"
                />
              )}
              {showSaveButton && (
                <Button
                  onClick={() => updateRecord()}
                  label="Update"
                  disabled={isOpenAILoading || isSubmitting}
                  variant="contained"
                />
              )}
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const styles: any = {
  error: {
    color: Colors.Red.primary,
  },
};
