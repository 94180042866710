import React, { useCallback, useEffect, useState } from 'react';
import { Title, useDataProvider } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import { DonationType } from 'types/donation';
import moment from 'moment-timezone';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import Calendar, { CalendarItems } from '@components/calendar';
import DonationCard from '@components/calendar/components/donation_card';

const ShopIndex = () => {
  const dataProvider = useDataProvider();
  const [calendarItems, setCalendarItems] = useState<CalendarItems>({});

  /**
   * Fetch donations
   * @returns {Promise<CalendarItems>}
   */
  const fetchCalendarItems = useCallback(async () => {
    const { data } = await dataProvider.getList('donations', {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'createdAt', order: 'DESC' },
      filter: {},
    });
    const result: CalendarItems = {};
    data.forEach((donation: DonationType) => {
      const days = [];
      let launchedAt = moment(donation.launchedAt);
      const expiresAt = donation.expiredAt
        ? moment(donation.expiredAt)
        : moment().add(1, 'month');
      while (launchedAt.isBefore(moment(expiresAt))) {
        const day = launchedAt.format('YYYY-MM-DD');
        if (!result[day]) {
          result[day] = [];
        }
        result[day].push(<DonationCard key={donation.id} donation={donation} />);
        days.push(day);
        launchedAt = launchedAt.add(1, 'day');
      }
    });
    setCalendarItems(result);
  }, [dataProvider]);

  useEffect(() => {
    fetchCalendarItems();
  }, [fetchCalendarItems]);

  return (
    <>
      <ResourceTitleActionBar title="Shop" mode="list" />
      <Card>
        <Title title="Shop" />
        <CardContent>
          <Calendar fetchItems={() => new Promise(resolve => resolve(calendarItems))} />
        </CardContent>
      </Card>
    </>
  );
};

export default ShopIndex;
