import React from 'react';
import {
  Button,
  Create,
  SimpleForm,
  TextInput,
  required,
  useRecordContext,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { parsePhoneNumber } from 'libphonenumber-js';

import Callout from '@components/callout';
import CustomReferenceInput from '@components/inputs/custom_reference_input';
import Favicon from '@components/svgs/logos/favicon';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const CreateActions = () => {
  const params = useParams();
  const record = useRecordContext();
  let actionId = params?.actionId ? params.actionId : record?.actionId;
  return (
    <ResourceTitleActionBar
      mode="create"
      actions={
        <Button
          href={`#/actions/${actionId}/show`}
          startIcon={<Favicon width={15} height={12} />}
          label="Back to Action"
          variant="outlined"
        />
      }
    />
  );
};

const redirectAfterCreateEdit = (_basePath: any, _id: any, data: any) =>
  `actions/${data.actionId}/show/cta`;

/**
 * Validate the script of the phone call
 * @param value
 * @returns boolean
 */
export const validateScriptContent = (value: string) => {
  if (!value) {
    return 'This field is required';
  }
  if (value.includes('{{')) {
    return 'Must not contain {{ }}';
  }
  if (value.includes('&')) {
    return 'Must not contain any &';
  }
  if (value.includes('**')) {
    return 'Must not contain **';
  }
  return null;
};

/**
 * Validate the phone number
 * @param value
 * @returns
 */
export const validatePhoneNumber = (value: string) => {
  if (!value) {
    return 'This field is required';
  }

  try {
    parsePhoneNumber(value);
  } catch {
    return 'Invalid phone number - must be in international format (ex: +19173537000)';
  }

  return null;
};

/**
 * Clean the phone number from any non-numeric characters
 * @param value
 * @returns string
 */
export const cleanPhonenumber = (value: any) => {
  if (!value) {
    return null;
  }
  return value.replaceAll(/[^+0-9]/g, '');
};

export const GPTCallout = () => (
  <Callout emoji="🤖" backgroundColor="yellow">
    Write a default script from the perspective of the user.
    <br />
    GPT will overwrite it based on the user's value system, their name, and their location
    (city, region).
  </Callout>
);

export const ActionCtaPhoneCallCreate = () => (
  <Create redirect={redirectAfterCreateEdit} actions={<CreateActions />}>
    <SimpleForm>
      <CustomReferenceInput
        source="actionId"
        reference="actions"
        optionText="name"
        validate={[required()]}
      />
      <br />
      <TextInput
        source="phoneNumber"
        required
        fullWidth
        helperText="Phone number of the person to call. Must be in international format (ex: +19173537000)"
        validate={validatePhoneNumber}
        parse={cleanPhonenumber}
      />

      <br />
      {GPTCallout()}
      <TextInput
        source="script"
        required
        multiline
        fullWidth
        label="Script users will read"
        placeholder="Hello, my name is UserName and I'm from City, Region. I'm calling to ask you to take action on this important issue."
        validate={validateScriptContent}
      />
    </SimpleForm>
  </Create>
);
