import React, { useEffect, useState } from 'react';
import { Button, useRedirect } from 'react-admin';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import dataProvider from 'src/dataProvider';

import CRMOrganizationCard from '@models/crm/crm_organizations/components/card';
import CRMPersonCard from '@models/crm/crm_persons/components/card';
import InputStyle from '@styles/input';

import CRMCreateDialog from '../create_dialog';
import Styles from '../styles';
import LoadingAnimation from '../../svgs/loading_animation';

type CRMSearchDialogProps = {
  buttonLabel?: string;
  ctaLabel?: string;
  onClick?: (entityType: string, entityId: string, entity: any) => void;
  style?: any;
};
const CRMSearchDialog = ({
  buttonLabel,
  ctaLabel,
  onClick,
  style,
}: CRMSearchDialogProps) => {
  const redirect = useRedirect();
  const [isSearching, setIsSearching] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchQ, setSearchQ] = useState('');
  const [crmPersonsFound, setPersonsFound] = useState<any[]>([]);
  const [totalCrmPersonsFound, setTotalPersonsFound] = useState(0);
  const [crmOrganizationsFound, setOrganizationsFound] = useState<any[]>([]);
  const [totalCrmOrganizationsFound, setTotalOrganizationsFound] = useState(0);

  useEffect(() => {
    setIsSearching(true);
    if (searchQ.length === 0) {
      reinit();
    }
    const timeOutId = setTimeout(() => triggerSearch(searchQ), 250);
    return () => clearTimeout(timeOutId);
  }, [searchQ]);

  const handleClickOpen = () => {
    reinit();
    setOpen(true);
  };
  const handleClose = () => {
    reinit();
    setOpen(false);
  };
  const handleOnClick = (...args: any[]) => {
    if (!onClick) {
      if (args[0] === 'crm_persons' || args[0] === 'crm_organizations') {
        redirect('show', args[0], args[1]);
      }
    } else {
      onClick(args[0], args[1], args[2]);
    }
    handleClose();
  };

  const reinit = () => {
    setSearchQ('');
    setPersonsFound([]);
    setTotalPersonsFound(0);
    setOrganizationsFound([]);
    setTotalOrganizationsFound(0);
  };

  const triggerSearch = async (q: string) => {
    if (!q) {
      setIsSearching(false);
      setPersonsFound([]);
      setTotalPersonsFound(0);
      setOrganizationsFound([]);
      setTotalOrganizationsFound(0);
      return;
    }
    setIsSearching(true);
    const { data } = await dataProvider.crmSearch(q);
    const { crmPersons, crmOrganizations } = data;

    setPersonsFound(crmPersons?.slice(0, 20) || []);
    setTotalPersonsFound(crmPersons?.length || 0);
    setOrganizationsFound(crmOrganizations?.slice(0, 20) || []);
    setTotalOrganizationsFound(crmOrganizations?.length || 0);
    setIsSearching(false);
  };

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') {
      return;
    }
    if (crmPersonsFound.length === 1) {
      handleOnClick('crm_persons', crmPersonsFound[0].id, crmPersonsFound[0]);
      return;
    }
    if (crmOrganizationsFound.length === 1) {
      handleOnClick(
        'crm_organizations',
        crmOrganizationsFound[0].id,
        crmOrganizationsFound[0]
      );
      return;
    }
  };

  const renderNoResults = () => {
    if (searchQ.length === 0) {
      return (
        <DialogContentText style={{ marginTop: 8 }}>
          Search (or add) by firstname, lastname, organization name, email, tags...
        </DialogContentText>
      );
    }
    if (isSearching || !searchQ) {
      return null;
    }
    if (crmPersonsFound.length > 0 || crmOrganizationsFound.length > 0) {
      return null;
    }
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#f5f6f7',
          marginTop: 24,
          padding: 12,
          borderRadius: 12,
        }}
      >
        <small style={{ marginBottom: 8 }}>No results found</small>
        <CRMCreateDialog
          label="Create one"
          initialEmail={searchQ}
          onClose={() => triggerSearch(searchQ)}
        />
      </div>
    );
  };

  const renderCRMPersons = () => {
    if (
      !searchQ ||
      (crmOrganizationsFound.length === 0 && crmPersonsFound.length === 0)
    ) {
      return null;
    }
    return (
      <div style={{ flex: 1 }}>
        <h3 style={{ ...Styles.flexRow, alignItems: 'center' }}>
          <PersonIcon />
          Persons
          <small>({totalCrmPersonsFound})</small>
        </h3>
        {crmPersonsFound.map(crmPerson => (
          <CRMPersonCard
            key={crmPerson.id}
            crmPerson={crmPerson}
            onClick={handleOnClick}
            ctaLabel={ctaLabel || 'Show'}
          />
        ))}
      </div>
    );
  };

  const renderCRMOrganizations = () => {
    if (
      !searchQ ||
      (crmOrganizationsFound.length === 0 && crmPersonsFound.length === 0)
    ) {
      return null;
    }
    return (
      <div style={{ flex: 1 }}>
        <h3 style={{ ...Styles.flexRow, alignItems: 'center' }}>
          <BusinessIcon />
          Organizations
          <small>({totalCrmOrganizationsFound})</small>
        </h3>
        {crmOrganizationsFound.map(crmOrganization => (
          <CRMOrganizationCard
            key={crmOrganization.id}
            crmOrganization={crmOrganization}
            onClick={handleOnClick}
            ctaLabel={ctaLabel || 'Show'}
          />
        ))}
      </div>
    );
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        startIcon={<SearchIcon />}
        label={buttonLabel || 'Search'}
        style={style}
      />

      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth="md"
        fullWidth
        aria-labelledby="form-dialog-title"
        disableRestoreFocus
      >
        <DialogTitle style={Styles.flexRow} id="form-dialog-title">
          <div style={{ ...Styles.flexRow, flex: 1 }}>
            <div style={{ ...Styles.flexRow, width: 27 }}>
              {isSearching ? <LoadingAnimation /> : <SearchIcon />}
            </div>
            <input
              autoFocus
              style={InputStyle.input}
              type="text"
              placeholder="Type something..."
              onChange={e => {
                setIsSearching(true);
                setSearchQ(e.target.value);
              }}
              onKeyDown={onKeyDownHandler}
              value={searchQ}
            />
          </div>
        </DialogTitle>
        <DialogContent style={{ minHeight: 200 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 24,
            }}
          >
            {renderCRMPersons()}
            {renderCRMOrganizations()}
          </div>
          {renderNoResults()}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CRMSearchDialog;
