import React, { useState } from 'react';
import { Button, Form, required, useDataProvider, useRefresh } from 'react-admin';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import AddIcon from '@mui/icons-material/Add';

import { useAdminRoles } from '@hooks/useAdminRoles';
import CustomReferenceInput from '@components/inputs/custom_reference_input';

import Styles from '../styles';

type CRMLinkOrganizationPersonProps = {
  crmOrganizationId?: string;
  crmPersonId?: string;
};
const CRMLinkOrganizationPerson = ({
  crmOrganizationId,
  crmPersonId,
}: CRMLinkOrganizationPersonProps) => {
  const dataprovider = useDataProvider();
  const refresh = useRefresh();
  const isReadOnly = useAdminRoles('read_only');
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');
  const [searchedCRMOrganizationId, setSearchedCRMOrganizationId] = useState<
    string | undefined
  >(crmOrganizationId);
  const [searchedCRMPersonId, setSearchedCRMPersonId] = useState<string | undefined>(
    crmPersonId
  );

  const handleClickOpen = () => {
    setError('');
    setOpen(true);
  };
  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  const addButtonLabel = () => {
    if (crmOrganizationId) {
      return 'Add an employee';
    }
    if (crmPersonId) {
      return 'Link to an organization';
    }
    return '';
  };

  const createCRMOrganizationPerson = async (_tag: any) => {
    try {
      setError('');
      await dataprovider.create('crm_organizations_persons', {
        data: {
          crmOrganizationId: searchedCRMOrganizationId || crmOrganizationId,
          crmPersonId: searchedCRMPersonId || crmPersonId,
        },
      });
      refresh();
      handleClose();
    } catch (error: any) {
      setError(error?.error?.message || error?.message || 'Something went wrong.');
    }
  };

  if (isReadOnly) {
    return <></>;
  }
  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        startIcon={<AddIcon />}
        label={addButtonLabel()}
      />

      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth="sm"
        fullWidth
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle style={Styles.flexRow} id="form-dialog-title">
          {addButtonLabel()}
        </DialogTitle>
        <DialogContent style={{ minHeight: 100 }}>
          <Form>
            <CustomReferenceInput
              source="crmOrganizationId"
              defaultValue={crmOrganizationId}
              reference="crm_organizations"
              queryKey="name"
              validate={[required()]}
              disabled={!!crmOrganizationId}
              label="Organization"
              onChange={setSearchedCRMOrganizationId}
              sort={{ field: 'name', order: 'ASC' }}
            />
            <CustomReferenceInput
              source="crmPersonId"
              defaultValue={crmPersonId}
              reference="crm_persons"
              queryKey={['firstname', 'lastname', 'email']}
              validate={[required()]}
              disabled={!!crmPersonId}
              label="Person"
              onChange={setSearchedCRMPersonId}
              sort={{ field: 'firstname', order: 'ASC' }}
            />
          </Form>
          {error && <small style={{ color: 'red' }}>{error}</small>}
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" label="Cancel" />
            <Button
              onClick={createCRMOrganizationPerson}
              variant="contained"
              label="Save"
            />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CRMLinkOrganizationPerson;
