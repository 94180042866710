import React from 'react';
import { Create, SimpleForm } from 'react-admin';
import { useSearchParams } from 'react-router-dom';
import { AdminUserRole } from 'types/admin_user_role.d';

import CustomReferenceInput from '@components/inputs/custom_reference_input';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const redirectAfterCreateEdit = (basePath: any, id: any, data: any) =>
  `campaigns/${data.campaignId}/show/managers`;

const CreateActions = <ResourceTitleActionBar mode="create" />;

export const AdminUserCampaignCreate = () => {
  const [searchParams] = useSearchParams();

  const campaignId = searchParams.get('campaignId');

  return (
    <Create actions={CreateActions} redirect={redirectAfterCreateEdit}>
      <SimpleForm>
        <CustomReferenceInput
          source="campaignId"
          reference="campaigns"
          queryKey="name"
          validate
          defaultValue={campaignId}
          disabled={!!campaignId}
        />

        <CustomReferenceInput
          source="adminUserId"
          reference="admin_users"
          queryKey="username"
          filters={{ role: AdminUserRole.campaign_manager }}
          validate
        />
      </SimpleForm>
    </Create>
  );
};
