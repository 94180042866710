import * as React from 'react';
import {
  DateField,
  EditButton,
  FunctionField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import { MissionType } from 'types/mission';
import { CauseType } from 'types/cause';
import { CampaignType } from 'types/campaign';

import { useAdminRoles } from '@hooks/useAdminRoles';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import { SpacingStyle } from '@styles/variables';

import MissionCard from '../missions/components/card';
import CampaignBlock from '../campaigns/components/campaign_block';

const ShowActions = () => {
  const canEdit = useAdminRoles('super_admin');
  return (
    <ResourceTitleActionBar
      mode="show"
      actions={canEdit ? <EditButton variant="outlined" /> : undefined}
    />
  );
};

export const CauseShow = () => (
  <Show actions={<ShowActions />}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <TextField source="name" />
        <TextField source="emoji" />
        <TextField source="descriptionFR" />
        <TextField source="descriptionEN" />
        <DateField
          source="createdAt"
          showTime
          label={`Created at (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
        />
        <DateField
          source="updatedAt"
          showTime
          label={`Updated at (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
        />
      </Tab>
      <Tab label="Campaigns" path="campaigns">
        <FunctionField
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: SpacingStyle.big,
          }}
          render={(cause: CauseType) =>
            cause.campaigns
              ?.sort((a, b) => (a.name < b.name ? -1 : 1))
              ?.map((campaign: CampaignType) => (
                <CampaignBlock key={campaign.id} campaign={campaign} clickable />
              ))
          }
        />
      </Tab>
      <Tab label="Missions" path="missions">
        <FunctionField
          style={{ display: 'flex', flexWrap: 'wrap', gap: SpacingStyle.big }}
          render={(cause: CauseType) =>
            cause.missions
              ?.sort((a, b) => (a.titleEN < b.titleEN ? -1 : 1))
              .map((mission: MissionType) => (
                <MissionCard key={mission.id} mission={mission} isClickable />
              ))
          }
        />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
