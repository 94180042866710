import * as React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

import CustomReferenceInput from '@components/inputs/custom_reference_input';
import Grammarly from '@components/grammarly';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const redirectAfterCreateEdit = (basePath: any, id: any, data: any) =>
  `campaigns/${data.campaignId}/show/qas`;

const CreateActions = <ResourceTitleActionBar mode="create" title="Campaign QAs" />;

export const CampaignQACreate = () => (
  <Create redirect={redirectAfterCreateEdit} actions={CreateActions}>
    <SimpleForm>
      <CustomReferenceInput source="campaignId" reference="campaigns" queryKey="name" />
      <Grammarly>
        <TextInput label="English question" source="questionEN" fullWidth required />
      </Grammarly>
      <Grammarly>
        <TextInput
          label="English answer"
          source="answerEN"
          fullWidth
          required
          multiline
        />
      </Grammarly>
      <TextInput label="French question" source="questionFR" fullWidth required />
      <TextInput label="French answer" source="answerFR" fullWidth required multiline />
    </SimpleForm>
  </Create>
);
