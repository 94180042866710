import React from 'react';
import { useRecordContext } from 'react-admin';
import { Card, CardContent, CardHeader } from '@mui/material';
import { MissionType } from 'types/mission';

import { ActivistIcon } from '@components/icons';
import TodoChecklist from '@components/todo_checklist';
import { UpdateFieldDialog } from '@components/update_field_dialog';
import CampaignBlock from '@models/campaigns/components/campaign_block';
import UserChip from '@models/users/components/user_chip';

const ProfilesCard = () => {
  const mission: MissionType = useRecordContext();

  return (
    <Card>
      <CardHeader title="Profiles" avatar={<ActivistIcon />} />
      <CardContent>
        <TodoChecklist
          checked={!!mission?.ownerId}
          label="Assign an owner"
          cta={
            !mission.outcome && (
              <UpdateFieldDialog
                type="autocomplete"
                resource="missions"
                record={mission}
                autocompleteReference="users"
                autocompleteQueryKey={['username']}
                field="ownerId"
                buttonLabel={mission?.ownerId ? 'Edit' : 'Add'}
                buttonVariant="outlined"
              />
            )
          }
        >
          {mission?.owner && <UserChip user={mission?.owner} redirectToUserPageOnClick />}
        </TodoChecklist>
        <br />
        <TodoChecklist checked={!!mission?.campaignId} label="Link a Campaign">
          <div style={{ display: 'flex', width: '100%' }}>
            {mission?.campaign && (
              <CampaignBlock campaign={mission?.campaign} clickable withStatus />
            )}
          </div>
        </TodoChecklist>
      </CardContent>
    </Card>
  );
};

export default ProfilesCard;
