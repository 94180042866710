import React from 'react';
import { Button, useRecordContext } from 'react-admin';
import { useAdminRoles } from 'src/hooks/useAdminRoles';
import { TopicType } from 'types/topic';

import PepperAvatar from '@components/pepper_avatar';

const PostAsPepperButton = () => {
  const canPost = useAdminRoles(['super_admin', 'campaign_admin']);
  const record: TopicType = useRecordContext();
  const isRecordGeneral = record.nameEN === 'general';

  if (!canPost || !isRecordGeneral) {
    return <></>;
  }

  return (
    <Button
      variant="outlined"
      label="Post as Pepper"
      startIcon={<PepperAvatar />}
      href={`#/topic_comments/pepper_pill?source={"topicId":"${record.id}"}`}
    />
  );
};

export default PostAsPepperButton;
