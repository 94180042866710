import * as React from 'react';
import {
  Create,
  DateTimeInput,
  Labeled,
  ReferenceField,
  SimpleForm,
  TextInput,
} from 'react-admin';

import { useAdminRoles } from '@hooks/useAdminRoles';
import Callout from '@components/callout';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const CreateActions = <ResourceTitleActionBar mode="create" />;

export const TopicCommentCreate = () => {
  const maxChar = useAdminRoles('super_admin') ? 10_000 : 1_000;
  return (
    <Create actions={CreateActions}>
      <SimpleForm>
        <Labeled label="Topic">
          <ReferenceField source="topicId" reference="topics" />
        </Labeled>

        <Labeled label="Author">
          <ReferenceField source="authorId" reference="users" />
        </Labeled>
        <Callout emoji="📝">
          <ul>
            <li>
              You can use <strong>**strong text**</strong> or <i>_italic_</i> styles
            </li>
            <li>Max {maxChar} characters.</li>
            <li>
              Links must start with <strong>https://</strong> to be previewed
            </li>
          </ul>
        </Callout>
        <TextInput
          source="message"
          fullWidth
          multiline
          inputProps={{ maxLength: maxChar }}
        />
        <DateTimeInput source="createdAt" defaultValue={new Date()} />
      </SimpleForm>
    </Create>
  );
};
