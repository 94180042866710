import * as React from 'react';
import {
  Create,
  ImageField,
  ImageInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import CustomReferenceInput from '@components/inputs/custom_reference_input';

const CreateActions = () => <ResourceTitleActionBar mode="create" />;

const redirectAfterCreate = (basePath: any, id: any, data: any) =>
  `missions/${data.id}/show`;

export const MissionCreate = ({ ...props }) => (
  <Create actions={<CreateActions />} redirect={redirectAfterCreate} {...props}>
    <SimpleForm>
      <CustomReferenceInput
        source="campaignId"
        reference="campaigns"
        sort={{ field: 'name', order: 'ASC' }}
      />
      <CustomReferenceInput
        source="ownerId"
        reference="users"
        validate={[required()]}
        sort={{ field: 'firstname', order: 'ASC' }}
      />
      <ImageInput
        source="picture"
        accept="image/*"
        maxSize={5000000}
        validate={[required()]}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput
        label="English title"
        source="titleEN"
        fullWidth
        validate={[required()]}
        placeholder="255 char max"
        inputProps={{ maxLength: 255 }}
      />
      <TextInput
        label="English description"
        source="descriptionEN"
        fullWidth
        multiline
        validate={[required()]}
      />
      <TextInput
        label="French title"
        source="titleFR"
        fullWidth
        validate={[required()]}
        placeholder="255 char max"
        inputProps={{ maxLength: 255 }}
      />
      <TextInput
        label="French description"
        source="descriptionFR"
        fullWidth
        multiline
        validate={[required()]}
      />
    </SimpleForm>
  </Create>
);
