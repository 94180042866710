import * as React from 'react';
import {
  CreateButton,
  Datagrid,
  DateField,
  Filter,
  ImageField,
  List,
  ReferenceField,
  SearchInput,
  TextField,
} from 'react-admin';

import { useAdminRoles } from '@hooks/useAdminRoles';
import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const ListActions = () => {
  const canCreate = useAdminRoles(['super_admin', 'campaign_admin', 'campaign_manager']);
  return (
    <ResourceTitleActionBar
      mode="list"
      actions={
        <>
          <Filter>
            <SearchInput source="shortCopyEn" alwaysOn />
          </Filter>
          {canCreate && <CreateButton variant="outlined" />}
        </>
      }
    />
  );
};

export const WinList = () => (
  <List
    sort={{ field: 'createdAt', order: 'ASC' }}
    perPage={PER_PAGE}
    pagination={<PER_PAGE_OPTIONS />}
    actions={<ListActions />}
    exporter={false}
    emptyWhileLoading
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <DateField source="createdAt" />
      <ReferenceField
        source="campaignId"
        reference="campaigns"
        link="show"
        label="Campaign"
      >
        <TextField source="name" label="Campaign Name" />
      </ReferenceField>
      <ImageField source="imageUrl" sx={{ '& img': { maxWidth: 100 } }} />
      <TextField source="shortCopyEn" />
    </Datagrid>
  </List>
);
