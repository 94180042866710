import * as React from 'react';
import {
  Edit,
  ImageField,
  ImageInput,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';
import { ColorInput } from 'react-admin-color-picker';
import ResourceTitleActionBar from 'src/components/resource_title_action_bar';

const redirectAfterCreateEdit = (basePath: any, id: any, data: any) =>
  `badges/${data.id}/show`;

const EditActions = <ResourceTitleActionBar mode="edit" title="Badges" />;

export const BadgeEdit = () => (
  <Edit
    redirect={redirectAfterCreateEdit}
    mutationMode="pessimistic"
    actions={EditActions}
  >
    <SimpleForm>
      <SelectInput
        source="type"
        choices={[
          { id: 'ACTION', name: 'Action' },
          { id: 'CAMPAIGN', name: 'Campaign' },
          { id: 'CUSTOM', name: 'Custom' },
          { id: 'FRIENDSHIP', name: 'Friendship' },
          { id: 'MISSION', name: 'Mission' },
          { id: 'STREAK', name: 'Streak' },
        ]}
        fullWidth
        validate={[required()]}
      />
      <NumberInput
        label="Badge Score"
        source="score"
        fullWidth
        helperText="The score required to unlock this badge. Leave empty if badge is not score-based."
      />
      <TextInput label="Name 🇺🇸" source="nameEN" validate={[required()]} fullWidth />
      <TextInput
        label="Description (displayed before user won it) 🇺🇸"
        source="descriptionEN"
        validate={[required()]}
        fullWidth
      />
      <TextInput
        label="Message (displayed after user won it) 🇺🇸"
        source="congratulationMessageEN"
        fullWidth
      />
      <TextInput label="Name 🇫🇷" source="nameFR" validate={[required()]} fullWidth />
      <TextInput
        label="Description (affiché avant que l'user le gagne) 🇫🇷"
        source="descriptionFR"
        validate={[required()]}
        fullWidth
      />
      <TextInput
        label="Message (affiché quand l'user l'a gagné) 🇫🇷"
        source="congratulationMessageFR"
        fullWidth
      />
      <ColorInput label="Color 1" source="color1" />
      <ColorInput label="Color 2" source="color2" />
      <ColorInput label="Color 3" source="color3" />
      <ImageField source="imageUrl" title="Image" />
      <ImageInput source="picture" accept="image/*" maxSize={5000000}>
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);
