import * as React from 'react';
import {
  Button,
  Loading,
  RecordContextProvider,
  useGetOne,
  useRecordContext,
  useRedirect,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import IosShareIcon from '@mui/icons-material/IosShare';
import { MissionType } from 'types/mission';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import { UpdateFieldDialog } from '@components/update_field_dialog';
import { CampaignIcon, MissionIcon } from '@components/icons';
import { SpacingStyle } from '@styles/variables';

import ProfilesCard from './components/show/profiles_card';
import PublishCard from './components/show/publish_card';
import StoriesCard from './components/show/stories_card';
import TextsCard from './components/show/texts_card';
import ThumbnailCard from './components/show/thumbnail_card';
import ActionsCard from './components/show/actions_card';
import CausesCard from './components/show/causes_card';
import MissionComments from './components/list/mission_comments';
import OutcomeCard from './components/show/outcome_card';
import LocationCard from './components/show/location_card';

const ShowActions = () => {
  const mission: MissionType = useRecordContext();
  const backLabel = mission?.campaignId ? 'Back to campaign' : null;
  const backTo = mission?.campaignId ? `#/campaigns/${mission?.campaignId}/show` : '#';
  return (
    <ResourceTitleActionBar
      mode="show"
      title={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: SpacingStyle[4],
          }}
        >
          <UpdateFieldDialog
            resource="missions"
            record={mission}
            field="name"
            hint="Give your new mission a name. It will only be displayed in the command center."
            buttonIcon={<MissionIcon />}
          />
          {!mission?.publishedAt && ' (draft)'}
        </div>
      }
      actions={
        <>
          <Button
            href={mission.publicUrl}
            label="Public link"
            variant="outlined"
            target="_blank"
            startIcon={<IosShareIcon />}
          />
          {backLabel && (
            <Button
              href={backTo}
              label={backLabel}
              variant="outlined"
              startIcon={<CampaignIcon />}
            />
          )}
        </>
      }
    />
  );
};

export const MissionShow = () => {
  const { id } = useParams();
  const redirect = useRedirect();
  const { data, isLoading } = useGetOne(
    'missions',
    { id },
    { onError: () => redirect('/missions') }
  );

  if (isLoading) {
    return <Loading />;
  }
  return (
    <RecordContextProvider value={data}>
      <ShowActions />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ThumbnailCard />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxWidth: 1200,
            margin: '-70px auto 220px',
            zIndex: 1,
          }}
        >
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
            }}
          >
            <CausesCard />
            <div style={{ gridColumn: 'span 2' }}>
              <StoriesCard />
            </div>
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
            }}
          >
            <TextsCard lang="EN" />
            <TextsCard lang="FR" />
          </div>
          <LocationCard />
          <ActionsCard />
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
            }}
          >
            <ProfilesCard />
            <PublishCard />
          </div>
          <OutcomeCard />
        </div>
      </div>
      <MissionComments />
    </RecordContextProvider>
  );
};
