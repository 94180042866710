import * as React from 'react';
import { Edit, SimpleForm } from 'react-admin';
import CustomReferenceInput from '../../components/inputs/custom_reference_input';

const redirectAfterEdit = (basePath: any, id: any, data: any) =>
  `campaigns/${data.campaignId}/show/stories`;

export const CampaignStoryEdit = () => (
  <Edit redirect={redirectAfterEdit} mutationMode="pessimistic">
    <SimpleForm>
      <CustomReferenceInput source="campaignId" reference="campaigns" disabled />
      <CustomReferenceInput source="videoId" reference="videos" queryKey="name" />
    </SimpleForm>
  </Edit>
);
