import React, { useEffect, useState } from 'react';
import {
  Button,
  FileField,
  FileInput,
  Form,
  SelectInput,
  TextInput,
  required,
  useDataProvider,
  useRedirect,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { Card, CardContent } from '@mui/material';
import BackupIcon from '@mui/icons-material/Backup';
import axios from 'axios';
import { VideoType } from 'types/video';
import { ActionType } from 'types/action';

import { Colors } from '@styles/variables';

import Callout from '../callout';
import LoadingAnimation from '../svgs/loading_animation';
import ResourceTitleActionBar from '../resource_title_action_bar';
import Favicon from '../svgs/logos/favicon';

const videoLocales = [
  {
    id: 'en',
    name: 'English',
  },
  {
    id: 'fr',
    name: 'French',
  },
  {
    id: 'es',
    name: 'Spanish',
  },
  // { id: 'de', name: 'German' },
  // { id: 'it', name: 'Italian' },
  // { id: 'pt', name: 'Portuguese' },
  // { id: 'ru', name: 'Russian' },
  // { id: 'ja', name: 'Japanese' },
  // { id: 'ar', name: 'Arabic' },
];

const FOLDER_NAME = 'actions_tmp_uploads';
const DEFAULT_OPENAI_PROMPT =
  'Speaker might talk about:\nchilli, chilli, EACOP, Glencore, TEEPSA, Willow, Rosebank';

export const ActionVideoStudio = () => {
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const params = useParams();
  const [dragEntered, setDragEntered] = useState(false);
  const [errors, setErrors] = useState([]);
  const [uploadStatus, setUploadStatus] = useState('Getting ready...');
  const [videoLocale, setVideoLocale] = useState(videoLocales[0].id);
  const [action, setAction] = useState<ActionType>();
  const [openAIPrompt, setOpenAIPrompt] = useState(DEFAULT_OPENAI_PROMPT);

  const { actionId } = params;

  useEffect(() => {
    fetchAction();
  }, [actionId]);

  const fetchAction = async () => {
    if (!actionId) {
      return;
    }
    const { data: action } = await dataProvider.getOne('actions', {
      id: actionId,
    });
    setAction(action);
  };

  /**
   * @param file
   * @returns presigned URL from API
   */
  const getS3PresignedURL = async (file: File) => {
    setUploadStatus('[1/4] Signing video...');
    const { data: presignedURL } = await dataProvider.getS3PresignedURL({
      filekey: `${FOLDER_NAME}/${actionId}-${file.name}`,
    });
    return presignedURL;
  };

  /**
   * Upload file to S3
   * @param file
   * @param presignedURL
   */
  const uploadFileToS3 = async (file: File, presignedURL: string) => {
    setUploadStatus('[2/4] Uploading video...');
    await axios.put(presignedURL, file);
  };

  /**
   * Upload video to API
   */
  const uploadVideo = async (file: File) => {
    if (!actionId) {
      setUploadStatus('Error: no actionId');
      return;
    }
    setUploadStatus('[3/4] Compressing video...');
    const s3URL = `https://chilli-production.s3.eu-west-2.amazonaws.com/${FOLDER_NAME}/${actionId}-${file.name}`;
    await dataProvider.uploadActionVideo({
      actionId,
      s3URL,
    });

    let video = null;
    const oldVideoActionId = action?.videoAction?.id;
    // Wait for video to be processed during ~7 minutes max
    for (let i = 0; i < 3 * 60; i++) {
      console.log('Waiting for video to be processed...', i);
      const { data: action } = await dataProvider.getOne('actions', {
        id: actionId,
      });
      if (!!action?.videoActionId && action?.videoActionId !== oldVideoActionId) {
        video = action.videoAction;
        break;
      }
      await new Promise(resolve => setTimeout(resolve, 2500));
    }

    if (!video) {
      setUploadStatus('Error uploading video, refresh page and try again');
      return;
    }

    return video;
  };

  /**
   * Generate subtitles for video
   * @param video
   */
  const generateVideoSubtitles = async (video: VideoType) => {
    setUploadStatus('[4/4] Generating and translating captions...');
    let subtitles = null;
    await dataProvider.generateVideoSubtitles({
      videoId: video.id,
      prompt: openAIPrompt,
      language: videoLocale,
    });
    for (let i = 0; i < 3 * 60; i++) {
      const { data } = await dataProvider.getOne('videos', {
        id: video.id,
      });
      console.log(
        `Waiting for subtitles to be generated... [${data.subtitles.length}/${
          Object.keys(videoLocales).length
        }]`,
        i
      );
      if (data.subtitles.length >= Object.keys(videoLocales).length) {
        break;
      }
      await new Promise(resolve => setTimeout(resolve, 2500));
    }
    if (!subtitles) {
      setUploadStatus('Error generating subtitles');
      return;
    }
  };

  const { mutate, isLoading: isUploading } = useMutation(
    ['uploadActionVideo', actionId],
    async ({ file }: any) => {
      try {
        // 1. Get presigned URL from API
        const presignedURL = await getS3PresignedURL(file);

        // 2. Upload file to S3
        await uploadFileToS3(file, presignedURL);

        // 3. Upload video to chilli API
        const video = await uploadVideo(file);
        if (!video) {
          setUploadStatus('Error: video not processed');
          return;
        }

        // 4. Generate and translate subtitles
        await generateVideoSubtitles(video);
        setUploadStatus('Done! Redirecting...');
        redirect('show', 'videos', video.id);
      } catch (error: any) {
        console.error(error);
        setUploadStatus('Error uploading video:' + error.message);
      }
    }
  );

  const onSubmit = async (data: any) => {
    if (!data.video) {
      window.alert('Please select a video file to upload');
      return;
    }
    await mutate({
      actionVideoType: data.actionVideoType,
      file: data.video.rawFile,
    });
  };

  if (isUploading) {
    return (
      <Card>
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '50vh',
          }}
        >
          <LoadingAnimation stroke={Colors.Magenta.primary} />
          <br />
          <strong>{uploadStatus}</strong>
          <small>This might take a few minutes, don't close this page!</small>
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      <ResourceTitleActionBar
        title={`Upload a video to ${action?.name || 'action'}`}
        mode="show"
        actions={
          <Button
            href={`#/actions/${actionId}/show`}
            startIcon={<Favicon width={15} height={12} />}
            label="Back to Action"
            variant="outlined"
          />
        }
      />
      <Card>
        <CardContent>
          <Form onSubmit={onSubmit}>
            <Callout emoji="🤓">
              <strong>Compress</strong> your video before uploading it:
              <ul>
                <li>
                  <strong>Max length recommended:</strong> 1min
                </li>
                <li>
                  <strong>Resolution:</strong> 720P
                </li>
                <li>
                  <strong>Codec:</strong> HEVC
                </li>
              </ul>
            </Callout>
            <FileInput
              source="video"
              accept="video/*"
              validate={[required()]}
              maxSize={60000000}
              placeholder={
                <div>
                  <p>Drop a file to upload, or click to select it</p>
                  <small>
                    Maximum file size: <strong>60 MB</strong>
                  </small>
                </div>
              }
              options={{
                onDragEnter: () => setDragEntered(true),
                onDragLeave: () => setDragEntered(false),
                onDrop: () => setDragEntered(false),
                onDropRejected: (e: any) => {
                  setErrors(e[0].errors);
                },
              }}
              style={
                {
                  ...(dragEntered ? { boxShadow: '0 0 0 1px #000' } : null),
                } as any
              }
            >
              <FileField source="src" title="title" />
            </FileInput>
            <div>
              {errors.map((error: any) => (
                <small key={error.code} style={{ display: 'block', color: 'red' }}>
                  <strong>{error.code}:</strong> {error.message}
                </small>
              ))}
            </div>
            <SelectInput
              label="Video original language"
              source="videoLanguage"
              choices={videoLocales}
              defaultValue={videoLocale}
              onChange={e => setVideoLocale(e.target.value)}
              validate={[required()]}
              fullWidth
            />
            <TextInput
              label="🤖 Give GPT all topics mentionned in the video to help auto captions"
              source="openAIPrompt"
              defaultValue={DEFAULT_OPENAI_PROMPT}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setOpenAIPrompt(e.target.value)
              }
              multiline
              fullWidth
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="submit"
                label="Start uploading"
                startIcon={<BackupIcon />}
                style={{
                  backgroundColor: '#000',
                  color: 'white',
                  padding: '10px 20px',
                }}
              />
            </div>
          </Form>
        </CardContent>
      </Card>
    </>
  );
};
