import * as React from 'react';
import { Create } from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import Callout from '@components/callout';

const CreateActions = () => <ResourceTitleActionBar mode="create" />;

export const MissionStoryCreate = () => (
  <Create actions={<CreateActions />}>
    <Callout>
      To create a Mission Story, go in the <strong>STORIES</strong> tab of a{' '}
      <a href="#/missions">Mission's</a> page.
    </Callout>
  </Create>
);
