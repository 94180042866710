import React, { useCallback, useMemo, useState } from 'react';
import { useDataProvider, useRecordContext } from 'react-admin';
import { Card, CardContent, CardHeader } from '@mui/material';
import { ActionType } from 'types/action';
import { MissionType } from 'types/mission';
import moment from 'moment-timezone';

import { ActionIcon } from '@components/icons';
import ActionCTATypeChip from '@components/action_cta_type_chip';
import Calendar, { CalendarItems } from '@components/calendar';
import Callout from '@components/callout';
import ActionCard from '@components/calendar/components/action_card';
import { ActionCreateDialog } from '@models/actions/components/create_dialog';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

const ActionsCard = () => {
  const dataProvider = useDataProvider();
  const mission: MissionType = useRecordContext();
  const [hoveredActionId, setHoveredActionId] = useState<string | null>(null);

  const draftActions = useMemo(() => {
    return mission.actions?.filter(action => !action.publishedAt);
  }, [mission.actions]);

  const hasPublishedActions = useMemo(() => {
    return mission.actions?.some(action => action.publishedAt);
  }, [mission.actions]);

  /**
   * Render a draft action
   * @param action
   * @returns {JSX.Element}
   */
  const renderDraftAction = useCallback(
    (action: ActionType) => (
      <a
        key={action.id}
        style={{
          ...styles.actionBlock,
          ...(action.id === hoveredActionId ? styles.actionBlockHovered : {}),
        }}
        href={`#/actions/${action.id}/show`}
        onMouseEnter={() => setHoveredActionId(action.id)}
        onMouseLeave={() => setHoveredActionId(null)}
      >
        {action.name || action.descriptionEN || action.descriptionFR}
        {action.ctaType && <ActionCTATypeChip ctaType={action.ctaType} size="small" />}
      </a>
    ),
    [hoveredActionId]
  );

  /**
   * Fetch calendar items
   * @param daysDisplayed
   * @returns {Promise<CalendarItems>}
   */
  const fetchCalendarItems = useCallback(
    async (daysDisplayed?: string[]) => {
      if (!mission?.id || !daysDisplayed) {
        return {};
      }
      const { data } = await dataProvider.getList('actions', {
        pagination: { page: 1, perPage: 500 },
        sort: { field: 'publishedAt', order: 'ASC' },
        filter: {
          missionId: mission.id,
          publishedAt: daysDisplayed,
        },
      });

      const result: CalendarItems = {};
      data.forEach((action: ActionType) => {
        const day = moment(action.publishedAt).format('YYYY-MM-DD');
        if (!day) {
          return;
        }
        if (!result[day]) {
          result[day] = [];
        }
        result[day].push(<ActionCard key={action.id} action={action} />);
      });
      return result;
    },
    [dataProvider, mission.id]
  );

  return (
    <Card>
      <CardHeader
        avatar={<ActionIcon />}
        title="Actions"
        action={
          !mission.outcome && (
            <ActionCreateDialog
              campaignId={mission.campaignId}
              missionId={mission.id}
              primaryOwnerId={mission.ownerId}
            />
          )
        }
      />
      <CardContent>
        <>
          {(draftActions?.length || 0) > 0 && (
            <div style={styles.draftActions}>
              <div style={styles.actionsHeader}>Unpublished (drafts)</div>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: SpacingStyle.normal,
                }}
              >
                {draftActions?.map(renderDraftAction)}
              </div>
            </div>
          )}
          {hasPublishedActions && !mission.publishedAt && (
            <Callout
              emoji="👋"
              backgroundColor={Colors.Magenta[400]}
              style={{ maxWidth: '100%' }}
            >
              Some <strong>actions</strong> are published, but the{' '}
              <strong>mission</strong> is still not published!
              <br />
              Actions won't be visible to users until the mission is published.
            </Callout>
          )}
          <Calendar fetchItems={fetchCalendarItems} />
        </>
      </CardContent>
    </Card>
  );
};

const styles: any = {
  createAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${SpacingStyle[4]}px ${SpacingStyle[8]}px`,
    cursor: 'pointer',
    fontSize: FontStyle.sizeVerySmall,
    fontWeight: '600',
    border: `1px solid ${Colors.Black.primary}`,
    borderRadius: BorderStyle.Radius.huge,
  },
  draftActions: {
    marginBottom: SpacingStyle.normal,
    padding: SpacingStyle.normal,
    backgroundColor: Colors.Magenta[50],
    borderRadius: BorderStyle.Radius.normal,
  },
  actionsHeader: {
    marginBottom: SpacingStyle.normal,
    color: Colors.OffBlack.primary,
    fontSize: FontStyle.sizeMedium,
    fontWeight: '600',
  },
  actionBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: SpacingStyle.small,
    padding: SpacingStyle.small,
    backgroundColor: Colors.White.primary,
    borderRadius: BorderStyle.Radius.small,
    width: 200,
    fontSize: FontStyle.sizeVerySmall,
    lineHeight: 1.2,
    color: Colors.OffBlack.primary,
    textDecoration: 'none',
  },
  actionBlockHovered: {
    boxShadow: `0 0 0 1px ${Colors.Magenta[200]}`,
  },
  publishedAt: {
    display: 'flex',
    alignItems: 'center',
    gap: SpacingStyle[4],
    fontSize: FontStyle.sizeVerySmall,
    fontWeight: '400',
    color: Colors.Grey[400],
  },
};

export default ActionsCard;
