import { usePermissions } from 'react-admin';

import { AdminUserRoles } from '@models/admin_users/components/roles';

export const useAdminRoles = (permissionNames?: string | string[]) => {
  const { permissions } = usePermissions();

  if (!permissionNames) {
    return true;
  }

  let hasPermission = false;
  if (typeof permissions === 'undefined' || permissions === null) {
    return false;
  }
  if (Array.isArray(permissionNames)) {
    permissionNames.forEach(permissionName => {
      const permissionIndex = AdminUserRoles.indexOf(permissionName);
      if (permissions?.includes(permissionIndex) || permissions == permissionIndex) {
        hasPermission = true;
      }
    });
  } else {
    const permissionIndex = AdminUserRoles.indexOf(permissionNames);
    if (permissionIndex == permissions) {
      hasPermission = true;
    }
  }
  return hasPermission;
};

export const isSuperAdmin = (permissions: number) =>
  permissions == AdminUserRoles.indexOf('super_admin');

export const isCampaignAdmin = (permissions: number) =>
  permissions == AdminUserRoles.indexOf('campaign_admin');

export const isCampaignManager = (permissions: number) =>
  permissions == AdminUserRoles.indexOf('campaign_manager');

export const isProduct = (permissions: number) =>
  permissions == AdminUserRoles.indexOf('product');

export const isReadOnly = (permissions: number) =>
  permissions == AdminUserRoles.indexOf('read_only');
