import React from 'react';
import { useRecordContext } from 'react-admin';
import { Card, CardContent, CardHeader } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import { ActionCTAType, ActionType } from 'types/action.d';

import { useAdminRoles } from '@hooks/useAdminRoles';
import { UpdateFieldDialog } from '@components/update_field_dialog';
import TodoChecklist from '@components/todo_checklist';
import Callout from '@components/callout';

const DescriptionsCard = () => {
  const action: ActionType = useRecordContext();
  const isReadOnly = useAdminRoles('read_only');
  const canEdit: boolean = !isReadOnly && !action?.mission?.outcome;

  if (!action) {
    return <></>;
  }

  return (
    <Card>
      <CardHeader title="In-app descriptions" avatar={<DescriptionIcon />} />
      <CardContent>
        {action?.ctaType === ActionCTAType.QUESTION && (
          <Callout emoji="📝" backgroundColor="grey">
            To update those descriptions, edit the question itself.
          </Callout>
        )}
        {/* Description EN */}
        <TodoChecklist
          checked={!!action?.descriptionEN}
          label="Set an English description"
          cta={
            canEdit &&
            !action.actionCtaQuestion && (
              <UpdateFieldDialog
                resource="actions"
                record={action}
                type="textarea"
                field="descriptionEN"
                buttonLabel="Update"
                buttonVariant="outlined"
                maxLength={200}
              />
            )
          }
        >
          {action.descriptionEN && <small>{action.descriptionEN}</small>}
        </TodoChecklist>

        {/* Description FR */}
        <TodoChecklist
          checked={!!action?.descriptionFR}
          label="Set a French description"
          cta={
            canEdit &&
            !action.actionCtaQuestion && (
              <UpdateFieldDialog
                resource="actions"
                record={action}
                type="textarea"
                formatter={value => {
                  if (!value) {
                    return '';
                  }
                  return value.replaceAll('\n', ' ');
                }}
                field="descriptionFR"
                buttonLabel="Update"
                buttonVariant="outlined"
                maxLength={200}
              />
            )
          }
        >
          {action.descriptionFR && <small>{action.descriptionFR}</small>}
        </TodoChecklist>
      </CardContent>
    </Card>
  );
};

export default DescriptionsCard;
