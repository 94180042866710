import * as React from 'react';
import {
  CreateButton,
  Datagrid,
  Filter,
  List,
  SearchInput,
  TextField,
} from 'react-admin';
import { PER_PAGE, PER_PAGE_OPTIONS } from 'src/components/list';
import ResourceTitleActionBar from 'src/components/resource_title_action_bar';

const ListActions = (
  <ResourceTitleActionBar
    mode="list"
    actions={
      <>
        <Filter>
          <SearchInput source="name" alwaysOn />
        </Filter>
        <CreateButton variant="outlined" />
      </>
    }
  />
);

export const CauseList = () => (
  <List
    exporter={false}
    sort={{ field: 'name', order: 'ASC' }}
    perPage={PER_PAGE}
    pagination={<PER_PAGE_OPTIONS />}
    actions={ListActions}
    emptyWhileLoading
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="emoji" />
      <TextField source="name" />
      <TextField source="descriptionEN" label="Description EN" />
    </Datagrid>
  </List>
);
