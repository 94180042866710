import * as React from 'react';
import {
  DateField,
  EditButton,
  FunctionField,
  ReferenceField,
  Show,
  SimpleShowLayout,
} from 'react-admin';
import ReactMarkdown from 'react-markdown';

import ResourceTitleActionBar from '@components/resource_title_action_bar';

const ShowActions = () => {
  return (
    <ResourceTitleActionBar
      mode="show"
      actions={<EditButton label="Edit" variant="outlined" />}
    />
  );
};

export const TopicCommentShow = () => (
  <Show actions={<ShowActions />}>
    <SimpleShowLayout>
      <ReferenceField source="topicId" reference="topics" link="show" label="Topic" />
      <DateField
        source="createdAt"
        showTime
        label={`Posted at (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
      />

      <ReferenceField source="parentCommentId" reference="topic_comments" link="show" />

      <ReferenceField source="authorId" reference="users" link="show" />
      <FunctionField
        label="Message"
        render={(record: any) => <ReactMarkdown>{record.message}</ReactMarkdown>}
      />
      <FunctionField
        label="Silent Message EN"
        render={(record: any) => <ReactMarkdown>{record.silentMessageEN}</ReactMarkdown>}
      />
      <FunctionField
        label="Silent Message FR"
        render={(record: any) => <ReactMarkdown>{record.silentMessageFR}</ReactMarkdown>}
      />
    </SimpleShowLayout>
  </Show>
);
