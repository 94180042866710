/**
 * [VideoScript] List view
 */
import * as React from 'react';
import { Datagrid, DateField, FunctionField, List } from 'react-admin';
import { VideoScriptType } from 'types/video_script';

import ellipsis from '@services/ellipsis';
import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const ListActions = <ResourceTitleActionBar mode="list" />;

export const VideoScriptList = () => (
  <List
    sort={{ field: 'createdAt', order: 'DESC' }}
    exporter={false}
    perPage={PER_PAGE}
    pagination={<PER_PAGE_OPTIONS />}
    actions={ListActions}
    emptyWhileLoading
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <DateField source="createdAt" />
      <FunctionField
        label="script"
        component="pre"
        render={(record: VideoScriptType) => ellipsis(record.script, 100)}
      />
    </Datagrid>
  </List>
);
