import * as React from 'react';
import { Create } from 'react-admin';
import Callout from '../../components/callout';

export const CampaignStoryCreate = () => (
  <Create>
    <Callout>
      To create a Campaign Story, go in the <strong>STORIES</strong> tab of a{' '}
      <a href="#/campaigns">Campaign's</a> page.
    </Callout>
  </Create>
);
