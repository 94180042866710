import React from 'react';
import Callout from '../../../components/callout';

export const WinInstructions = () => (
  <>
    <Callout emoji="ℹ️">
      Long copies can be written with new lines, and using Markdown syntax:
      <br />- <strong>Bold:</strong> <strong>**bold text**</strong>
      <br />- <strong>Italic:</strong> <i>*italic text*</i>
      <br />- <strong>External link:</strong>{' '}
      <span style={{ color: '#31e' }}>[your text here](https://example.com)</span>
    </Callout>
  </>
);
