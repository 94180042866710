import * as React from 'react';
import { SimpleForm, Edit, required } from 'react-admin';

import CustomReferenceInput from '@components/inputs/custom_reference_input';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const MissionEditActions = () => <ResourceTitleActionBar mode="edit" />;

const redirectAfterEdit = (_basePath: any, _id: any, data: any) =>
  `missions/${data.missionId}/show`;

export const MissionStoryEdit = () => (
  <Edit
    redirect={redirectAfterEdit}
    actions={<MissionEditActions />}
    mutationMode="pessimistic"
  >
    <SimpleForm>
      <CustomReferenceInput
        source="missionId"
        reference="missions"
        sort={{ field: 'titleEN', order: 'ASC' }}
      />
      <CustomReferenceInput
        source="videoId"
        reference="videos"
        validate={[required()]}
        sort={{ field: 'name', order: 'ASC' }}
      />
    </SimpleForm>
  </Edit>
);
