/**
 * [VideoScript] Edit view
 */
import * as React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';

import Callout from '@components/callout';
import CustomReferenceInput from '@components/inputs/custom_reference_input';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const redirectAfterEdit = (basePath: any, id: any, data: any) => {
  if (data?.action?.id) {
    return `actions/${data.action.id}/show/video`;
  }
  return `video_scripts/${data.id}/show`;
};

const EditActions = <ResourceTitleActionBar mode="edit" />;

export const VideoScriptEdit = () => (
  <Edit redirect={redirectAfterEdit} mutationMode="pessimistic" actions={EditActions}>
    <SimpleForm>
      <CustomReferenceInput
        source="actionId"
        reference="actions"
        queryKey="descriptionEN"
        disabled
      />
      <Callout emoji="ℹ️">
        <ul>
          <li>
            <strong>Write short lines:</strong>
            if you line is too long, add a new line in between
          </li>
          <li>
            <strong>Use the right punctuation:</strong>
            use commas, periods, exclamation marks, etc.
          </li>
        </ul>
      </Callout>
      <TextInput source="script" fullWidth multiline />
    </SimpleForm>
  </Edit>
);
