import * as React from 'react';
import {
  CreateButton,
  Datagrid,
  Filter,
  List,
  SearchInput,
  TextField,
} from 'react-admin';
import { Colors } from 'src/styles/variables';

import { useAdminRoles } from '@hooks/useAdminRoles';
import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';
import { PhoneNumberField } from '@components/phone_number_field';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

import { UserAvatar } from './components/user_avatar';

const ListActions = () => {
  const canCreateUser = useAdminRoles('super_admin');
  return (
    <ResourceTitleActionBar
      mode="list"
      actions={
        <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
          <small style={{ color: Colors.Grey.primary }}>Search by:</small>
          <Filter>
            <SearchInput source="username" alwaysOn placeholder="username" />
            <SearchInput source="phoneNumber" alwaysOn placeholder="phone number" />
          </Filter>
          {canCreateUser && <CreateButton />}
        </div>
      }
    />
  );
};

export const UserList = () => (
  <List
    sort={{ field: 'username', order: 'ASC' }}
    perPage={PER_PAGE}
    pagination={<PER_PAGE_OPTIONS />}
    actions={<ListActions />}
    exporter={false}
    emptyWhileLoading
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <UserAvatar label="Avatar" />
      <TextField source="username" />
      <PhoneNumberField source="phoneNumber" />
    </Datagrid>
  </List>
);
