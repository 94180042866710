import React, { useMemo } from 'react';
import { useRecordContext } from 'react-admin';
import { Card, CardContent, CardHeader } from '@mui/material';
import { MissionType } from 'types/mission';

import TodoChecklist from '@components/todo_checklist';
import { UpdateFieldDialog } from '@components/update_field_dialog';

type TextsCardProps = {
  lang: 'EN' | 'FR';
};
const TextsCard = ({ lang = 'EN' }: TextsCardProps) => {
  const mission: MissionType = useRecordContext();

  const cardAvatar = useMemo(() => {
    if (lang === 'FR') {
      return '🇫🇷';
    }
    return '🇺🇸';
  }, [lang]);

  return (
    <Card>
      <CardHeader title="Title + Description" avatar={cardAvatar} />
      <CardContent>
        <TodoChecklist
          checked={!!mission?.[`title${lang}`]}
          label={`Title ${lang}`}
          cta={
            !mission.outcome && (
              <UpdateFieldDialog
                resource="missions"
                record={mission}
                type="text"
                field={`title${lang}`}
                buttonLabel="Update"
                buttonVariant="outlined"
              />
            )
          }
        >
          {mission[`title${lang}`] && (
            <small style={{ fontWeight: '600' }}>{mission[`title${lang}`]}</small>
          )}
        </TodoChecklist>
        <TodoChecklist
          checked={!!mission?.[`description${lang}`]}
          label={`Description ${lang}`}
          cta={
            !mission.outcome && (
              <UpdateFieldDialog
                resource="missions"
                record={mission}
                type="textarea"
                field={`description${lang}`}
                buttonLabel="Update"
                buttonVariant="outlined"
              />
            )
          }
        >
          {mission[`description${lang}`] && (
            <small style={{ whiteSpace: 'pre-line' }}>
              {mission[`description${lang}`]}
            </small>
          )}
        </TodoChecklist>
      </CardContent>
    </Card>
  );
};

export default TextsCard;
