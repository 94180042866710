import React, { useCallback } from 'react';
import {
  ArrayField,
  Button,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  UrlField,
  useDataProvider,
  useRecordContext,
} from 'react-admin';
import { CampaignType } from 'types/campaign';
import { ActionType } from 'types/action';
import moment from 'moment-timezone';

import { useAdminRoles } from '@hooks/useAdminRoles';
import { dateFormatter } from '@services/date';
import ellipsis from '@services/ellipsis';
import { CampaignQAList } from '@components/lists/campaign_qa_list';
import { CampaignCauseList } from '@components/lists/campaign_cause_list';
import { AdminUserCampaignList } from '@components/lists/admin_user_campaigns_list';
import Callout from '@components/callout';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import ActionCTATypeChip from '@components/action_cta_type_chip';
import Calendar, { CalendarItems } from '@components/calendar';
import ActionCard from '@components/calendar/components/action_card';
import { CampaignStoriesList } from '@models/campaigns/components/list/campaign_stories';

import MissionsGroupedByStatus from '../missions/components/list/missions_grouped_by_status';

import CampaignBlock from './components/campaign_block';

const ShowActions = () => {
  const canEdit = useAdminRoles(['super_admin', 'campaign_admin']);
  return (
    <ResourceTitleActionBar
      mode="show"
      actions={canEdit && <EditButton label="Edit Campaign" variant="outlined" />}
    />
  );
};

const CampaignShowComponent = () => {
  const campaign: CampaignType = useRecordContext();
  const canAddManagers = useAdminRoles(['super_admin', 'campaign_admin']);
  const dataProvider = useDataProvider();

  /**
   * Fetch calendar items
   * @param daysDisplayed
   * @returns {Promise<CalendarItems>}
   */
  const fetchCalendarItems = useCallback(
    async (daysDisplayed?: string[]) => {
      if (!campaign?.id || !daysDisplayed) {
        return {};
      }
      const { data } = await dataProvider.getList('actions', {
        pagination: { page: 1, perPage: 500 },
        sort: { field: 'publishedAt', order: 'ASC' },
        filter: {
          campaignId: campaign.id,
          publishedAt: daysDisplayed,
        },
      });

      const result: CalendarItems = {};
      data.forEach((action: ActionType) => {
        const day = moment(action.publishedAt).format('YYYY-MM-DD');
        if (!day) {
          return;
        }
        if (!result[day]) {
          result[day] = [];
        }
        result[day].push(<ActionCard key={action.id} action={action} withMission />);
      });
      return result;
    },
    [dataProvider, campaign?.id]
  );

  return (
    <>
      <TabbedShowLayout>
        {/* Home */}
        <Tab label="Home">
          <CampaignBlock campaign={campaign} withStatus />
          <br />
          <Calendar fetchItems={fetchCalendarItems} />
          <MissionsGroupedByStatus campaignId={campaign?.id} withLiveMissions={false} />
        </Tab>
        {/* Details */}
        <Tab label="Details">
          <TextField
            source="descriptionEN"
            label="English description"
            fullWidth
            component="pre"
            style={{ whiteSpace: 'pre-wrap' }}
          />
          <br />
          <TextField
            source="descriptionFR"
            label="French description"
            fullWidth
            component="pre"
            style={{ whiteSpace: 'pre-wrap' }}
          />
          <hr style={{ width: '100%', height: 1 }} />
          <DateField source="livedAt" />
          <hr style={{ width: '100%', height: 1 }} />
          <strong>Social links:</strong>
          <UrlField source="websiteUrl" />
          <UrlField source="instagramHandle" />
          <UrlField source="tiktokHandle" />
          <UrlField source="facebookHandle" />
          <UrlField source="twitterHandle" />
          <UrlField source="linkedinHandle" />
          <UrlField source="snapchatHandle" />
        </Tab>
        <Tab label="Causes" path="causes">
          <CampaignCauseList />
        </Tab>
        {/* FAQ */}
        <Tab label="FAQ" path="qas">
          <CampaignQAList />
        </Tab>
        {/* Stories */}
        <Tab label="Stories" path="stories">
          <CampaignStoriesList />
        </Tab>
        {/* Actions */}
        <Tab label="Actions" path="actions">
          <Callout emoji="🚨" backgroundColor="red">
            <strong>Deprecated:</strong>
            <br />
            This page will be removed by the end of the year, all actions must now be
            managed within a mission.
          </Callout>
          <ArrayField
            source="actions"
            label=""
            sort={{ field: 'publishedAt', order: 'DESC' }}
          >
            <Datagrid bulkActionButtons={false}>
              <FunctionField
                label="Published At"
                render={(record: ActionType) =>
                  record.publishedAt
                    ? dateFormatter(record.publishedAt, { short: true })
                    : 'draft'
                }
              />
              <ReferenceField
                source="missionId"
                reference="missions"
                link="show"
                label="Mission"
                sx={{ display: 'block', width: 200 }}
              />

              <FunctionField
                label="Name"
                render={(record: ActionType) => (
                  <small>
                    {ellipsis(
                      record.name || record.descriptionEN || record.descriptionFR,
                      40
                    )}
                  </small>
                )}
              />
              <FunctionField
                label="Action Type"
                render={(record: ActionType) => (
                  <ActionCTATypeChip ctaType={record.ctaType} />
                )}
              />
              <FunctionField
                label=""
                render={(record: ActionType) => (
                  <Button
                    size="small"
                    onClick={() => (window.location.href = `#/actions/${record.id}/show`)}
                    label="SHOW"
                  />
                )}
              />
            </Datagrid>
          </ArrayField>
        </Tab>

        {canAddManagers && (
          <Tab label="Managers" path="managers">
            <AdminUserCampaignList />
          </Tab>
        )}
      </TabbedShowLayout>
    </>
  );
};

export const CampaignShow = () => (
  <Show actions={<ShowActions />}>
    <CampaignShowComponent />
  </Show>
);
