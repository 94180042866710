import React from 'react';
import { EditButton, Show, SimpleShowLayout, TextField } from 'react-admin';

import { useAdminRoles } from '@hooks/useAdminRoles';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const ShowActions = () => {
  const canEdit = useAdminRoles('super_admin');
  return (
    <ResourceTitleActionBar
      mode="show"
      actions={canEdit ? <EditButton variant="outlined" /> : undefined}
    />
  );
};

export const CampaignCauseShow = () => (
  <Show actions={<ShowActions />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="emoji" />
      <TextField source="descriptionFR" />
      <TextField source="descriptionEN" />
    </SimpleShowLayout>
  </Show>
);
