import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Button,
  FileField,
  FileInput,
  Form,
  SelectInput,
  TextInput,
  Title,
  required,
  useDataProvider,
  useRedirect,
} from 'react-admin';
import { useSearchParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import axios from 'axios';
import { Card, CardContent } from '@mui/material';
import BackupIcon from '@mui/icons-material/Backup';
import Callout from 'src/components/callout';
import { VideoType } from 'types/video';

const videoLocales = [
  { id: 'en', name: 'English' },
  { id: 'fr', name: 'French' },
  { id: 'es', name: 'Spanish' },
  { id: 'pt', name: 'Portuguese' },
  { id: 'de', name: 'German' },
  { id: 'it', name: 'Italian' },
  { id: 'ru', name: 'Russian' },
  { id: 'ja', name: 'Japanese' },
];

const FOLDER = 'mission_stories_tmp_uploads';
const PAGE_TITLE = 'Mission Story Video Studio';

export default () => {
  let [searchParams] = useSearchParams();
  const [errors, setErrors] = useState([]);
  const [uploadStatus, setUploadStatus] = useState('Getting ready...');
  const [videoLocale, setVideoLocale] = useState(videoLocales[0].id);
  const [openAIPrompt, setOpenAIPrompt] = useState('Speaker will talk about:');
  const [position, setPosition] = useState(null);
  const [countStories, setCountStories] = useState(0);
  const dataProvider = useDataProvider();
  const redirect = useRedirect();

  const missionId = searchParams.get('missionId');

  /**
   * Position choices
   */
  const positionChoices = useMemo(
    () =>
      Array.from({ length: countStories + 1 }, (_, i) => {
        return { id: i + 1, name: i + 1 };
      }),
    [countStories]
  );

  const fetchMission = useCallback(async () => {
    const { data: mission } = await dataProvider.getOne('missions', {
      id: missionId,
    });
    let countStories = mission?.stories?.length || 0;

    setCountStories(countStories);
    setPosition(countStories + 1);
  }, [missionId]);

  /**
   * @param file
   * @returns presigned URL from API
   */
  const getS3PresignedURL = async (file: File) => {
    setUploadStatus('[1/4] Verifying the video...');
    const { data: presignedURL } = await dataProvider.getS3PresignedURL({
      filekey: `${FOLDER}/${missionId}-${file.name}`,
    });
    return presignedURL;
  };

  /**
   * Upload file to S3
   * @param file
   * @param presignedURL
   */
  const uploadFileToS3 = async (file: File, presignedURL: string) => {
    setUploadStatus('[2/4] Uploading video...');
    await axios.put(presignedURL, file);
  };

  /**
   * Upload video to API
   */
  const uploadVideo = async (file: File) => {
    setUploadStatus('[3/4] Compressing video...');

    const s3URL = `https://chilli-production.s3.eu-west-2.amazonaws.com/${FOLDER}/${missionId}-${file.name}`;
    await dataProvider.uploadMissionStoryVideo({
      missionId,
      s3URL,
      position,
    });

    let video = null;
    // Wait for video to be processed during ~7 minutes max
    for (let i = 0; i < 3 * 60; i++) {
      console.log('Waiting for video to be processed...', i);
      const { data: mission } = await dataProvider.getOne('missions', {
        id: missionId,
      });
      let newCountStories = mission.stories?.length;
      if (newCountStories > countStories) {
        video = mission.stories[newCountStories - 1].video;
        break;
      }

      await new Promise(resolve => setTimeout(resolve, 1000));
    }

    if (!video) {
      setUploadStatus('Error uploading video, refresh page and try again');
      return;
    }

    return video;
  };

  /**
   * Generate subtitles for video
   * @param video
   */
  const generateVideoSubtitles = async (video: VideoType) => {
    setUploadStatus(`[4/4] Generating and translating captions...`);
    await dataProvider.generateVideoSubtitles({
      videoId: video.id,
      prompt: openAIPrompt,
      language: videoLocale,
    });
  };

  const { mutate, isLoading } = useMutation(
    ['uploadMissionStoryVideo', missionId],
    async ({ file }: any) => {
      try {
        // 1. Get presigned URL from API
        const presignedURL = await getS3PresignedURL(file);

        // 2. Upload file to S3
        await uploadFileToS3(file, presignedURL);

        // 3. Upload video to chilli API
        const video = await uploadVideo(file);
        if (!video) {
          setUploadStatus('Error: video not processed');
          return;
        }

        // 4. Generate and translate subtitles
        await generateVideoSubtitles(video);
        setUploadStatus('Done! Redirecting...');
        redirect('show', 'missions', missionId!);
      } catch (error: any) {
        console.error(error);
        setUploadStatus('Error uploading video:' + error.message);
      }
    }
  );

  const onSubmit = async (data: any) => {
    if (!data.video) {
      window.alert('Please select a video file to upload');
      return;
    }
    await mutate({ file: data.video.rawFile });
  };

  useEffect(() => {
    fetchMission();
  }, [fetchMission]);

  if (isLoading) {
    return (
      <Card>
        <Title title={PAGE_TITLE} />
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: 300,
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 38 38"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#333"
          >
            <g fill="none" fillRule="evenodd">
              <g transform="translate(1 1)" strokeWidth="2">
                <circle strokeOpacity=".3" cx="18" cy="18" r="18" />
                <path d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="1s"
                    repeatCount="indefinite"
                  />
                </path>
              </g>
            </g>
          </svg>
          <br />
          <strong>{uploadStatus}</strong>
          <small>This might take a few minutes, don't close this page!</small>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <Title title={PAGE_TITLE} />
      <CardContent>
        <Form onSubmit={onSubmit}>
          <h3>Upload a new story to this mission</h3>
          <Callout emoji="📹">
            <strong>Compress</strong> your video before uploading it:
            <ul>
              <li>
                <strong>Max length recommended:</strong> 30sec
              </li>
              <li>
                <strong>Resolution:</strong> 720P
              </li>
              <li>
                <strong>Codec:</strong> HEVC
              </li>
            </ul>
          </Callout>
          <FileInput
            source="video"
            accept="video/*"
            isRequired
            maxSize={20000000}
            placeholder={
              <div>
                <p>Drop a file to upload, or click to select it</p>
                <small>
                  Maximum file size: <strong>20 MB</strong>
                </small>
              </div>
            }
            options={{
              onDropRejected: (e: any) => {
                console.error(e[0].errors);
                setErrors(e[0].errors);
              },
            }}
          >
            <FileField source="src" title="title" />
          </FileInput>
          <div>
            {errors.map((error: any) => (
              <small key={error.code} style={{ display: 'block', color: 'red' }}>
                <strong>{error.code}:</strong> {error.message}
              </small>
            ))}
          </div>
          <SelectInput
            source="videoLanguage"
            choices={videoLocales}
            defaultValue={videoLocale}
            onChange={e => setVideoLocale(e.target.value)}
            validate={[required()]}
          />
          <TextInput
            label="🤖 Give GPT all topics mentionned in the video to help auto captions"
            source="openAIPrompt"
            defaultValue={openAIPrompt}
            onChange={e => setOpenAIPrompt(e.target.value)}
            fullWidth
          />
          <SelectInput
            source="position"
            choices={positionChoices}
            defaultValue={position}
            onChange={e => setPosition(e.target.value)}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="submit"
              label="Start uploading"
              startIcon={<BackupIcon />}
              style={{
                backgroundColor: '#000',
                color: 'white',
                padding: '10px 20px',
              }}
            />
          </div>
        </Form>
      </CardContent>
    </Card>
  );
};
