import React from 'react';
import {
  SimpleForm,
  TextInput,
  NumberInput,
  DateTimeInput,
  ImageInput,
  ImageField,
  required,
} from 'react-admin';

import { SpacingStyle } from '@styles/variables';

import RadioBucketInput from './radio_bucket_input';

interface DonationFormProps {
  mode: 'create' | 'edit';
}

const DonationForm: React.FC<DonationFormProps> = ({ mode }) => {
  return (
    <SimpleForm>
      <ImageField source="avatarUrl" title="Avatar" />
      <ImageInput
        source="avatar"
        accept="image/*"
        maxSize={4000000}
        validate={mode === 'create' ? [required()] : []}
      >
        <ImageField source="src" title="title" />
      </ImageInput>

      <ImageField source="thumbnailUrl" title="Thumbnail" />
      <ImageInput
        source="thumbnail"
        accept="image/*"
        maxSize={4000000}
        validate={mode === 'create' ? [required()] : []}
      >
        <ImageField source="src" title="title" />
      </ImageInput>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: SpacingStyle.normal,
          width: '100%',
        }}
      >
        {/* English */}
        <div style={{ width: '100%' }}>
          <TextInput source="nameEN" label="Name EN" fullWidth validate={[required()]} />
          <TextInput
            source="taglineEN"
            label="Tagline EN"
            fullWidth
            validate={[required()]}
          />
          <TextInput
            source="descriptionEN"
            label="Description EN"
            fullWidth
            validate={[required()]}
            multiline
          />
        </div>
        {/* French */}
        <div style={{ width: '100%' }}>
          <TextInput source="nameFR" label="Name FR" fullWidth validate={[required()]} />
          <TextInput
            source="taglineFR"
            label="Tagline FR"
            fullWidth
            validate={[required()]}
          />

          <TextInput
            source="descriptionFR"
            label="Description FR"
            fullWidth
            validate={[required()]}
            multiline
          />
        </div>
      </div>

      <br />

      <DateTimeInput
        source="launchedAt"
        helperText="When the donation will be available on the app."
      />
      <DateTimeInput
        source="expiredAt"
        helperText="After this date, the donation will be removed from the app."
      />

      <br />

      <h4>Coins management</h4>
      <NumberInput
        label={'Total target coins'}
        source="maxCoins"
        fullWidth
        min={500}
        step={500}
        validate={[required()]}
        helperText="What is the target amount of coins to be donated for this donation?"
      />

      <RadioBucketInput source="buckets" />
    </SimpleForm>
  );
};

export default DonationForm;
