import React from 'react';
import { FunctionField } from 'react-admin';
import parsePhoneNumber from 'libphonenumber-js';
import AppleIcon from '@mui/icons-material/Apple';

import { Colors } from '@styles/variables';

type PhoneNumberFieldProps = {
  source: string;
};
export const PhoneNumberField = ({ source }: PhoneNumberFieldProps) => (
  <FunctionField
    label="Phone Number"
    render={(resource: any) => {
      if (!resource.phoneNumber) {
        if (resource.appleUserIdentifier) {
          return <AppleIcon style={{ fontSize: '1em', color: Colors.Grey.primary }} />;
        }
        return;
      }
      try {
        const parsed = parsePhoneNumber(resource.phoneNumber);
        if (!parsed) {
          return resource.phoneNumber;
        }
        if (parsed.isValid()) {
          return `+${parsed.countryCallingCode} ${parsed.formatNational()}`;
        }
      } catch {
        return resource.phoneNumber;
      }
    }}
  />
);
