import React from 'react';
import { SelectInput, required } from 'react-admin';
import { CommentEntityType } from 'types/comment.d';

import { dateFormatter } from '@services/date';
import ellipsis from '@services/ellipsis';
import CustomReferenceInput from '@components/inputs/custom_reference_input';

export const appPages = [
  {
    id: 'TopicComment',
    name: 'Topic Comment',
    children: (
      <CustomReferenceInput
        source="appPageObjectId"
        reference="topic_comments"
        queryKey="message"
        label="Choose a comment"
        filters={{
          parentCommentId: null,
          entityType: CommentEntityType.TOPIC,
          silentMessageEN: null,
          silentMessageFR: null,
        }}
        optionText={option =>
          `[${dateFormatter(option.createdAt, {
            short: true,
            withTime: true,
          })}]\t${ellipsis(option.message, 120)}`
        }
        sort={{ field: 'createdAt', order: 'DESC' }}
      />
    ),
  },
  {
    id: 'MissionProfile',
    name: 'Mission Profile',
    children: (
      <CustomReferenceInput
        source="appPageObjectId"
        reference="missions"
        queryKey="titleEN"
        label="Choose a mission"
        optionText={mission => (mission.publishedAt ? mission.titleEN : '[DRAFT]')}
        sort={{ field: 'createdAt', order: 'ASC' }}
      />
    ),
  },
  { id: 'Settings', name: 'Settings' },
  {
    id: 'SettingsPreferencesCtaTypes',
    name: 'CTA Preferences',
  },
];

export const AppPageSelectInput = () => (
  <SelectInput
    source="page"
    fullWidth
    validate={[required()]}
    choices={appPages.map(page => ({
      id: page.id,
      name: page.name,
    }))}
  />
);
