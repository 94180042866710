import React from 'react';
import { Button, DateField, useRecordContext } from 'react-admin';
import EditIcon from '@mui/icons-material/Edit';

export const WinComments = () => {
  const { comments } = useRecordContext();

  if (!comments || comments.length === 0) {
    return <small>Win didn't receive any comment from users yet.</small>;
  }

  return (
    <div>
      <strong>Comments posted:</strong> {comments.length}
      <br />
      <br />
      <table width={'100%'} style={{ borderSpacing: 0 }}>
        <tbody>
          <tr style={{ textAlign: 'left', backgroundColor: '#d5d5d5' }}>
            <th>Created at</th>
            <th>Avatar</th>
            <th>Username</th>
            <th>Message</th>
            <th></th>
          </tr>
          {comments &&
            comments.map((comment: any, i: number) => (
              <tr
                key={i}
                style={{
                  backgroundColor: i % 2 === 1 ? '#f9f9f9' : undefined,
                }}
              >
                <td>
                  <DateField source="createdAt" />
                </td>
                <td>
                  {!!comment.author.avatarUrl && (
                    <img
                      src={comment.author.avatarUrl}
                      width={20}
                      alt={comment.author.username}
                    />
                  )}
                </td>
                <td>
                  <a
                    href={`#/users/${comment.author.id}/show`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {comment.author.username}
                  </a>
                </td>
                <td>
                  <a href={comment.url} rel="noreferrer">
                    {comment.message}
                  </a>
                </td>
                <td>
                  <Button
                    href={`#/win_comments/${comment.id}`}
                    label="edit"
                    startIcon={<EditIcon />}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
