import * as React from 'react';
import { Edit, Labeled, SimpleForm, TextInput } from 'react-admin';
import ResourceTitleActionBar from '../../components/resource_title_action_bar';
import { AdminUserRoleInput } from './components/roles';

const EditActions = <ResourceTitleActionBar mode="edit" />;

export const AdminUserEdit = () => (
  <Edit actions={EditActions}>
    <SimpleForm>
      <TextInput source="username" fullWidth />
      <TextInput source="password" fullWidth />

      <AdminUserRoleInput />
    </SimpleForm>
  </Edit>
);
