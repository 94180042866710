import * as React from 'react';
import { CreateButton, Datagrid, FunctionField, List } from 'react-admin';

import { useAdminRoles } from '@hooks/useAdminRoles';
import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

import CRMTagChip from './components/chip';

const ListActions = () => {
  const canCreate = useAdminRoles(['super_admin', 'campaign_admin', 'campaign_manager']);
  return (
    <ResourceTitleActionBar
      mode="list"
      resourceName="Tags"
      actions={canCreate && <CreateButton variant="outlined" />}
    />
  );
};

export const CRMTagList = () => (
  <List
    sort={{ field: 'name', order: 'ASC' }}
    perPage={PER_PAGE}
    pagination={<PER_PAGE_OPTIONS />}
    exporter={false}
    actions={<ListActions />}
    emptyWhileLoading
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <FunctionField
        label="Tag name"
        render={(resource: any) => <CRMTagChip tag={resource} />}
      />
    </Datagrid>
  </List>
);
