import * as React from 'react';
import { Create, SimpleForm, TextInput, required } from 'react-admin';
import CustomReferenceInput from '@components/inputs/custom_reference_input';
import PropertiesArrayExample from './components/properties_array_example';

const redirectAfterCreate = (basePath: any, id: any, data: any) =>
  `actions/${data.actionId}/show/cta`;

export const ActionCtaWebpageCreate = () => (
  <Create redirect={redirectAfterCreate}>
    <SimpleForm>
      <CustomReferenceInput
        source="actionId"
        reference="actions"
        queryKey="descriptionEN"
        validate={[required()]}
        disabled
      />
      <TextInput source="websiteUrl" required fullWidth />

      <PropertiesArrayExample />
      <TextInput source="propertiesArray" required multiline fullWidth />
      <TextInput source="extraInjectedJavascript" multiline fullWidth />
    </SimpleForm>
  </Create>
);
