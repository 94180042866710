/**
 * @param { status } status of the campaign
 * @returns a colored chip with the status of the campaign
 */
import React, { useEffect, useState } from 'react';
import { CampaignType } from 'types/campaign';

import { Colors } from '@styles/variables';

type CampaignStatusChipProps = {
  campaign: CampaignType;
};
const CampaignStatusChip = ({ campaign }: CampaignStatusChipProps) => {
  const [status, setStatus] = useState<'not live' | 'live' | 'upcoming'>('live');
  const statusColor = {
    live: {
      background: Colors.Green[50],
      text: Colors.Green[900],
    },
    upcoming: {
      background: Colors.Orange[100],
      text: Colors.Orange.primary,
    },
    'not live': {
      background: Colors.Grey[100],
      text: Colors.Grey.primary,
    },
  };

  useEffect(() => {
    if (!campaign.livedAt) {
      setStatus('not live');
    } else if (new Date(campaign.livedAt) <= new Date()) {
      setStatus('live');
    } else {
      setStatus('upcoming');
    }
  }, [campaign.livedAt]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          padding: '2px 8px',
          fontSize: '.7em',
          fontWeight: '600',
          color: statusColor[status].text,
          textAlign: 'center',
          backgroundColor: statusColor[status].background,
          borderRadius: 20,
        }}
      >
        {status}
      </div>
    </div>
  );
};

export default CampaignStatusChip;
