import React from 'react';
import { useRecordContext } from 'react-admin';
import { MissionType } from 'types/mission';

import { useAdminRoles } from '@hooks/useAdminRoles';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

const MissionComments = () => {
  const mission: MissionType = useRecordContext();
  const canSeeComments = useAdminRoles(['super_admin', 'campaign_admin']);
  const { topic } = mission;
  const { commentsCount } = topic || {};

  if (!canSeeComments) {
    return null;
  }

  return (
    <a style={styles.floatingButton} href={`#/topics/${mission?.topic?.id}/chat`}>
      {!commentsCount
        ? 'no comments posted yet'
        : `${commentsCount} message${commentsCount > 1 ? 's' : ''} in the chat`}
    </a>
  );
};

const styles: any = {
  floatingButton: {
    position: 'fixed',
    bottom: 40,
    right: 60,
    zIndex: 1,
    padding: `${SpacingStyle.small}px ${SpacingStyle[24]}px`,
    fontSize: FontStyle.sizeSmall,
    fontWeight: 600,
    color: Colors.White.primary,
    textDecoration: 'none',
    borderRadius: BorderStyle.Radius.big,
    boxShadow: `1px 2px 20px ${Colors.Magenta[400]}`,
    backgroundColor: Colors.Magenta.primary,
    cursor: 'pointer',
  },
};

export default MissionComments;
