import * as React from 'react';
import { Create, required, SimpleForm, TextInput } from 'react-admin';

import CustomReferenceInput from '@components/inputs/custom_reference_input';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

import { CtaTypeInput } from './components/cta_type';
import { ExpiresInInput } from './components/expires_in';
import { PointInput } from './components/points';

const ActionCreateActions = () => <ResourceTitleActionBar mode="create" />;

const transform = (data: any) => {
  return {
    ...data,
    descriptionEN: data.descriptionEN.trim(),
    descriptionFR: data.descriptionFR.trim(),
  };
};

export const ActionCreate = () => (
  <Create transform={transform} actions={<ActionCreateActions />}>
    <SimpleForm>
      <CustomReferenceInput
        source="campaignId"
        reference="campaigns"
        queryKey="name"
        validate={true}
      />
      <TextInput
        label="Published at (no TZ)"
        source="publishedAt"
        placeholder="2023-02-01 00:01:00"
        fullWidth
        validate={[required()]}
      />
      <CtaTypeInput source="ctaType" label="CTA Type" />
      <PointInput source="points" label="Coins" />
      <ExpiresInInput source="expiresIn" label="Expires in X days" />
    </SimpleForm>
  </Create>
);
