import * as React from 'react';
import {
  SimpleShowLayout,
  Show,
  DateField,
  ReferenceField,
  TextField,
} from 'react-admin';
import ResourceTitleActionBar from 'src/components/resource_title_action_bar';
import { VideoField } from '../videos/components/video_field';

const ShowActions = () => <ResourceTitleActionBar mode="show" />;

export const MissionStoryShow = () => (
  <Show actions={<ShowActions />}>
    <SimpleShowLayout>
      <ReferenceField source="missionId" reference="missions" link="show" />
      <VideoField source="video" label="Video" reference="missionStoryId" showButtons />
      <DateField
        source="createdAt"
        showTime
        label={`Created at (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
      />
      <DateField
        source="updatedAt"
        showTime
        label={`Updated at (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
      />
    </SimpleShowLayout>
  </Show>
);
