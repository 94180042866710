import React, { useState } from 'react';
import { Button, useDataProvider } from 'react-admin';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { CampaignType } from 'types/campaign';
import { UserType } from 'types/user';

import { useAdminRoles } from '@hooks/useAdminRoles';
import { MissionIcon } from '@components/icons';
import InputStyle from '@styles/input';

type MissionCreateDialogProps = {
  campaignId?: CampaignType['id'];
  ownerId?: UserType['id'];
};
export const MissionCreateDialog = ({
  campaignId,
  ownerId,
}: MissionCreateDialogProps) => {
  const dataProvider = useDataProvider();
  const isReadOnly = useAdminRoles('read_only');
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [missionName, setMissionName] = useState('');
  const [error, setError] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
    setIsSubmitting(false);
  };

  const handleClose = () => {
    if (isSubmitting) {
      window.alert("You can't close this modal while the mission is being saved.");
      return;
    }
    setOpen(false);
  };

  const createMission = async () => {
    setIsSubmitting(true);
    try {
      const { data: mission } = await dataProvider.create('missions', {
        data: {
          name: missionName,
          campaignId,
          ownerId,
        },
      });
      window.location.href = `#/missions/${mission.id}/show`;
    } catch (error: any) {
      setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isReadOnly) {
    return null;
  }

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        label={'Create a draft mission'}
        startIcon={<MissionIcon size={16} />}
      />
      <Dialog onClose={handleClose} open={open} maxWidth="xs" fullWidth>
        <DialogTitle>What is the mission about?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Giving a clear name to your mission will help you find it later more easily.
          </DialogContentText>
          <input
            type="text"
            autoFocus
            style={InputStyle.input}
            placeholder="ex: raise awareness on Chubb Insurance"
            value={missionName}
            onChange={e => setMissionName(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                createMission();
              }
            }}
          />
          {error && <div style={{ color: 'red' }}>{error}</div>}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={createMission}
            label={isSubmitting ? 'Creating...' : 'Create'}
            disabled={isSubmitting || missionName.length < 4}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};
