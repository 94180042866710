import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import CustomReferenceInput from '@components/inputs/custom_reference_input';

export const AdminUserCampaignEdit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <TextInput disabled source="id" fullWidth />
      <CustomReferenceInput
        source="adminUserId"
        reference="admin_users"
        queryKey="username"
      />
      <CustomReferenceInput source="campaignId" reference="campaigns" queryKey="name" />
    </SimpleForm>
  </Edit>
);
