import React from 'react';
import { Button, useRecordContext, useRedirect } from 'react-admin';
import EditIcon from '@mui/icons-material/Edit';

import { useAdminRoles } from '@hooks/useAdminRoles';

export const UserFeatureFlagsCustomList = () => {
  const { featureFlags } = useRecordContext();
  const redirect = useRedirect();
  const canEdit = useAdminRoles(['super_admin', 'product']);

  return (
    <table width={'100%'} style={{ borderSpacing: 0 }}>
      <tbody>
        <tr style={{ textAlign: 'left', backgroundColor: '#d5d5d5' }}>
          <th>Name</th>
          <th>Enabled At</th>
          <th></th>
        </tr>
        {featureFlags
          .sort((f1: any, f2: any) => (f1.name > f2.name ? 1 : -1))
          .map((ff: any, i: number) => (
            <tr
              key={i}
              style={{
                height: 100,
                backgroundColor: i % 2 === 1 ? '#f9f9f9' : undefined,
                cursor: 'pointer',
              }}
              onClick={() => {
                redirect('show', 'feature_flags', ff.id);
              }}
            >
              <td>
                <strong>{ff.name}</strong>
              </td>
              <td>
                {ff.enabledAt
                  ? `Opened to everyone on ${ff.enabledAt}`
                  : `Added on ${ff.addedAt}`}
              </td>
              <td>
                {canEdit && (
                  <Button
                    href={`#/users_feature_flags/${ff.userFeatureFlagId}`}
                    label="edit"
                    onClick={e => e.stopPropagation()}
                    startIcon={<EditIcon />}
                  />
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};
