import React from 'react';
import { SelectInput, required } from 'react-admin';

export const expiresInValues = [
  { id: 1, name: '1 day' },
  { id: 2, name: '2 days' },
  { id: 3, name: '3 days' },
  { id: 4, name: '4 days' },
  { id: 5, name: '5 days' },
  { id: 6, name: '6 days' },
  { id: 7, name: '7 days' },
  { id: 31, name: '1 month' },
  { id: 183, name: '6 months' },
  { id: 365, name: '1 year' },
  { id: 3650, name: 'Never' },
];

export const ExpiresInInput = ({ source }: any) => (
  <SelectInput
    source={source}
    choices={expiresInValues}
    emptyValue={7}
    validate={[required()]}
  />
);
