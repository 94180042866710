import React from 'react';
import {
  EditButton,
  ImageField,
  Show,
  SimpleShowLayout,
  TextField,
  UrlField,
  useRecordContext,
} from 'react-admin';
import { PepperPillType } from 'types/pepper_pill';

import { useAdminRoles } from '@hooks/useAdminRoles';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

import PepperPillPreview from './components/preview';

const ShowActions = () => {
  const canEdit = useAdminRoles(['super_admin', 'campaign_admin', 'product']);
  return (
    <ResourceTitleActionBar
      mode="show"
      actions={canEdit && <EditButton label="Edit" variant="outlined" />}
    />
  );
};

const Content = () => {
  const pepperPill: PepperPillType = useRecordContext();
  return (
    <SimpleShowLayout>
      <TextField source="createdAt" />
      <TextField source="type" />
      <ImageField source="imageUrl" title="Image" />
      <hr />
      <TextField label="Title EN" source="titleEN" />
      <TextField label="Content EN" source="contentEN" />
      <hr />
      <TextField label="Title FR" source="titleFR" />
      <TextField label="Content FR" source="contentFR" />
      <UrlField source="sourceUrl" />

      <PepperPillPreview pepperPill={pepperPill} />
    </SimpleShowLayout>
  );
};

export const PepperPillShow = () => (
  <Show actions={<ShowActions />}>
    <Content />
  </Show>
);
