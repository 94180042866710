import * as React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import CustomReferenceInput from '@components/inputs/custom_reference_input';
import PropertiesArrayExample from './components/properties_array_example';

const redirectAfterEdit = (basePath: any, id: any, data: any) =>
  `actions/${data.actionId}/show/cta`;

export const ActionCtaWebpageEdit = () => (
  <Edit redirect={redirectAfterEdit} mutationMode="pessimistic">
    <SimpleForm>
      <CustomReferenceInput source="actionId" reference="actions" disabled />
      <TextInput source="websiteUrl" required fullWidth />

      <PropertiesArrayExample />
      <TextInput source="propertiesArray" required multiline fullWidth />
      <TextInput source="extraInjectedJavascript" multiline fullWidth />
    </SimpleForm>
  </Edit>
);
