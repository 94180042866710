import React, { useState } from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import { Button } from 'react-admin';
import { IoDownload } from 'react-icons/io5';
import dataProvider from 'src/dataProvider';

import ResourceTitleActionBar from '@components/resource_title_action_bar';

const ProductAnalytics = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  /**
   * Download the academy report
   */
  const handleDownloadAcademyReport = async () => {
    try {
      setIsDownloading(true);
      await dataProvider.downloadAcademyReport();
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <div>
      <ResourceTitleActionBar title="Analytics" mode="list" />
      <Card>
        <CardHeader title="🎓 Academy" />
        <CardContent>
          <Button
            label={isDownloading ? 'Downloading...' : 'Download activity report'}
            startIcon={<IoDownload />}
            onClick={handleDownloadAcademyReport}
            disabled={isDownloading}
            variant="contained"
          />
        </CardContent>
      </Card>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default ProductAnalytics;
