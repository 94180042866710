import * as React from 'react';
import { SimpleForm, Create } from 'react-admin';
import CustomReferenceInput from '../../components/inputs/custom_reference_input';

const redirectAfterCreate = (_basePath: any, _id: any, data: any) =>
  `users/${data.userId}/show/missions`;

export const UserMissionCreate = () => (
  <Create redirect={redirectAfterCreate}>
    <SimpleForm>
      <CustomReferenceInput source="userId" reference="users" queryKey="username" />
      <CustomReferenceInput source="missionId" reference="missions" queryKey="titleEN" />
    </SimpleForm>
  </Create>
);
