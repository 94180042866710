import * as React from 'react';
import { List, Datagrid, ReferenceField } from 'react-admin';

import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const ListActions = () => <ResourceTitleActionBar mode="list" />;

export const MissionCauseList = () => (
  <List
    perPage={PER_PAGE}
    pagination={<PER_PAGE_OPTIONS />}
    emptyWhileLoading
    actions={<ListActions />}
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <ReferenceField source="missionId" reference="missions" link="show" />
      <ReferenceField source="causeId" reference="causes" link="show" />
    </Datagrid>
  </List>
);
