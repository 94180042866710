import * as React from 'react';
import {
  DateField,
  FunctionField,
  ImageField,
  ReferenceField,
  Show,
  TabbedShowLayout,
  TextField,
  Tab,
  useRecordContext,
  EditButton,
} from 'react-admin';
import InfoIcon from '@mui/icons-material/Info';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CommentIcon from '@mui/icons-material/Comment';

import { useAdminRoles } from '@hooks/useAdminRoles';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import { WinComments } from '@components/lists/win_comment_list';
import { WinLikes } from '@components/lists/win_likes_list';

const ShowActions = () => {
  const canEdit = useAdminRoles(['super_admin', 'campaign_admin', 'campaign_manager']);
  return (
    <ResourceTitleActionBar
      mode="show"
      actions={canEdit && <EditButton label="Edit" variant="outlined" />}
    />
  );
};

export const WinShowTabs = () => {
  const win = useRecordContext();

  return (
    <TabbedShowLayout>
      <Tab label="summary" icon={<InfoIcon />} iconPosition="start">
        <DateField
          source="createdAt"
          showTime
          label={`Created at (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
        />
        <DateField
          source="updatedAt"
          showTime
          label={`Updated at (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
        />
        <hr />
        <ReferenceField
          source="campaignId"
          reference="campaigns"
          link="show"
          label="Campaign"
        >
          <TextField source="name" label="Campaign" />
        </ReferenceField>
        <ReferenceField source="actionId" reference="actions" link="show" label="Action">
          <TextField source="name" label="Action" />
        </ReferenceField>

        <ImageField source="imageUrl" />
        <ImageField source="socialUrl" label="Instagram image" />
        <hr />
        <TextField source="shortCopyEn" label="Short Copy EN" />
        <FunctionField
          render={(record: any) => record?.longCopyEn}
          label="Long Copy EN"
        />
        <hr />
        <TextField source="shortCopyFr" label="Short Copy FR" />
        <FunctionField
          render={(record: any) => record?.longCopyFr}
          label="Long Copy FR"
        />
      </Tab>
      <Tab
        label="Users comments"
        path="comments"
        icon={<CommentIcon />}
        iconPosition="start"
        count={win?.comments?.length || 0}
      >
        <WinComments />
      </Tab>
      <Tab
        label="Users likes"
        path="likes"
        icon={<FavoriteIcon />}
        iconPosition="start"
        count={win?.likes?.length || 0}
      >
        <WinLikes />
      </Tab>
    </TabbedShowLayout>
  );
};

export const WinShow = (
  <Show actions={<ShowActions />}>
    <WinShowTabs />
  </Show>
);
