import React from 'react';
import { useState } from 'react';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { Button, Confirm, useDataProvider, useRefresh } from 'react-admin';
import { useMutation } from 'react-query';

import { useAdminRoles } from '../hooks/useAdminRoles';

type PushNotifyUserButtonProps = {
  user: any;
};
export const PushNotifyUserButton = ({ user }: PushNotifyUserButtonProps) => {
  const dataProvider = useDataProvider();
  const { mutate, isLoading } = useMutation(
    ['sendUserPushNotification', user],
    async () => {
      let pushNotificationBody = window.prompt('Notification body');
      await dataProvider.sendUserPushNotification({
        userId: user.id,
        pushNotificationBody,
      });
    }
  );
  if (!useAdminRoles('super_admin')) {
    return <></>;
  }
  return (
    <Button
      label="Send Push Notification"
      onClick={() => mutate()}
      children={<NotificationsActiveIcon />}
      disabled={isLoading}
      variant="outlined"
    />
  );
};

type AmplitudeButtonProps = {
  user: any;
};
export const AmplitudeButton = ({ user }: AmplitudeButtonProps) => {
  const handleOnClick = () => {
    window.open(
      `https://analytics.amplitude.com/regroop/project/408094/search/user_id%3D${user.id}`,
      '_blank'
    );
  };
  return (
    <Button
      label="Amplitude"
      onClick={handleOnClick}
      children={<QueryStatsIcon />}
      variant="outlined"
    />
  );
};

type UnsubscribeUserFromCampaignProps = {
  userCampaignId: string;
};
export const UnsubscribeUserFromCampaign = ({
  userCampaignId,
}: UnsubscribeUserFromCampaignProps) => {
  const dataProvider = useDataProvider();
  const [open, setOpen] = useState(false);
  const canUnsubscribe = useAdminRoles('super_admin');

  const { mutate, isLoading } = useMutation(['delete', userCampaignId], async () => {
    await dataProvider.delete('users_campaigns', {
      id: userCampaignId,
    });
  });

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async () => {
    await mutate(undefined, {
      onSuccess: () => {
        setOpen(false);
        window.location.reload();
      },
    });
  };

  if (!canUnsubscribe) {
    return <></>;
  }

  return (
    <>
      <Button
        label="Unsubscribe"
        children={<NotificationsActiveIcon />}
        onClick={handleClick}
      />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="User's campaign"
        content="Are you sure you want to unsubscribe this user from the campaign?"
        onClose={handleDialogClose}
        onConfirm={handleConfirm}
      />
    </>
  );
};

type UnsubscribeUserFromMissionProps = {
  userMissionId: string;
};
export const UnsubscribeUserFromMission = ({
  userMissionId,
}: UnsubscribeUserFromMissionProps) => {
  const dataProvider = useDataProvider();
  const [open, setOpen] = useState(false);
  const canUnsubscribe = useAdminRoles('super_admin');
  const refresh = useRefresh();

  const { mutate, isLoading } = useMutation(['delete', userMissionId], async () => {
    await dataProvider.delete('users_missions', {
      id: userMissionId,
    });
  });

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async () => {
    await mutate(undefined, {
      onSuccess: () => {
        setOpen(false);
        refresh();
      },
    });
  };

  if (!canUnsubscribe) {
    return <></>;
  }

  return (
    <>
      <Button
        label="Unsubscribe"
        children={<NotificationsActiveIcon />}
        onClick={handleClick}
      />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="User's mission"
        content="Are you sure you want to unsubscribe this user from the mission?"
        onClose={handleDialogClose}
        onConfirm={handleConfirm}
      />
    </>
  );
};
