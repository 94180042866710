import { Qualifier } from "../../internal/qualifier/Qualifier.js";
import { createBackgroundModel } from "../../internal/models/createBackgroundModel.js";
import { createBackgroundFromModel } from "../../internal/models/createBackgroundFromModel.js";
import { ResizeSimpleAction } from "./ResizeSimpleAction.js";
/**
 * @description Tries to prevent a "bad crop" by first attempting to use the auto cropping mode, but adding some padding if the algorithm determines that more of the original image needs to be included in the final image.
 * @extends Actions.Resize.autoPad
 * @memberOf Actions.Resize
 * @see Visit {@link Actions.Resize| Resize} for examples
 */
class ResizeAutoPadAction extends ResizeSimpleAction {
    constructor(cropType, cropWidth, cropHeight) {
        super(cropType, cropWidth, cropHeight);
        this.addQualifier(new Qualifier('g', 'auto'));
    }
    /**
     * @description Sets the background.
     * @param {Qualifiers.Background} backgroundQualifier Defines the background color to use instead of
     * transparent background areas or when resizing with padding.
     */
    background(backgroundQualifier) {
        this._actionModel.background = createBackgroundModel(backgroundQualifier);
        return this.addQualifier(backgroundQualifier);
    }
    gravity(gravity) {
        // Only `auto` gravity is allowed (and required) for `c_auto_pad` and it's already added automatically in the constructor.
        // Although, this method is needed to exist because it is being shown in autogenerated SDK code snippet.
        return this;
    }
    static fromJson(actionModel) {
        const result = super.fromJson.apply(this, [actionModel]);
        actionModel.background && result.background(createBackgroundFromModel(actionModel.background));
        return result;
    }
}
export { ResizeAutoPadAction };
