import * as React from 'react';
import { DateField, EditButton, Show, SimpleShowLayout, TextField } from 'react-admin';

import { useAdminRoles } from '@hooks/useAdminRoles';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const ShowActions = () => {
  const canEdit = useAdminRoles('super_admin');
  return (
    <ResourceTitleActionBar
      mode="show"
      actions={canEdit ? <EditButton variant="outlined" /> : undefined}
    />
  );
};

export const ProfileSkillShow = () => (
  <Show actions={<ShowActions />}>
    <SimpleShowLayout>
      <TextField source="labelEN" />
      <TextField source="labelFR" />
      <TextField source="emoji" />
      <TextField source="key" />
      <TextField source="category" />
      <DateField
        source="createdAt"
        showTime
        label={`Created at (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
      />
      <DateField
        source="updatedAt"
        showTime
        label={`Updated at (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
      />
    </SimpleShowLayout>
  </Show>
);
