import { useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';

interface OpenAICompletion {
  systemMessage: string;
  prompt: string;
}

interface OpenAITranslate {
  text: string;
  from: string;
  to: string;
}

interface OpenAIFixGrammar {
  text: string;
  saveInClipboard?: boolean;
}

export const useOpenAI = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  /**
   * Create a completion using OpenAI
   * @param systemMessage
   * @param prompt
   * @returns promise<string>
   */
  const createCompletion = async ({ systemMessage, prompt }: OpenAICompletion) => {
    setIsLoading(true);
    try {
      const { data } = await dataProvider.createOpenAICompletion({
        systemMessage,
        prompt,
      });
      return data;
    } catch (error) {
      console.error('[useOpenAI.createCompletion]', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Translate text from one language to another
   * @param text
   * @param from
   * @param to
   * @returns promise<string>
   */
  const translateText = async ({ text, from, to }: OpenAITranslate) => {
    const systemMessage = `You are a casual, friendly language translator.
When translating to French, use the informal "on" and "tu" forms.
Provide a natural, conversational translation that captures the intended meaning and tone of the original text.
If the input text includes any slang, idioms, or cultural references, try to convey their essence in the translated text while maintaining a similar casual and informal tone.
Your goal is to produce a translation that reads smoothly and naturally in the target language, as if written by a native speaker in a casual setting.
Return only the translated text, without any additional information or formatting.
`;
    const prompt = `Translate the following text from ${from} to ${to}:
${text}`;

    return createCompletion({ systemMessage, prompt });
  };

  /**
   * Fix grammar, spelling, and writing issues in text
   * @param text
   * @param saveInClipboard
   * @returns promise<string>
   */
  const fixGrammar = async ({ text, saveInClipboard = true }: OpenAIFixGrammar) => {
    const systemMessage = `You are a professional editor and writer tasked with fixing grammar, spelling, and writing issues in text while maintaining our brand's distinct tone of voice.
Our brand voice is bold and inspiring, sassy and positive, action oriented, young and casual.
Your goal is to fix a text by writing a text that is clear, well-written, and accurately represents our brand personality.`;

    const prompt = `
# Instructions:
- When making corrections, preserve the original meaning and intent of the text as much as possible.
- You must keep the same language as the original text, do not translate it.
- You must adhere to the spacing conventions of the language used in the original text. (example in French: "Bonjour, comment ça va ?" and not "Bonjour, comment ça va?")
- You must keep emojis and other special characters as they are in the original text, do not add or remove any of them.

# Text to fix:
${text}`;

    const fixedText = await createCompletion({ systemMessage, prompt });

    if (saveInClipboard) {
      await navigator.clipboard.writeText(text);
      notify('📋 old version copied to clipboard', {
        type: 'info',
      });
    }

    return fixedText;
  };

  return {
    isLoading,
    createCompletion,
    translateText,
    fixGrammar,
  };
};
