import React from 'react';
import { Button, useRecordContext, useRedirect } from 'react-admin';
import 'property-information';
import ReactMarkdown from 'react-markdown';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import { useAdminRoles } from '@hooks/useAdminRoles';

export const CampaignQAList = () => {
  const { id, qas } = useRecordContext();
  const redirect = useRedirect();
  const isReadOnly = useAdminRoles('read_only');

  return (
    <div>
      <table width={'100%'} style={{ borderSpacing: 0 }}>
        <tbody>
          {qas &&
            qas.map((qa: any, i: number) => (
              <tr
                key={i}
                style={{
                  backgroundColor: i % 2 === 1 ? '#f4f4f6' : undefined,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  redirect('show', 'campaign_qas', qa.id);
                }}
              >
                <td style={{ padding: '0 10px' }}>
                  {qa.answerEN && (
                    <>
                      <h4>🇺🇸 {qa.questionEN}</h4>
                      <ReactMarkdown>{qa.answerEN}</ReactMarkdown>
                    </>
                  )}
                  <hr />

                  {qa.questionFR && (
                    <>
                      <h4>🇫🇷 {qa.questionFR}</h4>
                      <ReactMarkdown>{qa.answerFR}</ReactMarkdown>
                      <br />
                    </>
                  )}
                </td>
                <td>
                  {!isReadOnly && (
                    <Button
                      href={`#/campaign_qas/${qa.id}`}
                      onClick={e => e.stopPropagation()}
                      label="edit"
                      startIcon={<EditIcon />}
                    />
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {!isReadOnly && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <Button
            href={`#/campaign_qas/create?source={"campaignId":"${id}"}`}
            label="Add an question/answer"
            startIcon={<AddIcon />}
          />
        </div>
      )}
    </div>
  );
};
