import * as React from 'react';
import { Datagrid, DateField, List, ReferenceField } from 'react-admin';
import { VideoField } from '../videos/components/video_field';

export const CampaignStoryList = () => (
  <List exporter={false} emptyWhileLoading>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <ReferenceField source="campaignId" reference="campaigns" link="show" />
      <DateField source="createdAt" />
      <VideoField source="video?.videoUrl" label="Video" />
    </Datagrid>
  </List>
);
