import React, { useCallback, useEffect, useState } from 'react';
import { Button, useDataProvider, useRecordContext } from 'react-admin';
import { UserType } from 'types/user';
import axios from 'axios';
import moment from 'moment-timezone';

import { BorderStyle, Colors, SpacingStyle } from '@styles/variables';

const GOOGLE_MAPS_API_KEY = 'AIzaSyCzvUA8GbGXdDZrNLwpaIMi2Ltk_bgP44o';

type UserSession = {
  ipAddress: string;
  startedAt: string;
  endedAt: string;
};

const SessionCard = ({
  session,
  isLatestSession,
}: {
  session: UserSession;
  isLatestSession: boolean;
}) => {
  const [ipInfo, setIpInfo] = useState<any>(null);

  const getIPInfo = useCallback(async () => {
    const { data } = await axios.get(
      `https://api.ipapi.is?q=${session.ipAddress}&key=02942f8b7b35aa24`
    );
    setIpInfo(data);
  }, [session.ipAddress]);

  useEffect(() => {
    if (isLatestSession) {
      getIPInfo();
    }
  }, [getIPInfo, isLatestSession]);

  const renderIPInfo = () => {
    if (!ipInfo) {
      return null;
    }

    if (!ipInfo.location || !ipInfo.company || !ipInfo.asn) {
      return <div>No info found for this IP</div>;
    }

    return (
      <div style={sessionStyles.infoContainer}>
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${ipInfo.location.latitude},${ipInfo.location.longitude}`}
          target="_blank"
        >
          <img
            src={`https://maps.googleapis.com/maps/api/staticmap?center=${ipInfo.location.latitude},${ipInfo.location.longitude}&zoom=10&size=400x180&key=${GOOGLE_MAPS_API_KEY}`}
            style={sessionStyles.map}
          />
        </a>
        <div>
          <div>
            <strong>Location: </strong>
            {ipInfo.location.city}, {ipInfo.location.state}, {ipInfo.location.country}
          </div>
          <div>
            <strong>Company: </strong>
            {ipInfo.company.name} (Abuser Score: {ipInfo.company.abuser_score})
          </div>
          <div>
            <strong>ASN: </strong>
            {ipInfo.asn.org}, {ipInfo.asn.domain}
          </div>
        </div>
      </div>
    );
  };

  if (!session.ipAddress) {
    return;
  }

  return (
    <div style={sessionStyles.container}>
      <div style={sessionStyles.header}>
        <div style={sessionStyles.header}>
          <code style={{ fontWeight: 800 }}>{session.ipAddress}</code>
          {!ipInfo && (
            <Button
              onClick={getIPInfo}
              label="Get info"
              variant="outlined"
              size="small"
            />
          )}
        </div>
        <small style={{ color: Colors.Grey.primary }}>
          {moment(session.startedAt).fromNow()}
        </small>
      </div>
      {renderIPInfo()}
    </div>
  );
};

const SpyTab = () => {
  const user = useRecordContext<UserType>();
  const dataProvider = useDataProvider();
  const [userSessions, setUserSessions] = useState<UserSession[]>([]);

  const fetchLastDeviceUUIDsLogs = useCallback(async () => {
    // 1. Get all device uuids for a user
    const { data: deviceUuids } = await dataProvider.getList('device_uuids', {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'createdAt', order: 'DESC' },
      filter: { userId: user.id },
    });

    const sessions: UserSession[] = [];
    for (const deviceUuid of deviceUuids) {
      // 2. Get the last IP address for each device uuid
      const { data: deviceUuidLogs } = await dataProvider.getList('device_uuids_logs', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'createdAt', order: 'DESC' },
        filter: { deviceUuidId: deviceUuid.id },
      });
      for (let i = 0; i < deviceUuidLogs.length; i++) {
        const deviceUuidLog = deviceUuidLogs[i];
        if (i === 0) {
          sessions.push({
            ipAddress: deviceUuidLog.ipAddress,
            startedAt: deviceUuidLog.createdAt,
            endedAt: deviceUuidLog.createdAt,
          });
          continue;
        }
        const pastSession = sessions[sessions.length - 1];
        if (
          pastSession.ipAddress === deviceUuidLog.ipAddress &&
          moment(deviceUuidLog.createdAt).diff(moment(pastSession.endedAt), 'minutes') <
            30
        ) {
          pastSession.endedAt = deviceUuidLog.createdAt;
        } else {
          sessions.push({
            ipAddress: deviceUuidLog.ipAddress,
            startedAt: deviceUuidLog.createdAt,
            endedAt: deviceUuidLog.createdAt,
          });
        }
      }
    }
    setUserSessions(sessions);
  }, [dataProvider, user.id]);

  useEffect(() => {
    fetchLastDeviceUUIDsLogs();
  }, [fetchLastDeviceUUIDsLogs]);

  if (!user) {
    return null;
  }

  return (
    <div>
      <h2>Latest app sessions:</h2>
      {userSessions.map((userSession, index) => (
        <SessionCard session={userSession} key={index} isLatestSession={index === 0} />
      ))}
    </div>
  );
};

const sessionStyles: any = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 900,
    padding: SpacingStyle.normal,
    border: `1px solid ${Colors.Grey[50]}`,
    borderRadius: BorderStyle.Radius.normal,
    marginBottom: SpacingStyle.normal,
    gap: SpacingStyle.normal,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: SpacingStyle.small,
  },
  infoContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: SpacingStyle.normal,
  },
  map: {
    backgroundColor: Colors.Grey[100],
    height: 180,
    width: 400,
    borderRadius: BorderStyle.Radius.normal,
  },
};

export default SpyTab;
