import * as React from 'react';
import {
  EditButton,
  FunctionField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

import { useAdminRoles } from '@hooks/useAdminRoles';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

import CRMTagChip from './components/chip';

const ShowActions = () => {
  const canEdit = useAdminRoles(['super_admin', 'campaign_admin', 'campaign_manager']);
  return (
    <ResourceTitleActionBar
      mode="show"
      resourceName="Tags"
      actions={canEdit && <EditButton variant="outlined" />}
    />
  );
};

export const CRMTagShow = () => (
  <Show actions={<ShowActions />}>
    <SimpleShowLayout>
      <TextField source="name" />
      <FunctionField
        label="Color"
        render={(resource: any) => <CRMTagChip tag={resource} />}
      />
    </SimpleShowLayout>
  </Show>
);
