import * as React from 'react';
import { CreateButton, Datagrid, DateField, List, TextField } from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';

import { AdminUserCampaignsCountField, AdminUserRoleField } from './components/roles';

const ListActions = (
  <ResourceTitleActionBar mode="list" actions={<CreateButton variant="outlined" />} />
);

export const AdminUserList = () => (
  <List
    exporter={false}
    sort={{ field: 'username', order: 'ASC' }}
    perPage={PER_PAGE}
    pagination={<PER_PAGE_OPTIONS />}
    actions={ListActions}
    emptyWhileLoading
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="username" />
      <AdminUserRoleField source="role" />
      <AdminUserCampaignsCountField />
      <DateField source="createdAt" showTime />
      <DateField source="lastConnectedAt" showTime />
    </Datagrid>
  </List>
);
