import React from 'react';

import { BorderStyle, Colors, SpacingStyle } from '@styles/variables';

type CalloutProps = {
  backgroundColor?: 'blue' | 'green' | 'yellow' | 'red' | 'grey' | string;
  emoji?: string | React.ReactNode;
  children: React.ReactNode;
  style?: React.CSSProperties;
};
export default ({ backgroundColor, emoji, children, style }: CalloutProps) => {
  const getBackgroundColor = () => {
    if (backgroundColor) {
      if (backgroundColor.startsWith('#')) {
        return backgroundColor;
      }

      switch (backgroundColor) {
        case 'blue':
          return Colors.Blue[100];
        case 'green':
          return Colors.Green[25];
        case 'yellow':
          return Colors.Yellow[100];
        case 'red':
          return Colors.Red[100];
        case 'grey':
          return Colors.Grey[100];
      }
    }
    return Colors.Blue[100];
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: getBackgroundColor(),
        width: '100%',
        maxWidth: 800,
        boxSizing: 'border-box',
        margin: '18px 0',
        padding: SpacingStyle.small,
        borderLeft: '2px solid rgba(0,0,0,.1)',
        borderRadius: BorderStyle.Radius.small,
        fontSize: '0.8em',
        color: Colors.OffBlack.primary,
        ...style,
      }}
    >
      <div style={{ height: '100%' }}>
        <div
          style={{
            borderRadius: 8,
            backgroundColor: 'rgba(255,255,255,.4)',
            width: 40,
            height: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 24,
            marginRight: 10,
          }}
        >
          {emoji ? emoji : '☝️'}
        </div>
      </div>
      <div style={{ lineHeight: 1.4 }}>{children}</div>
    </div>
  );
};
