import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDataProvider } from 'react-admin';
import TranslateIcon from '@mui/icons-material/Translate';

import LoadingAnimation from '../svgs/loading_animation';
import SubtitleScript from './components/subtitle_script';
import SubtitleTimeline from './components/subtitle_timeline';
import Video from './components/video';
import VideoTimeline from './components/video_timeline';
import {
  SubtitleStudioContext,
  SubtitleStudioContextType,
  SubtitleStudioProvider,
} from './context';
import Controllers from './components/controllers';
import { SubtitleType } from 'types/subtitle';

const LOCALE_HUMANIZED: any = {
  en: '🇺🇸 English',
  es: '🇪🇸 Español',
  fr: '🇫🇷 Français',
  it: '🇮🇹 Italiano',
  pt: '🇵🇹 Português',
  de: '🇩🇪 Deutsch',
};

const FEEDBACK_URL =
  '//www.notion.so/regroop/Allow-Campaign-Managers-to-edit-subtitles-for-a-video-ec6af648149845f2b402de0262d804fe?pvs=4';

type SubtitlesStudioProps = {
  videoId?: string;
};
const SubtitlesStudio = ({ videoId }: SubtitlesStudioProps) => {
  const dataProvider = useDataProvider();
  const {
    video,
    setVideo,
    videoHtmlElement,
    selectedSubtitle,
    setSelectedSubtitle,
    widthPerSecond,
  } = useContext<SubtitleStudioContextType>(SubtitleStudioContext);
  const { search } = useLocation();
  const containerRef = useRef<HTMLDivElement>(null);
  const timelinesRef = useRef<HTMLDivElement>(null);
  const [videoDuration, setVideoDuration] = useState<number>(0);

  useEffect(() => {
    fetchVideo();
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(search);
    const lang = query.get('lang');
    if (lang) {
      const langSubtitle = video?.subtitles?.find(
        (subtitle: SubtitleType) => subtitle.locale === lang
      );
      if (langSubtitle) {
        setSelectedSubtitle(langSubtitle);
      }
    }
  }, [search, video?.subtitles]);

  useEffect(() => {
    if (!videoHtmlElement) {
      return;
    }
    videoHtmlElement.addEventListener('loadeddata', handleLoadedData);
    videoHtmlElement.addEventListener('timeupdate', scrollToBlock);
    return () => {
      videoHtmlElement.removeEventListener('loadeddata', handleLoadedData);
      videoHtmlElement.removeEventListener('timeupdate', scrollToBlock);
    };
  }, [videoHtmlElement]);

  const fetchVideo = async () => {
    if (!videoId) {
      return;
    }
    const { data: video } = await dataProvider.getOne('videos', {
      id: videoId,
    });
    setVideo(video);
    if (video.subtitles.length > 0) {
      setSelectedSubtitle(video.subtitles[0]);
    }
  };

  const handleLoadedData = () => {
    if (!videoHtmlElement) {
      return;
    }
    setVideoDuration(videoHtmlElement.duration);
  };

  const handleSelectChange = (e: any) => {
    const subtitleId = e.target.value;
    const subtitle = video?.subtitles?.find(
      (subtitle: SubtitleType) => subtitle.id === subtitleId
    );
    if (subtitle) {
      setSelectedSubtitle(subtitle);
    }
  };

  const scrollToBlock = (e: any) => {
    if (!timelinesRef.current) {
      return;
    }
    const cursorPosition = e.target.currentTime * widthPerSecond;
    const isCursorHiddenLeftByTimelines =
      timelinesRef.current.scrollLeft > cursorPosition;
    const isCursorHiddenRightByTimelines =
      timelinesRef.current.scrollLeft + timelinesRef.current.offsetWidth <
      cursorPosition + 100;
    if (isCursorHiddenLeftByTimelines || isCursorHiddenRightByTimelines) {
      timelinesRef.current.scrollTo({
        left: cursorPosition - timelinesRef.current.offsetWidth + 200,
        behavior: 'smooth',
      });
    }
  };

  if (!video) {
    return (
      <div style={{ ...StyleSheet.container, alignItems: 'center' }}>
        <LoadingAnimation stroke="#ffffff" />
        <div style={{ marginTop: 16, color: '#ffffff' }}>Loading video...</div>
      </div>
    );
  }

  return (
    <>
      <header style={StyleSheet.header}>
        <div style={StyleSheet.headerComponent}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
            <TranslateIcon style={{ fontSize: 13 }} />
            Language
          </div>
          <select
            style={StyleSheet.select}
            onChange={handleSelectChange}
            value={selectedSubtitle?.id}
          >
            {video?.subtitles?.map((subtitle: SubtitleType) => (
              <option key={subtitle.id} value={subtitle.id}>
                {LOCALE_HUMANIZED[subtitle.locale]
                  ? LOCALE_HUMANIZED[subtitle.locale]
                  : subtitle.locale}
              </option>
            ))}
          </select>
        </div>
      </header>
      <div style={StyleSheet.container} ref={containerRef}>
        <div style={StyleSheet.editor}>
          <SubtitleScript />
          <Video />
        </div>
        <Controllers />
        <div style={StyleSheet.timelines} ref={timelinesRef}>
          <div
            style={{
              width: widthPerSecond * (videoDuration || 0),
              margin: 16,
            }}
          >
            <SubtitleTimeline />
            <VideoTimeline />
          </div>
        </div>
      </div>
    </>
  );
};

const StyleSheet: any = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerComponent: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    color: '#666',
    fontSize: 11,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '30vh',
    width: '100%',
    maxWidth: document.body.clientWidth - 300,
    marginTop: 12,
    borderRadius: 12,
    backgroundColor: '#F7F7F9',
    border: '1px solid #E7E7E9',
    overflow: 'hidden',
  },
  select: {
    maxWidth: 200,
    marginLeft: 8,
    padding: '4px 8px',
    borderRadius: 4,
    border: '1px solid #E7E7E9',
    backgroundColor: '#fbfbfc',
    fontSize: 13,
    fontWeight: 500,
    color: '#333',
    cursor: 'pointer',
  },
  editor: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '12px 24px',
    maxWidth: 1600,
  },
  timelines: {
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    overflowX: 'scroll',
  },
};

export default ({ videoId }: { videoId: string }) => (
  <SubtitleStudioProvider>
    <SubtitlesStudio videoId={videoId} />
  </SubtitleStudioProvider>
);
