import React from 'react';
import { BadgeType } from 'types/badge';

import { BorderStyle, FontStyle, SpacingStyle } from '@styles/variables';

type BadgePreviewProps = {
  badge: BadgeType;
};
const BadgePreview = ({ badge }: BadgePreviewProps) => {
  return (
    <div
      style={{
        ...styles.container,
        background: `linear-gradient(to bottom, ${badge.color1} 0%, ${badge.color2} 65%, ${badge.color3} 100%)`,
      }}
    >
      <img src={badge.imageUrl} alt={badge.nameEN} style={styles.image} />
      <div style={styles.name}>{badge.nameEN}</div>
      <div style={styles.description}>{badge.descriptionEN}</div>
    </div>
  );
};

const styles: any = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: BorderStyle.Radius.normal,
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
    maxWidth: 220,
    height: 340,
  },
  image: {
    width: '50%',
    borderRadius: 100,
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
  },
  name: {
    fontSize: FontStyle.sizeBig,
    fontWeight: 700,
    margin: `${SpacingStyle.big}px 0 ${SpacingStyle.normal}px`,
    textAlign: 'center',
  },
  description: {
    textAlign: 'center',
    fontSize: FontStyle.sizeSmall,
    lineHeight: 1.1,
    fontWeight: 500,
    padding: `0 ${SpacingStyle.normal}px`,
  },
};

export default BadgePreview;
