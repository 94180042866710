import { MissionType } from './mission';
import { UserType } from './user';

export enum TopicTypes {
  Community = 'COMMUNITY',
  Cause = 'CAUSE',
  Mission = 'MISSION',
  DirectMessage = 'DIRECT_MESSAGE',
}

export type TopicType = {
  id: string;
  nameEN: string;
  nameENHashtagged: string;
  nameFR: string;
  nameFRHashtagged: string;
  descriptionFR: string;
  descriptionEN: string;
  isPublic: boolean;
  thumbnailUrl: string;
  type: TopicTypes;

  missionId?: MissionType['id'];

  createdAt: string;
  updatedAt: string;

  commentsCount?: number;
  mission?: MissionType;
  users?: UserType[];
};
