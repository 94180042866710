/**
 * [VideoScript] Create view
 */
import * as React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import CustomReferenceInput from '../../components/inputs/custom_reference_input';

export const VideoScriptCreate = () => (
  <Create>
    <SimpleForm>
      <CustomReferenceInput
        source="actionId"
        reference="actions"
        queryKey="descriptionEN"
        disabled
      />
      <TextInput source="script" fullWidth multiline />
    </SimpleForm>
  </Create>
);
