import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';

export const AdminUserCampaignShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="username" />
    </SimpleShowLayout>
  </Show>
);
