import * as React from 'react';
import {
  BooleanField,
  CreateButton,
  Datagrid,
  DateField,
  ImageField,
  List,
  TextField,
} from 'react-admin';

import { useAdminRoles } from '@hooks/useAdminRoles';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const ListActions = () => {
  const canCreate = useAdminRoles(['super_admin', 'campaign_admin', 'product']);
  return (
    <ResourceTitleActionBar
      mode="list"
      actions={canCreate ? <CreateButton variant="outlined" /> : undefined}
    />
  );
};

export const InAppNotificationList = () => (
  <List
    sort={{ field: 'createdAt', order: 'DESC' }}
    pagination={false}
    exporter={false}
    actions={<ListActions />}
    emptyWhileLoading
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <ImageField
        source="imageUrl"
        label="Image"
        sx={{ '& img': { maxWidth: 50, maxHeight: 50, borderRadius: 100 } }}
      />
      <TextField
        source="badgeEmoji"
        label="Badge"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 30,
          height: 30,
          backgroundColor: '#FBE4E4',
          borderRadius: 100,
        }}
      />
      <TextField source="titleEN" label="Title EN" />
      <TextField source="entityType" label="Type" />
      <BooleanField source="displayAsToast" />
      <DateField source="createdAt" showTime />
    </Datagrid>
  </List>
);
