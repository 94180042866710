import * as React from 'react';
import {
  DateField,
  EditButton,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

import { useAdminRoles } from '@hooks/useAdminRoles';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const ShowActions = () => {
  const canEdit = useAdminRoles(['super_admin', 'campaign_admin', 'campaign_manager']);
  return (
    <ResourceTitleActionBar
      mode="show"
      actions={canEdit && <EditButton label="Edit" variant="outlined" />}
    />
  );
};

export const SubtitleShow = () => (
  <Show actions={<ShowActions />}>
    <SimpleShowLayout>
      <ReferenceField source="videoId" reference="videos" link="show" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <TextField source="locale" />
      <TextField source="text" component="pre" style={{ whiteSpace: 'pre-wrap' }} />
    </SimpleShowLayout>
  </Show>
);
