import React from 'react';
import {
  useGetRecordRepresentation,
  useRecordContext,
  useResourceContext,
} from 'react-admin';
import { Box, useMediaQuery } from '@mui/material';

import { Colors, FontStyle, SpacingStyle } from '@styles/variables';

type ResourceTitleActionBarProps = {
  mode: 'list' | 'create' | 'edit' | 'show';
  actions?:
    | false
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined
    | null;
  title?:
    | false
    | string
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
  resourceName?: string;
};
const ResourceTitleActionBar = ({
  mode,
  title,
  resourceName,
  actions,
}: ResourceTitleActionBarProps) => {
  const record = useRecordContext();
  const resource = useResourceContext();
  const isMediumScreen = useMediaQuery('(max-width: 900px)');

  const getRecordRepresentation = useGetRecordRepresentation(resource);
  return (
    <Box
      style={{
        ...styles.container,
        ...(isMediumScreen ? styles.containerMedium : {}),
      }}
    >
      <div style={styles.title}>
        {title ? (
          title
        ) : (
          <>
            {resourceName || resource.replace(/_/g, ' ')}
            {record && ' · '}
            {record && (getRecordRepresentation(record) || '')}
            {['create', 'edit'].includes(mode) && <div style={styles.mode}>{mode}</div>}
          </>
        )}
      </div>
      {actions && <div style={styles.actions}>{actions}</div>}
    </Box>
  );
};

const styles: any = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: SpacingStyle[32],
    padding: `0 ${SpacingStyle[32]}px`,
  },
  containerMedium: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: SpacingStyle[8],
    fontSize: FontStyle.sizeBig,
    fontWeight: 600,
    color: Colors.OffBlack.primary,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: SpacingStyle.small,
  },
  mode: {
    color: Colors.Grey[400],
  },
};

export default ResourceTitleActionBar;
