import * as React from 'react';
import {
  BooleanField,
  CreateButton,
  Datagrid,
  Filter,
  List,
  SearchInput,
  TextField,
} from 'react-admin';

import { useAdminRoles } from '@hooks/useAdminRoles';
import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const ListActions = () => {
  const canCreate = useAdminRoles(['super_admin', 'product']);
  return (
    <ResourceTitleActionBar
      mode="list"
      actions={
        <>
          <Filter>
            <SearchInput source="name" alwaysOn />
          </Filter>
          {canCreate && <CreateButton variant="outlined" />}
        </>
      }
    />
  );
};

export const FeatureFlagList = () => (
  <List
    sort={{ field: 'name', order: 'ASC' }}
    perPage={PER_PAGE}
    pagination={<PER_PAGE_OPTIONS />}
    actions={<ListActions />}
    exporter={false}
    emptyWhileLoading
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="name" />
      <TextField source="description" />
      <BooleanField source="enabledAt" looseValue label="Enabled" />
    </Datagrid>
  </List>
);
