import * as React from 'react';
import {
  SimpleShowLayout,
  Show,
  DateField,
  ReferenceField,
  TextField,
  EditButton,
} from 'react-admin';

import { useAdminRoles } from '@hooks/useAdminRoles';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const ShowActions = () => {
  const canEdit = useAdminRoles(['super_admin', 'campaign_admin', 'product']);
  return (
    <ResourceTitleActionBar
      mode="show"
      actions={canEdit && <EditButton label="Edit" variant="outlined" />}
    />
  );
};

export const UserProfilePromptShow = () => (
  <Show actions={<ShowActions />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="userId" reference="users" fullWidth link="show" />
      <ReferenceField source="promptId" reference="profile_prompts" link="show" />
      <TextField source="answer" />
      <DateField
        source="createdAt"
        showTime
        label={`Created at (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
      />
      <DateField
        source="updatedAt"
        showTime
        label={`Updated at (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}
      />
    </SimpleShowLayout>
  </Show>
);
