import React, { useMemo } from 'react';
import {
  Button,
  DateField,
  EditButton,
  Labeled,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useDataProvider,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import LockIcon from '@mui/icons-material/Lock';
import { AdminUserType } from 'types/admin_user';
import { AdminUserRole } from 'types/admin_user_role.d';

import { useAdminRoles } from '@hooks/useAdminRoles';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import Callout from '@components/callout';

import { AdminUserCampaignsList } from '../admin_users_campaigns/components/admin_users_campaigns_list';

import { AdminUserRoleField } from './components/roles';

const ShowActions = () => {
  const isSuperAdmin = useAdminRoles('super_admin');
  const isCampaignAdmin = useAdminRoles('campaign_manager');
  const dataProvider = useDataProvider();
  const adminUser: AdminUserType = useRecordContext();
  const refresh = useRefresh();

  const handleLogout = async () => {
    try {
      await dataProvider.logoutAdminUser({ adminUserId: adminUser.id });
      refresh();
      window.confirm('User logged out.');
    } catch (error: any) {
      throw new Error(error);
    }
  };

  /**
   * Check if the current user can edit the admin user
   * @returns {boolean}
   */
  const canEdit = useMemo(() => {
    if (isSuperAdmin) {
      return true;
    }

    if (isCampaignAdmin) {
      return (
        adminUser.role === AdminUserRole.campaign_manager ||
        adminUser.role === AdminUserRole.read_only
      );
    }

    return false;
  }, [isSuperAdmin, isCampaignAdmin, adminUser?.role]);

  return (
    <ResourceTitleActionBar
      mode="show"
      actions={
        canEdit ? (
          <>
            <EditButton variant="outlined" />
            <Button
              variant="outlined"
              color="primary"
              label={'Log user out'}
              startIcon={<LockIcon />}
              onClick={handleLogout}
            />
          </>
        ) : undefined
      }
    />
  );
};

export const AdminUserShow = () => (
  <Show actions={<ShowActions />}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <TextField source="username" />
        <Labeled label="Role">
          <AdminUserRoleField />
        </Labeled>

        <DateField source="lastConnectedAt" showTime />
      </Tab>
      <Tab label="Campaigns">
        <AdminUserCampaignsList />
        <Callout backgroundColor="grey">
          To add or remove this admin from a campaign, go in the Campaign page you want
          and open the "Managers" tabs.
        </Callout>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
