import * as React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import ResourceTitleActionBar from 'src/components/resource_title_action_bar';

const redirectAfterEdit = (basePath: any, id: any, data: any) => `causes/${data.id}/show`;

const EditActions = <ResourceTitleActionBar mode="edit" />;

export const CauseEdit = () => (
  <Edit redirect={redirectAfterEdit} mutationMode="pessimistic" actions={EditActions}>
    <SimpleForm>
      <TextInput disabled source="id" fullWidth />
      <TextInput source="name" fullWidth />
      <TextInput source="emoji" fullWidth />
      <TextInput source="descriptionFR" fullWidth />
      <TextInput source="descriptionEN" fullWidth />
    </SimpleForm>
  </Edit>
);
