import * as React from 'react';
import { SimpleForm, Create, required } from 'react-admin';

import CustomReferenceInput from '@components/inputs/custom_reference_input';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const CreateActions = () => <ResourceTitleActionBar mode="create" />;

const redirectAfterCreate = (_basePath: any, _id: any, data: any) =>
  `missions/${data.missionId}/show`;

export const MissionCauseCreate = () => (
  <Create actions={<CreateActions />} redirect={redirectAfterCreate}>
    <SimpleForm>
      <CustomReferenceInput
        source="missionId"
        reference="missions"
        queryKey="name"
        sort={{ field: 'titleEN', order: 'ASC' }}
      />
      <CustomReferenceInput
        source="causeId"
        reference="causes"
        validate={[required()]}
        queryKey="name"
        sort={{ field: 'name', order: 'ASC' }}
      />
    </SimpleForm>
  </Create>
);
