import React from 'react';
import { useRedirect } from 'react-admin';
import { Colors, FontStyle, SpacingStyle } from 'src/styles/variables';
import { CauseType } from 'types/cause';

type CauseChipProps = {
  cause: CauseType;
  clickable?: boolean | 'campaigns' | 'missions' | string;
};
const CauseChip = ({ cause, clickable }: CauseChipProps) => {
  const redirect = useRedirect();

  const onClickHandler = () => {
    if (!clickable) {
      return;
    }
    let redirectTo = `/causes/${cause.id}/show`;
    if (clickable === 'campaigns' || clickable === 'missions') {
      redirectTo += '/' + clickable;
    }
    if (typeof clickable === 'string') {
      redirect(clickable);
    } else {
      redirect(redirectTo);
    }
  };

  return (
    <div
      style={{
        ...styles.container,
        ...(clickable ? { cursor: 'pointer' } : null),
      }}
      onClick={onClickHandler}
    >
      {cause.emoji} {cause.name}
    </div>
  );
};

const styles: any = {
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: SpacingStyle[4],
    padding: `${SpacingStyle[2]}px ${SpacingStyle[8]}px`,
    color: Colors.Black.primary,
    fontSize: FontStyle.sizeVerySmall,
    fontWeight: 600,
    whiteSpace: 'nowrap',
    backgroundColor: Colors.White.primary,
    backgroundFilter: 'blur(2px)',
  },
};

export default CauseChip;
