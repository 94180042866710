import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecordContext } from 'react-admin';
import { Card, CardContent, CardHeader } from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import {
  Bar,
  BarChart,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import moment from 'moment-timezone';
import { ActionType } from 'types/action';
import dataProvider from 'src/dataProvider';

import Callout from '@components/callout';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

const AnalyticsCard = () => {
  const action: ActionType = useRecordContext();

  const [publicVisitorsTotal, setPublicVisitorsTotal] = useState<number>(0);
  const [usersActionsTotal, setUsersActionsTotal] = useState<number>(0);
  const [graphData, setGraphData] = useState<any>([]);
  const [groupBy, setGroupBy] = useState<'hour' | 'day' | 'week' | 'month'>('day');

  /**
   * Set the group by format based on groupBy
   */
  const groupByFormat = useMemo(() => {
    let format = 'YYYY-MM-MM';
    switch (groupBy) {
      case 'hour':
        format = 'YYYY-MM-DD-HH:00';
        break;
      case 'day':
        format = 'YYYY-MM-DD';
        break;
      case 'week':
        format = 'YYYY-MM-WW';
        break;
      default:
        format = 'YYYY-MM';
        break;
    }
    return format;
  }, [groupBy]);

  /**
   * Fetch action analytics
   * and set the graph data
   */
  const fetchActionAnalytics = useCallback(async () => {
    const { data } = await dataProvider.getActionAnalytics({
      actionId: action.id,
      groupBy,
    });
    const { publicVisitors, usersActions, publicVisitorsTotal, usersActionsTotal } = data;

    const graphDataTmp = [];
    const from = moment(action.publishedAt).startOf('day');
    while (from.isBefore(moment())) {
      const name = from.format(groupByFormat);
      graphDataTmp.push({
        name,
        universalLink: (publicVisitors && publicVisitors[name]) || 0,
        inApp: (usersActions && usersActions[name]) || 0,
      });
      from.add(1, groupBy);
    }

    setPublicVisitorsTotal(publicVisitorsTotal || 0);
    setUsersActionsTotal(usersActionsTotal || 0);
    setGraphData(graphDataTmp);
  }, [action, groupBy]);

  useEffect(() => {
    fetchActionAnalytics();
  }, [action.id, groupBy]);

  if (!action.id) {
    return <></>;
  }

  if (!action.publishedAt || moment(action.publishedAt).isAfter(moment())) {
    return <></>;
  }

  return (
    <Card
      style={{
        gridColumn: 'span 2',
      }}
    >
      <CardHeader title="Analytics" avatar={<BarChartIcon />} />
      <CardContent
        style={{
          width: '100%',
          height: 600,
        }}
      >
        <Callout emoji="🟰" backgroundColor="grey">
          In total, <strong>{publicVisitorsTotal + usersActionsTotal}</strong> people have
          completed this action:
          <br />– 📱 <strong>{usersActionsTotal}</strong> from the mobile app,
          <br />– 🔗 <strong>{publicVisitorsTotal}</strong> from the universal link.
          <p>
            The objective is <strong>{action.usersObjective}</strong> people.
          </p>
        </Callout>
        <div style={styles.groupByContainer}>
          <div style={styles.groupByLabel}>group by</div>
          <div style={styles.groupBys}>
            <div
              style={{
                ...styles.groupByButton,
                ...(groupBy === 'hour' ? styles.groupByButtonSelected : {}),
              }}
              onClick={() => setGroupBy('hour')}
            >
              hour
            </div>
            <div
              style={{
                ...styles.groupByButton,
                ...(groupBy === 'day' ? styles.groupByButtonSelected : {}),
              }}
              onClick={() => setGroupBy('day')}
            >
              day
            </div>
            <div
              style={{
                ...styles.groupByButton,
                ...(groupBy === 'week' ? styles.groupByButtonSelected : {}),
              }}
              onClick={() => setGroupBy('week')}
            >
              week
            </div>
            <div
              style={{
                ...styles.groupByButton,
                ...(groupBy === 'month' ? styles.groupByButtonSelected : {}),
              }}
              onClick={() => setGroupBy('month')}
            >
              month
            </div>
          </div>
        </div>
        <ResponsiveContainer height={400}>
          <BarChart data={graphData}>
            <XAxis
              dataKey="name"
              interval="preserveStartEnd"
              style={{
                fontSize: FontStyle.sizeSmall,
              }}
              minTickGap={1}
            />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="inApp" fill={Colors.Magenta[600]} stackId={0} />
            <Bar dataKey="universalLink" stackId={0} fill={Colors.Magenta[1300]} />
            <ReferenceLine
              y={action.usersObjective}
              stroke={Colors.Grey[300]}
              strokeDasharray="2 2"
              ifOverflow="extendDomain"
            />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

const styles: any = {
  groupByContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: SpacingStyle.normal,
  },
  groupByLabel: {
    display: 'flex',
    alignItems: 'center',
    marginRight: SpacingStyle.small,
    fontSize: FontStyle.sizeVerySmall,
    color: Colors.Grey.primary,
  },
  groupBys: {
    display: 'flex',
    borderRadius: BorderStyle.Radius.small,
    overflow: 'hidden',
    border: `1px solid ${Colors.Grey[200]}`,
  },
  groupByButton: {
    padding: '2px 8px',
    cursor: 'pointer',
    fontSize: FontStyle.sizeVerySmall,
    borderRight: `.5px solid ${Colors.Grey[200]}`,
  },
  groupByButtonSelected: {
    backgroundColor: Colors.Grey[200],
  },
};

export default AnalyticsCard;
