import React, { useState } from 'react';
import { useRefresh } from 'react-admin';
import CloseIcon from '@mui/icons-material/Close';
import dataProvider from 'src/dataProvider';

import { useAdminRoles } from '@hooks/useAdminRoles';

export const TagStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '2px 6px',
  fontSize: 13,
  fontWeight: 600,
  color: 'rgba(0,0,0,.7)',
  borderRadius: 4,
  cursor: 'default',
};

type CRMTagChipProps = {
  tag: any;
  style?: any;
  canBeDelete?: boolean;
};
const CRMTagChip = ({ tag, style, canBeDelete }: CRMTagChipProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const refresh = useRefresh();
  const isReadOnly = useAdminRoles('read_only');

  const deleteTag = async (tag: any) => {
    if (isLoading) {
      return;
    }
    if (!tag || !tag.crmTagTaggableId) {
      window.alert('Can delete this tag. It has no "crmTagTaggableId" property.');
      return;
    }
    setIsLoading(true);
    await dataProvider.delete('crm_tags_taggables', {
      id: tag.crmTagTaggableId,
    });
    refresh();
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
      }}
    >
      <div style={{ ...TagStyle, ...style, backgroundColor: tag.color }}>
        {tag.name}
        {!isReadOnly && canBeDelete && (
          <CloseIcon
            style={{
              height: TagStyle.fontSize,
              width: TagStyle.fontSize,
              marginLeft: 8,
              cursor: 'pointer',
            }}
            htmlColor="rgba(0,0,0,.3)"
            onClick={() => deleteTag(tag)}
          />
        )}
      </div>
    </div>
  );
};

export default CRMTagChip;
