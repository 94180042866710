import * as React from 'react';
import {
  DateTimeInput,
  DeleteButton,
  Edit,
  ImageField,
  ImageInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from 'react-admin';

import { useAdminRoles } from '@hooks/useAdminRoles';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import LocaleInput from '@components/inputs/locale_input';
import TimezoneInput from '@components/inputs/timezone_input';
import UrlInput from '@components/inputs/url_input';

const redirectAfterEdit = (basePath: any, id: any, data: any) => `users/${data.id}/show`;

const EditActions = <ResourceTitleActionBar mode="edit" />;

const UserEditToolbar = ({ ...props }) => {
  const isReadOnly = useAdminRoles('read_only');
  const isSuperAdmin = useAdminRoles('super_admin');
  return (
    <Toolbar {...props}>
      {!isReadOnly && <SaveButton />}
      {isSuperAdmin && <DeleteButton />}
    </Toolbar>
  );
};

export const UserEdit = () => (
  <Edit redirect={redirectAfterEdit} mutationMode="pessimistic" actions={EditActions}>
    <SimpleForm toolbar={<UserEditToolbar />}>
      <TextInput source="username" fullWidth required />
      <TextInput source="firstname" fullWidth />
      <TextInput source="lastname" fullWidth />
      <TextInput source="phoneNumber" fullWidth />
      <ImageField source="avatarUrl" title="title" />
      <ImageInput source="picture" label="Avatar" accept="image/*" maxSize={5000000}>
        <ImageField source="src" title="title" />
      </ImageInput>
      <LocaleInput source="locale" label="Locale" required />
      <TimezoneInput source="timezone" label="Timezone" required />
      <hr style={{ width: '100%' }} />
      <TextInput
        source="bio"
        fullWidth
        multiline
        placeholder="255 char max"
        inputProps={{ maxLength: 255 }}
      />
      <TextInput
        source="occupation"
        fullWidth
        multiline
        placeholder="80 char max"
        inputProps={{ maxLength: 80 }}
      />
      <UrlInput
        source="facebookHandle"
        fullWidth
        label="Facebook URL"
        inputProps={{ maxLength: 255 }}
      />
      <UrlInput
        source="instagramHandle"
        fullWidth
        label="Instagram URL"
        inputProps={{ maxLength: 255 }}
      />
      <UrlInput
        source="linkedinHandle"
        fullWidth
        label="LinkedIn URL"
        inputProps={{ maxLength: 255 }}
      />
      <UrlInput
        source="snapchatHandle"
        fullWidth
        label="Snapchat URL"
        inputProps={{ maxLength: 255 }}
      />
      <UrlInput
        source="tiktokHandle"
        fullWidth
        label="Tiktok URL"
        inputProps={{ maxLength: 255 }}
      />
      <UrlInput
        source="twitterHandle"
        fullWidth
        label="Twitter URL"
        inputProps={{ maxLength: 255 }}
      />
      <UrlInput
        source="websiteUrl"
        fullWidth
        label="Website URL"
        inputProps={{ maxLength: 255 }}
      />
      <hr style={{ width: '100%' }} />
      <DateTimeInput source="spyDetectedAt" label="Mark as spy" />
    </SimpleForm>
  </Edit>
);
