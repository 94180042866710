import React, { useMemo, useState } from 'react';
import CampaignStatusChip from './campaign_status';
import { CampaignType } from 'types/campaign';
import { Colors } from 'src/styles/variables';
import { compressedImageUrl } from 'src/modules/cloudinary';

type CampaignBlockProps = {
  campaign?: CampaignType;
  clickable?: boolean | string;
  withStatus?: boolean;
  style?: any;
  label?: string;
};
const CampaignBlock = ({
  campaign,
  clickable = false,
  withStatus = false,
  style,
}: CampaignBlockProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const imageUrl = useMemo(() => {
    if (!campaign) {
      return;
    }
    return compressedImageUrl(campaign.imageUrl, { width: 100 });
  }, [campaign?.imageUrl]);

  const handleOnClick = () => {
    if (!clickable || !campaign) {
      return;
    }
    if (typeof clickable === 'string') {
      window.location.href = clickable;
      return;
    }
    window.location.href = `#/campaigns/${campaign.id}/show`;
  };

  if (!campaign) {
    return <></>;
  }

  return (
    <a
      style={{
        ...StyleSheet.container,
        cursor: clickable ? 'pointer' : 'inherit',
        ...style,
      }}
      onClick={handleOnClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        style={{
          ...StyleSheet.image,
          filter: !campaign.livedAt ? 'grayscale(1) brightness(1.5)' : null,
        }}
        src={imageUrl}
      />
      <div
        style={{
          ...StyleSheet.name,
          ...(isHovered && clickable ? StyleSheet.containerHovered : undefined),
          color: !campaign.livedAt
            ? Colors.Grey.primary
            : new Date(campaign.livedAt) > new Date()
              ? Colors.Orange.primary
              : Colors.OffBlack.primary,
        }}
      >
        {campaign.name}
      </div>
      {withStatus && <CampaignStatusChip campaign={campaign} />}
    </a>
  );
};

const StyleSheet: any = {
  container: {
    display: 'inline-flex',
    gap: 12,
    alignItems: 'center',
    textDecoration: 'none',
  },
  containerHovered: {
    textDecoration: 'underline',
  },
  image: {
    maxWidth: 50,
    maxHeight: 50,
    borderRadius: 100,
    boxShadow: '0px 0px 0 1px rgba(0,0,0,.05)',
  },
  name: {
    fontWeight: 600,
    color: 'black',
  },
};

export default CampaignBlock;
