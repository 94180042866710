import * as React from 'react';
import {
  Create,
  DateInput,
  ImageField,
  ImageInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';

import Callout from '@components/callout';
import Grammarly from '@components/grammarly';
import UrlInput from '@components/inputs/url_input';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

const redirectAfterCreate = (_basePath: any, _id: any, data: any) =>
  `campaigns/${data.id}/show`;

const CreateActions = () => <ResourceTitleActionBar mode="create" />;

export const CampaignCreate = ({ ...props }) => (
  <Create actions={<CreateActions />} redirect={redirectAfterCreate} {...props}>
    <SimpleForm>
      <TextInput source="name" fullWidth validate={[required()]} />
      <Grammarly>
        <TextInput
          label="English description"
          source="descriptionEN"
          fullWidth
          multiline
          validate={[required()]}
        />
      </Grammarly>
      <TextInput
        label="French description"
        source="descriptionFR"
        fullWidth
        multiline
        validate={[required()]}
      />
      <ImageInput
        source="picture"
        accept="image/*"
        maxSize={5000000}
        validate={[required()]}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <br />
      <Callout backgroundColor="grey" emoji="👇">
        When a date is set, the campaign will be visible to users starting from that date.
        <br />
        When no date, the campaign will be hidden.
      </Callout>
      <DateInput source="livedAt" fullWidth />
      <br />
      <h4>Socials</h4>
      <UrlInput source="websiteUrl" label="Website URL" fullWidth />
      <UrlInput source="instagramHandle" label="Instagram URL" fullWidth />
      <UrlInput source="tiktokHandle" label="TikTok URL" fullWidth />
      <UrlInput source="facebookHandle" label="Facebook URL" fullWidth />
      <UrlInput source="twitterHandle" label="Twitter (X) URL" fullWidth />
      <UrlInput source="linkedinHandle" label="LinkedIn URL" fullWidth />
      <UrlInput source="snapchatHandle" label="Snapchat URL" fullWidth />
    </SimpleForm>
  </Create>
);
