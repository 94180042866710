import * as React from 'react';
import {
  ArrayField,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  UrlField,
  useRecordContext,
} from 'react-admin';
import { CRMOrganizationType } from 'types/crm_organization';
import { CRMPersonType } from 'types/crm_person';

import { useAdminRoles } from '@hooks/useAdminRoles';
import CRMAddTagDialog from '@components/crm/add_tag_dialog';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import CRMLinkOrganizationPerson from '@components/crm/link_organization_person_dialog';
import CRMTag from '@models/crm/crm_tags/components/chip';
import CRMOrganizationCard from '@models/crm/crm_organizations/components/card';

import { CRMPersonImage } from './components/image';

const ShowActions = () => {
  const canEdit = useAdminRoles(['super_admin', 'campaign_admin', 'campaign_manager']);
  return (
    <ResourceTitleActionBar
      mode="show"
      resourceName="Persons"
      actions={canEdit && <EditButton variant="outlined" />}
    />
  );
};

export const CRMPersonShowSummary = () => {
  const record: CRMPersonType = useRecordContext();
  return (
    <TabbedShowLayout>
      <Tab label="Summary">
        <CRMPersonImage width={100} />
        <FunctionField
          label="Name"
          render={(record: any) =>
            `${record.firstname || ''} ${record.lastname || ''}`.trim() ||
            'No firstname/lastname'
          }
        />
        <TextField source="email" fullWidth />
        <TextField source="gender" fullWidth />
        <TextField source="location" fullWidth />
        <DateField source="createdAt" fullWidth />

        <TextField source="organizationName" />
        <TextField source="jobTitle" />

        <hr style={{ width: '100%', height: 1 }} />
        <TextField source="notes" fullWidth />
        <FunctionField
          label="Tags"
          render={(record: any) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {record.crmTags?.map((tag: any) => (
                <CRMTag key={tag.id} tag={tag} style={{ marginRight: 8 }} canBeDelete />
              ))}
              <CRMAddTagDialog crmPersonId={record.id} />
            </div>
          )}
        />
        <hr style={{ width: '100%', height: 1 }} />

        <UrlField source="facebookUrl" />
        <UrlField source="instagramUrl" />
        <UrlField source="linkedinUrl" />
        <UrlField source="threadUrl" />
        <UrlField source="tiktokUrl" />
        <UrlField source="twitterUrl" />
      </Tab>
      <Tab label="Organizations">
        <CRMLinkOrganizationPerson crmPersonId={record?.id} />
        <ArrayField source="crmOrganizations" label="List">
          <Datagrid bulkActionButtons={false}>
            <FunctionField
              render={(record: CRMOrganizationType) => (
                <CRMOrganizationCard crmOrganization={record} ctaLabel="show" />
              )}
            />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  );
};
export const CRMPersonShow = () => (
  <Show actions={<ShowActions />}>
    <CRMPersonShowSummary />
  </Show>
);
