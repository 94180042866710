import * as React from 'react';
import { Create, SimpleForm, TextInput, required } from 'react-admin';

import CustomReferenceInput from '@components/inputs/custom_reference_input';

const redirectAfterCreate = (basePath: any, id: any, data: any) =>
  `actions/${data.actionId}/show/cta`;

export const ActionCtaGoogleMapsReviewCreate = () => (
  <Create redirect={redirectAfterCreate}>
    <SimpleForm>
      <CustomReferenceInput
        source="actionId"
        reference="actions"
        queryKey="descriptionEN"
        validate={[required()]}
        disabled
      />
      <TextInput source="googleMapsUrl" fullWidth required />
      <TextInput source="message1" required multiline fullWidth />
      <TextInput source="message2" multiline fullWidth />
      <TextInput source="message3" multiline fullWidth />
      <TextInput source="message4" multiline fullWidth />
      <TextInput source="message5" multiline fullWidth />
      <TextInput source="message6" multiline fullWidth />
      <TextInput source="message7" multiline fullWidth />
      <TextInput source="message8" multiline fullWidth />
      <TextInput source="message9" multiline fullWidth />
      <TextInput source="message10" multiline fullWidth />
      <details>
        <summary>➕ Add more messages...</summary>
        <TextInput source="message11" fullWidth />
        <TextInput source="message12" fullWidth />
        <TextInput source="message13" fullWidth />
        <TextInput source="message14" fullWidth />
        <TextInput source="message15" fullWidth />
        <TextInput source="message16" fullWidth />
        <TextInput source="message17" fullWidth />
        <TextInput source="message18" fullWidth />
        <TextInput source="message19" fullWidth />
        <TextInput source="message20" fullWidth />
      </details>
    </SimpleForm>
  </Create>
);
