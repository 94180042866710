import * as React from 'react';
import {
  Create,
  FormDataConsumer,
  ImageField,
  ImageInput,
  maxLength,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';
import moment from 'moment-timezone';

import Callout from '@components/callout';
import CustomReferenceInput from '@components/inputs/custom_reference_input';
import Grammarly from '@components/grammarly';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

import { NotificationsPreview } from './components/notifications_preview';
import { WinInstructions } from './components/instructions';

export const MAX_LENGTH_SHORT_COPY = 140;

const CreateActions = <ResourceTitleActionBar mode="create" />;

export const WinCreate = () => (
  <Create actions={CreateActions}>
    <SimpleForm>
      <CustomReferenceInput
        source="campaignId"
        reference="campaigns"
        queryKey="name"
        validate={true}
      />
      <FormDataConsumer>
        {({ formData }) => (
          <CustomReferenceInput
            source="actionId"
            reference="actions"
            queryKey="name"
            filters={{
              campaignId: formData.campaignId,
            }}
            disabled={!formData.campaignId}
            helperText="Optional: Select an action to link to this win."
          />
        )}
      </FormDataConsumer>

      <ImageInput
        source="picture"
        accept="image/*"
        maxSize={5000000}
        validate={[required()]}
      >
        <ImageField source="src" title="title" />
      </ImageInput>

      <Grammarly>
        <TextInput
          source="shortCopyEn"
          fullWidth
          label="Short Copy English"
          helperText={`Maximum ${MAX_LENGTH_SHORT_COPY} characters`}
          validate={[required(), maxLength(MAX_LENGTH_SHORT_COPY)]}
          inputProps={{ maxLength: MAX_LENGTH_SHORT_COPY }}
        />
      </Grammarly>
      <TextInput
        source="shortCopyFr"
        fullWidth
        label="Short Copy French"
        helperText={`Maximum ${MAX_LENGTH_SHORT_COPY} characters`}
        validate={[required(), maxLength(MAX_LENGTH_SHORT_COPY)]}
        inputProps={{ maxLength: MAX_LENGTH_SHORT_COPY }}
      />
      <br />
      <NotificationsPreview />

      <br />
      <br />
      <WinInstructions />

      <Grammarly>
        <TextInput
          source="longCopyEn"
          fullWidth
          label="Long Copy English"
          helperText="Format your text using Markdown syntax."
          validate={[required()]}
          multiline
        />
      </Grammarly>
      <TextInput
        source="longCopyFr"
        fullWidth
        label="Long Copy French"
        helperText="Format your text using Markdown syntax."
        validate={[required()]}
        multiline
      />

      <br />
      <br />
      <Callout backgroundColor="red" emoji="🕐">
        You're about to send a <strong>Push Notification</strong> to all users. Make sure
        it's not too late or too early in their day:
        <br />- <strong>Los Angeles:</strong>{' '}
        {moment().tz('America/Los_Angeles').format('h:ma')}
        <br />- <strong>NYC:</strong> {moment().tz('America/New_York').format('h:ma')}
        <br />- <strong>Paris:</strong> {moment().tz('Europe/Paris').format('h:ma')}
      </Callout>
    </SimpleForm>
  </Create>
);
