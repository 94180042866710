import React from 'react';

import { Colors } from '@styles/variables';

interface PepperAvatarProps {
  width?: string;
  height?: string;
}

const PepperAvatar = ({ width = '1em', height = '1em' }: PepperAvatarProps) => {
  return (
    <img
      src="https://res.cloudinary.com/hsiz9ovy1/image/upload/v1706557985/activists/xave_2REkS9.png"
      alt="Pepper"
      style={{
        width,
        height,
        borderRadius: '50%',
        backgroundColor: Colors.Magenta[200],
        overflow: 'hidden',
      }}
    />
  );
};

export default PepperAvatar;
