import React, { useMemo, useState } from 'react';
import { CreateButton, useGetList } from 'react-admin';
import { Card, CardContent, CardHeader } from '@mui/material';
import { CampaignType } from 'types/campaign';
import { MissionType } from 'types/mission';

import { useAdminRoles } from '@hooks/useAdminRoles';
import ResourceTitleActionBar from '@components/resource_title_action_bar';
import LoadingAnimation from '@components/svgs/loading_animation';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

import MissionCard from '../missions/components/card';

import CampaignBlock from './components/campaign_block';

const CampaignListActions = () => {
  const canCreate = useAdminRoles(['super_admin', 'campaign_admin']);
  return (
    <ResourceTitleActionBar
      mode="list"
      actions={canCreate && <CreateButton variant="outlined" />}
    />
  );
};

type GroupedMission = {
  campaign?: CampaignType;
  missions: MissionType[];
};

export const CampaignList = () => {
  const [filterKey, setFilterKey] = useState<string>('');
  const { data: missions, isLoading: areMissionsLoading } = useGetList('missions', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'publishedAt', order: 'ASC' },
    filter: {
      outcome: null,
      publishedAt: ['2023', '2024', '2025', '2026'],
    },
  });
  const { data: campaigns, isLoading: areCampaignsLoading } = useGetList('campaigns', {
    pagination: { page: 1, perPage: 100 },
    filter: {
      livedAt: ['2023', '2024', '2025', '2026'],
    },
  });
  const { data: notLiveCampaigns, isLoading: areOtherCampaignsLoading } = useGetList(
    'campaigns',
    {
      pagination: { page: 1, perPage: 100 },
      filter: {
        livedAt: null,
      },
    }
  );

  const groupedMissions: GroupedMission[] = useMemo(() => {
    if (!campaigns || !missions) {
      return [];
    }
    const groupedMissionsByCampaign: GroupedMission[] = [];
    campaigns.forEach((campaign: CampaignType) => {
      if (campaign.name.toLowerCase().includes(filterKey)) {
        const campaignId = campaign.id;
        const campaignMissions: MissionType[] = missions.filter(
          (mission: MissionType) => mission.campaignId === campaignId
        );
        groupedMissionsByCampaign.push({
          campaign,
          missions: campaignMissions,
        });
      }
    });

    return groupedMissionsByCampaign;
  }, [campaigns, missions, filterKey]);

  const sortByCampaignName = (a: GroupedMission, b: GroupedMission) => {
    const campaignA = a.campaign;
    const campaignB = b.campaign;
    if (!campaignA || !campaignB) {
      return 0;
    }
    return campaignA.name.localeCompare(campaignB.name);
  };

  const sortByMissionPublishedAt = (a: MissionType, b: MissionType) => {
    if (!a.publishedAt || !b.publishedAt) {
      return 0;
    }
    return b.publishedAt.localeCompare(a.publishedAt);
  };

  return (
    <>
      <CampaignListActions />
      <Card>
        <input
          type="text"
          onChange={event => setFilterKey(event.target.value)}
          style={styles.filterInput}
          placeholder="Filter by campaign..."
          autoFocus
        />
      </Card>
      {groupedMissions.sort(sortByCampaignName).map(groupedMission => {
        if (!groupedMission.campaign) {
          return;
        }
        return (
          <Card key={groupedMission.campaign.id}>
            <CardHeader
              title={
                <CampaignBlock
                  campaign={groupedMission.campaign}
                  style={{ marginBottom: SpacingStyle.normal }}
                  clickable
                />
              }
            />
            <CardContent>
              <div style={styles.missions}>
                {groupedMission.missions
                  ?.sort(sortByMissionPublishedAt)
                  .map((mission: MissionType) => (
                    <MissionCard
                      key={mission.id}
                      mission={mission}
                      withStartedAt
                      withUsersCount
                      maxWith={300}
                    />
                  ))}
                {groupedMission.missions.length === 0 && (
                  <div
                    style={{
                      fontSize: FontStyle.sizeSmall,
                      fontStyle: 'italic',
                    }}
                  >
                    no mission.
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        );
      })}
      {(areMissionsLoading || areCampaignsLoading || areOtherCampaignsLoading) && (
        <Card>
          <CardContent
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 300,
            }}
          >
            <LoadingAnimation stroke={Colors.Magenta.primary} />
          </CardContent>
        </Card>
      )}
      {!areOtherCampaignsLoading && notLiveCampaigns && notLiveCampaigns.length > 0 && (
        <Card>
          <CardHeader title="Other campaigns" />
          <CardContent>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: SpacingStyle.normal,
              }}
            >
              {notLiveCampaigns
                .sort((c1, c2) => {
                  if (!c1.name || !c2.name) {
                    return 0;
                  }
                  return c1.name.localeCompare(c2.name);
                })
                .map((campaign: CampaignType) => (
                  <CampaignBlock
                    key={campaign.id}
                    campaign={campaign}
                    withStatus
                    clickable
                  />
                ))}
            </div>
          </CardContent>
        </Card>
      )}
    </>
  );
};

const styles: any = {
  campaign: {
    marginBottom: SpacingStyle.big,
  },
  filterInput: {
    width: '100%',
    padding: SpacingStyle.normal,
    border: `1px solid ${Colors.Grey[400]}`,
    borderRadius: BorderStyle.Radius.normal,
    backgroundColor: Colors.Grey[25],
    fontSize: FontStyle.sizeSmall,
  },
  missions: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: SpacingStyle.big,
  },
};
