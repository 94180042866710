import React from 'react';
import { useRecordContext } from 'react-admin';
import { Card, CardContent, CardHeader } from '@mui/material';
import { PiMedalFill as PointsIcon } from 'react-icons/pi';
import { ActionType } from 'types/action';

import { useAdminRoles } from '@hooks/useAdminRoles';
import { UpdateFieldDialog } from '@components/update_field_dialog';
import TodoChecklist from '@components/todo_checklist';
import { FontStyle } from '@styles/variables';

import { pointValues } from '../points';

const PointsCard = () => {
  const action: ActionType = useRecordContext();
  const isReadOnly = useAdminRoles('read_only');
  const canEdit: boolean = !isReadOnly && !action?.mission?.outcome;

  return (
    <Card>
      <CardHeader title="Points" avatar={<PointsIcon />} />
      <CardContent>
        <TodoChecklist
          checked={!!action?.points}
          label="Points earned by users who complete this action"
          cta={
            canEdit && (
              <UpdateFieldDialog
                resource="actions"
                record={action}
                type="select"
                field="points"
                buttonLabel="Edit"
                buttonVariant="outlined"
                selectOptions={pointValues}
              />
            )
          }
        >
          <>
            <div
              style={{
                fontSize: FontStyle.sizeBig,
                fontWeight: 600,
                lineHeight: 1,
              }}
            >
              {action?.points}
            </div>
            <div style={{ fontSize: FontStyle.sizeSmall, fontWeight: '500' }}>points</div>
          </>
        </TodoChecklist>
      </CardContent>
    </Card>
  );
};

export default PointsCard;
