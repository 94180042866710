import React from 'react';
import { Button } from 'react-admin';
import { getCountryData, TCountryCode } from 'countries-list';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { parsePhoneNumber } from 'libphonenumber-js';
import { ActionType } from 'types/action';

import { useAdminRoles } from '@hooks/useAdminRoles';
import Callout from '@components/callout';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

type ActionCtaPhoneCallsActionCardProps = {
  action: ActionType;
  style: any;
  canEdit?: boolean;
};
const ActionCtaPhoneCallsActionCard = ({
  action,
  style,
  canEdit = true,
}: ActionCtaPhoneCallsActionCardProps) => {
  const isReadOnly = useAdminRoles('read_only');
  if (!action || !action.actionCtaPhoneCalls) {
    return null;
  }

  return (
    <div
      style={{
        justifyContent: 'space-between',
        ...style,
      }}
    >
      {canEdit && (
        <Callout emoji="🤖" backgroundColor="yellow">
          GPT will randomly take one of the following scripts, and generate a{' '}
          <strong>customized version</strong> for each user,
          <br />
          based on the user's value system, their name, and their location (city, region)
        </Callout>
      )}
      {action.actionCtaPhoneCalls.length > 0 &&
        action.actionCtaPhoneCalls.map((phoneCall, index) => (
          <div key={`${phoneCall.id || 'fake-id'}-${index}`}>
            <div
              style={{
                fontSize: FontStyle.sizeVerySmall,
                fontWeight: 600,
                color: Colors.Grey.primary,
              }}
            >
              Script #{index + 1}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: SpacingStyle.normal,
                marginTop: 4,
                marginBottom: SpacingStyle.small,
                paddingTop: SpacingStyle.normal,
                paddingLeft: SpacingStyle.normal,
                paddingRight: SpacingStyle.normal,
                fontSize: FontStyle.sizeVerySmall,
                lineHeight: 1.3,
                whiteSpace: 'pre-wrap',
                backgroundColor: Colors.Background.white,
                border: `1px solid ${Colors.Grey[100]}`,
                borderRadius: BorderStyle.Radius.small,
              }}
            >
              <div>
                <strong>Phone number:</strong>{' '}
                {parsePhoneNumber(phoneCall.phoneNumber).formatInternational()}
              </div>
              <div>
                <strong>Will be made by users from:</strong>{' '}
                {getCountryData(phoneCall?.country as TCountryCode)?.name ||
                  'Any country'}
              </div>
              <div>
                <strong>Script:</strong>
                <div style={{ whiteSpace: 'pre-wrap' }}>
                  {phoneCall.script && phoneCall.script.length > 300
                    ? `${phoneCall.script.slice(0, 300).trim()}...`
                    : phoneCall.script || ''}
                </div>
              </div>

              {!isReadOnly && canEdit && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: SpacingStyle.small,
                  }}
                >
                  <Button
                    label="Edit"
                    endIcon={<EditIcon />}
                    href={`#/action_cta_phone_calls/${phoneCall.id}/edit`}
                    variant="outlined"
                  />
                </div>
              )}
            </div>
          </div>
        ))}

      {!isReadOnly && canEdit && (
        <Button
          label="Create a script for a phone call"
          startIcon={<AddIcon />}
          href={`#/action_cta_phone_calls/create?source={"actionId":"${action.id}"}`}
          style={{
            marginTop: SpacingStyle.small,
            marginBottom: SpacingStyle.normal,
          }}
        />
      )}
    </div>
  );
};

export default ActionCtaPhoneCallsActionCard;
