import React from 'react';
import { FormDataConsumer, useGetOne } from 'react-admin';
import IosNotificationPreview from '../../in_app_notifications/components/ios_notification_preview';
import NotificationCenterPreview from '../../in_app_notifications/components/notification_center_preview';
import ToastNotificationPreview from '../../in_app_notifications/components/toast_notification_preview';

/**
 * Preview of a push notification, an in-app notification and in the Notification Center.
 * @returns
 */
export const NotificationsPreview = () => (
  <FormDataConsumer>
    {({ formData }) => {
      const { data } = useGetOne('campaigns', {
        id: formData.campaignId,
      });
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            maxWidth: 800,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <strong>Push Notification preview:</strong>
            <IosNotificationPreview
              label="🇺🇸"
              title={`🎉 New win for ${data?.name}!`}
              body={formData.shortCopyEn}
            />
            <IosNotificationPreview
              label="🇫🇷"
              title={`🎉 Nouvelle victoire pour ${data?.name} !`}
              body={formData.shortCopyFr}
            />
            <br />
            <strong>In-App Notification preview:</strong>
            <ToastNotificationPreview
              label="🇺🇸"
              emoji="🎉"
              title={`New win for ${data?.name}!`}
              body={formData.shortCopyEn}
            />
            <ToastNotificationPreview
              label="🇫🇷"
              emoji="🎉"
              title={`Nouvelle victoire pour ${data?.name} !`}
              body={formData.shortCopyFr}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <strong>Notification Center preview:</strong>
            <NotificationCenterPreview
              notifications={[
                {
                  badgeEmoji: '🎉',
                  title: `New win for ${data?.name}!`,
                  body: formData.shortCopyEn,
                  imageUrl: data?.imageUrl,
                },
                {
                  badgeEmoji: '🎉',
                  title: `Nouvelle victoire pour ${data?.name} !`,
                  body: formData.shortCopyFr,
                  imageUrl: data?.imageUrl,
                },
              ]}
            />
          </div>
        </div>
      );
    }}
  </FormDataConsumer>
);
