import * as React from 'react';
import {
  Edit,
  ImageField,
  ImageInput,
  maxLength,
  required,
  SimpleForm,
  TextInput,
  useRecordContext,
} from 'react-admin';

import CustomReferenceInput from '@components/inputs/custom_reference_input';
import Grammarly from '@components/grammarly';
import ResourceTitleActionBar from '@components/resource_title_action_bar';

import { MAX_LENGTH_SHORT_COPY } from './create';
import { WinInstructions } from './components/instructions';

const redirectAfterEdit = (basePath: any, id: any, data: any) => `wins/${data.id}/show`;

const EditActions = <ResourceTitleActionBar mode="edit" />;

const WinEditForm = () => {
  const win = useRecordContext();
  return (
    <SimpleForm>
      <CustomReferenceInput
        source="actionId"
        reference="actions"
        queryKey="name"
        filters={{ campaignId: win?.campaignId }}
        disabled={!win?.campaignId}
        helperText="Optional: Select an action to link to this win."
      />
      <ImageField source="imageUrl" fullWidth />
      <ImageInput source="picture" accept="image/*" maxSize={5000000}>
        <ImageField source="src" title="title" />
      </ImageInput>
      <Grammarly>
        <TextInput
          source="shortCopyEn"
          fullWidth
          validate={[required(), maxLength(MAX_LENGTH_SHORT_COPY)]}
          inputProps={{ maxLength: MAX_LENGTH_SHORT_COPY }}
          helperText={`Maximum ${MAX_LENGTH_SHORT_COPY} characters`}
        />
      </Grammarly>
      <TextInput
        source="shortCopyFr"
        fullWidth
        validate={[required(), maxLength(MAX_LENGTH_SHORT_COPY)]}
        inputProps={{ maxLength: MAX_LENGTH_SHORT_COPY }}
        helperText={`Maximum ${MAX_LENGTH_SHORT_COPY} characters`}
      />
      <br />
      <br />
      <WinInstructions />
      <Grammarly>
        <TextInput source="longCopyEn" fullWidth multiline validate={[required()]} />
      </Grammarly>
      <TextInput source="longCopyFr" fullWidth multiline validate={[required()]} />
    </SimpleForm>
  );
};

export const WinEdit = (
  <Edit redirect={redirectAfterEdit} mutationMode="pessimistic" actions={EditActions}>
    <WinEditForm />
  </Edit>
);
