import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import { ActionType } from 'types/action';

import ellipsis from '@services/ellipsis';
import { dateFormatter } from '@services/date';
import { UserIcon } from '@components/icons';
import ActionCTATypeChip from '@components/action_cta_type_chip';
import { BorderStyle, Colors, FontStyle, SpacingStyle } from '@styles/variables';

type ActionCardProps = {
  action: ActionType;
  withCampaign?: boolean;
  withCause?: boolean;
  withMission?: boolean;
};
const ActionCard = ({
  action,
  withCampaign = false,
  withCause = false,
  withMission = false,
}: ActionCardProps) => {
  const [hovered, setHovered] = useState<boolean>(false);

  /**
   * Check if the action is expired
   * @returns {boolean}
   */
  const isExpired = useMemo(
    () =>
      moment(action.publishedAt)
        .add(action.expiresIn, 'days')
        .isBefore(moment(), 'minutes'),
    [action.publishedAt, action.expiresIn]
  );
  /**
   * Calculate the expiration date
   * @returns {Moment}
   */
  const expiresOn = useMemo(
    () => moment(action.publishedAt).add(action.expiresIn, 'days'),
    [action.publishedAt, action.expiresIn]
  );

  /**
   * Go to the campaign page
   */
  const goToCampaign = useCallback(() => {
    if (action.campaignId) {
      window.location.href = `#/campaigns/${action.campaignId}/show`;
    }
  }, [action.campaignId]);

  /**
   * Go to the mission page
   */
  const goToMission = useCallback(() => {
    if (action.missionId) {
      window.location.href = `#/missions/${action.missionId}/show`;
    }
  }, [action.missionId]);

  /**
   * Go to the action page
   */
  const goToAction = useCallback(() => {
    if (action.id) {
      window.location.href = `#/actions/${action.id}/show`;
    }
  }, [action.id]);

  if (!action) {
    return;
  }

  return (
    <div
      key={action.id}
      style={{
        ...styles.action,
        ...(isExpired && styles.actionExpired),
        ...(hovered && styles.actionHovered),
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {action?.priority && action.priority > 1 && (
        <div style={styles.priority}>
          {Array(action.priority)
            .fill('🔥')
            .map((fire, index) => (
              <span key={index}>{fire}</span>
            ))}
        </div>
      )}
      {withCampaign && action.campaignId && (
        <div style={styles.header} onClick={() => goToCampaign()}>
          {action.campaign?.imageUrl && (
            <img
              src={action.campaign?.imageUrl}
              alt={action.campaign?.name}
              style={styles.campaignImage}
            />
          )}
          {action.campaign?.name && (
            <div style={styles.campaignName}>{action.campaign?.name}</div>
          )}
        </div>
      )}
      {withMission && action.missionId && (
        <div style={styles.header} onClick={() => goToMission()}>
          {action.mission?.thumbnailUrl && (
            <img
              src={action.mission?.thumbnailUrl}
              alt={action.mission?.name}
              style={styles.missionImage}
            />
          )}
          {action.mission?.name && (
            <div style={styles.missionName}>
              {ellipsis(action.mission?.name, 50)}
              {!action.mission?.publishedAt && ' (draft)'}
            </div>
          )}
        </div>
      )}
      <div style={styles.content} onClick={() => goToAction()}>
        <div
          style={{
            ...styles.description,
            ...(isExpired && { textDecoration: 'line-through' }),
          }}
        >
          {action.name || action.descriptionEN}
        </div>
        <div
          style={styles.expires}
          title={dateFormatter(expiresOn.format('YYYY-MM-DD HH:mm'))}
        >
          {isExpired ? 'Expired' : `Expires ${expiresOn.fromNow()}`}
        </div>
        {withCause &&
          action.causes &&
          action.causes.length &&
          action.causes.map(cause => (
            <div key={cause.id} style={styles.cause}>
              {cause.emoji} {cause.name}
            </div>
          ))}
        <div style={styles.footer}>
          <div
            style={{
              ...styles.footerMembers,
              color:
                (action.usersCount || 0) >= (action.usersObjective || 0)
                  ? Colors.Red.primary
                  : Colors.Grey[400],
            }}
          >
            <UserIcon size={FontStyle.sizeVerySmall} color={Colors.Grey[400]} />
            {action.usersCount || 0}
            {action.usersObjective && `/${action.usersObjective}`}
          </div>
          <ActionCTATypeChip ctaType={action.ctaType as any} size="small" />
        </div>
      </div>
    </div>
  );
};

const styles: any = {
  action: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.Background.white,
    borderRadius: BorderStyle.Radius.small,
    border: `1px solid ${Colors.Black.transparent.max}`,
    cursor: 'default',
    overflow: 'hidden',
  },
  actionExpired: {
    opacity: 0.5,
  },
  actionHovered: {
    backgroundColor: '#FDFBFD',
    cursor: 'pointer',
  },
  noAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 50,
    marginTop: SpacingStyle.small,
    fontStyle: 'italic',
    fontSize: FontStyle.sizeVeryVerySmall,
    color: Colors.Grey[300],
    textAlign: 'center',
  },
  priority: {
    position: 'absolute',
    top: 0,
    right: SpacingStyle[4],
    fontSize: FontStyle.sizeVerySmall,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: SpacingStyle[4],
    backgroundColor: Colors.Grey[100],
    padding: SpacingStyle[4],
  },
  campaignImage: {
    width: 20,
    height: 20,
    objectFit: 'cover',
    borderRadius: BorderStyle.Radius.big,
  },
  campaignName: {
    fontSize: FontStyle.sizeVerySmall,
    fontWeight: 600,
    color: Colors.OffBlack.primary,
    lineHeight: 1.1,
  },
  missionImage: {
    width: 20,
    height: 20,
    objectFit: 'cover',
    borderRadius: BorderStyle.Radius.small,
  },
  missionName: {
    fontSize: FontStyle.sizeVeryVerySmall,
    fontWeight: 400,
    color: Colors.Grey.primary,
    lineHeight: 1.1,
  },
  expires: {
    fontSize: FontStyle.sizeVeryVerySmall,
    color: Colors.Grey[300],
  },
  prioritized: {
    position: 'absolute',
    fontSize: FontStyle.sizeVerySmall,
    right: 0,
    top: -2,
    transform: 'rotate(12deg)',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: SpacingStyle[4],
    gap: SpacingStyle[4],
  },
  cause: {
    fontSize: FontStyle.sizeVeryVerySmall,
    fontWeight: 500,
    color: Colors.Grey[400],
  },
  description: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    lineClamp: 2,
    overflow: 'hidden',
    fontSize: FontStyle.sizeVerySmall,
    fontWeight: 500,
    color: Colors.OffBlack.primary,
    lineHeight: 1.2,
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  footerMembers: {
    display: 'flex',
    alignItems: 'center',
    gap: SpacingStyle[4],
    fontSize: FontStyle.sizeVeryVerySmall,
    fontWeight: 500,
    color: Colors.Grey[400],
  },
};

export default ActionCard;
