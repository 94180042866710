import * as React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import CustomReferenceInput from '@components/inputs/custom_reference_input';
import Grammarly from '@components/grammarly';

const redirectAfterCreateEdit = (basePath: any, id: any, data: any) =>
  `campaigns/${data.campaignId}/show/qas`;

const EditActions = <ResourceTitleActionBar mode="edit" title="Campaign QAs" />;

export const CampaignQAEdit = () => (
  <Edit
    redirect={redirectAfterCreateEdit}
    mutationMode="pessimistic"
    actions={EditActions}
  >
    <SimpleForm>
      <CustomReferenceInput source="campaignId" reference="campaigns" disabled />
      <Grammarly>
        <TextInput label="English question" source="questionEN" fullWidth required />
      </Grammarly>
      <Grammarly>
        <TextInput
          label="English answer"
          source="answerEN"
          fullWidth
          required
          multiline
        />
      </Grammarly>
      <TextInput label="French question" source="questionFR" fullWidth required />
      <TextInput label="French answer" source="answerFR" fullWidth required multiline />
    </SimpleForm>
  </Edit>
);
