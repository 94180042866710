import React, { useEffect, useState } from 'react';
import { Button, useDataProvider, useRefresh } from 'react-admin';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { ActionType } from 'types/action';

import InputStyle from '@styles/input';
import { FontStyle } from '@styles/variables';

type ActionCtaWebpageCreateEditDialogProps = {
  action: ActionType;
};
const ActionCtaWebpageCreateEditDialog = ({
  action,
}: ActionCtaWebpageCreateEditDialogProps) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [propertiesArray, setPropertiesArray] = useState('');
  const [extraInjectedJS, setExtraInjectedJS] = useState('');
  const [error, setError] = useState('');
  const [type, setType] = useState<'create' | 'edit'>('create');

  useEffect(() => {
    if (!action || !action.actionCtaWebpage) {
      return;
    }
    if (action.actionCtaWebpage) {
      setType('edit');
      setWebsiteUrl(action.actionCtaWebpage?.websiteUrl || '');
      setPropertiesArray(action.actionCtaWebpage?.propertiesArray || '');
      setExtraInjectedJS(action.actionCtaWebpage?.extraInjectedJavascript || '');
    } else {
      setType('create');
      setWebsiteUrl('');
      setPropertiesArray('');
      setExtraInjectedJS('');
    }
  }, [action?.actionCtaWebpage]);

  const handleClickOpen = () => {
    setOpen(true);
    setIsSubmitting(false);
  };

  const handleClose = () => {
    if (isSubmitting) {
      window.alert("You can't close this modal while the CTA is being saved.");
      return;
    }
    const confirm = window.confirm(
      'Are you sure you want to close this modal without saving?\nYou will lose any unsaved changes.'
    );
    confirm && setOpen(false);
  };

  const saveCTA = async () => {
    if (!action) {
      return;
    }

    setIsSubmitting(true);
    try {
      if (type === 'create') {
        await dataProvider.create('action_cta_webpages', {
          data: {
            actionId: action.id,
            websiteUrl,
            propertiesArray,
            extraInjectedJavascript: extraInjectedJS,
          },
        });
      } else if (type === 'edit') {
        await dataProvider.update('action_cta_webpages', {
          id: action.actionCtaWebpage?.id,
          data: {
            websiteUrl,
            propertiesArray,
            extraInjectedJavascript: extraInjectedJS,
          },
        } as any);
      }
      refresh();
      setOpen(false);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const buttonLabel = () => {
    if (type === 'create') {
      return isSubmitting ? 'Creating...' : 'Create';
    } else if (type === 'edit') {
      return isSubmitting ? 'Editing...' : 'Edit';
    }
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        label={type === 'create' ? 'Add' : 'Edit'}
        endIcon={<EditIcon />}
      />
      <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
        <DialogTitle>Which website should be opened inside the app?</DialogTitle>
        <DialogContent>
          <label style={{ fontSize: FontStyle.sizeVeryVerySmall }}>
            Website URL:
            <input
              type="text"
              autoFocus
              style={InputStyle.input}
              placeholder="ex: https://chilli.club/survey-123"
              value={websiteUrl}
              onChange={e => setWebsiteUrl(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  saveCTA();
                }
              }}
            />
          </label>
          <label style={{ fontSize: FontStyle.sizeVeryVerySmall }}>
            Properties Array:
            <textarea
              style={InputStyle.input}
              value={propertiesArray}
              rows={6}
              onChange={e => setPropertiesArray(e.target.value)}
              placeholder={`[{
  querySelector: '[name="firstname"]',
  property: 'firstname',
  value: '%{firstname}',
  prefil: true,
  shouldUpdate: true,
}, ...]`}
            />
          </label>
          <label style={{ fontSize: FontStyle.sizeVeryVerySmall }}>
            Injected Javascript:
            <textarea
              style={InputStyle.input}
              value={extraInjectedJS}
              rows={4}
              onChange={e => setExtraInjectedJS(e.target.value)}
              placeholder="console.log('whatever')"
            />
          </label>
          {error && <div style={{ color: 'red' }}>{error}</div>}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={saveCTA}
            label={buttonLabel()}
            disabled={isSubmitting || !websiteUrl.startsWith('https://')}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ActionCtaWebpageCreateEditDialog;
