import * as React from 'react';
import {
  CreateButton,
  Datagrid,
  DateField,
  FunctionField,
  List,
  TextField,
} from 'react-admin';
import { PepperPillType } from 'types/pepper_pill';

import ResourceTitleActionBar from '@components/resource_title_action_bar';
import { PER_PAGE, PER_PAGE_OPTIONS } from '@components/list';
import { BorderStyle, SpacingStyle } from '@styles/variables';

import { pepperPillColor } from './components/preview';

const ListActions = (
  <ResourceTitleActionBar
    mode="list"
    title="Pepper Pills"
    actions={<CreateButton variant="outlined" />}
  />
);

export const PepperPillList = () => (
  <List
    exporter={false}
    sort={{ field: 'createdAt', order: 'DESC' }}
    perPage={PER_PAGE}
    pagination={<PER_PAGE_OPTIONS />}
    actions={ListActions}
    emptyWhileLoading
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="type" />
      <FunctionField
        render={(record: PepperPillType) => (
          <img
            src={record.imageUrl}
            style={{
              borderRadius: BorderStyle.Radius.small,
              width: 100,
              height: 70,
              objectFit: 'cover',
              border: `4px solid ${pepperPillColor(record.type)}`,
            }}
          />
        )}
        label="Image"
      />
      <TextField label="title" source="titleEN" />
      <FunctionField
        render={(record: PepperPillType) => {
          const groupedReactions: { [label: string]: number } = record?.reactions?.reduce(
            (acc, reaction) => {
              if (!acc[reaction.label]) {
                acc[reaction.label] = 0;
              }
              acc[reaction.label]++;
              return acc;
            },
            {} as any
          );

          return Object.entries(groupedReactions)
            .sort((a, b) => b[1] - a[1])
            .map(([label, count]) => (
              <div
                key={label}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: SpacingStyle[4],
                }}
              >
                {label} <small>{count || 0}</small>
              </div>
            ));
        }}
        label="Reactions"
      />
      <DateField source="createdAt" />
    </Datagrid>
  </List>
);
