import React from 'react';
import { DateField, useRecordContext } from 'react-admin';

export const WinLikes = () => {
  const { likes } = useRecordContext();

  if (!likes || likes.length === 0) {
    return <small>Win didn't receive any like from users yet.</small>;
  }

  return (
    <div>
      <strong>Amount of likes:</strong> {likes.length}
      <br />
      <br />
      <table width={'100%'} style={{ borderSpacing: 0 }}>
        <tbody>
          <tr style={{ textAlign: 'left', backgroundColor: '#d5d5d5' }}>
            <th>Created at</th>
            <th>Avatar</th>
            <th>Username</th>
          </tr>
          {likes &&
            likes.map((like: any, i: number) => (
              <tr
                key={i}
                style={{
                  backgroundColor: i % 2 === 1 ? '#f9f9f9' : undefined,
                }}
              >
                <td>
                  <DateField source="createdAt" />
                </td>
                <td>
                  {!!like.author.avatarUrl && (
                    <img
                      src={like.author.avatarUrl}
                      width={20}
                      alt={like.author.username}
                    />
                  )}
                </td>
                <td>
                  <a
                    href={`#/users/${like.author.id}/show`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {like.author.username}
                  </a>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
