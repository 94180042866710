import React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';

import ResourceTitleActionBar from '@components/resource_title_action_bar';

import NorthStarMetricCard from './components/north_star_metric';
import AmplitudeGraphCard from './components/amplitude_graph';
import ActionsCalendar from './components/actions_calendar';

const Dashboard = () => {
  return (
    <div>
      <ResourceTitleActionBar title="Dashboard" mode="list" />
      <NorthStarMetricCard />
      <Card>
        <CardHeader title="🗓️ Weekly Calendar" />
        <CardContent>
          <ActionsCalendar />
        </CardContent>
      </Card>
      <AmplitudeGraphCard />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default Dashboard;
